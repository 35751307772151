import { AdminPanelHeader, DashBoardHeader, SideBar } from 'components';
import useApiManager from 'networking/ApiManager';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { setAlerts, setLocations } from 'redux/Slices';
import { useGetCockpitDataQuery } from 'redux/cockpitSlice';
import { CommonAlert, CommonBorderBox, Loader, PostHeader, QuestionsTab } from 'whealth-core-web/components';
import { dateFormatStringSingleDate, profileImage } from 'whealth-core-web/components/Helper';
import AddPatientForm from 'components/AddPatientForm';

function CreatePatient() {
  const ApiClient = useApiManager();
  const navigate = useNavigate();
  const { id } = useParams();
  const { patientDetails } = useSelector((state) => state.sliceReducer);
  const dispatch = useDispatch();
  let userLocalData = localStorage.getItem('userDetails');
  userLocalData = JSON.parse(userLocalData);
  const location = useLocation();
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('alert-danger');
  const [errors, serErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [status, setStatus] = useState({ link: 'patient_profile' });
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [isNivanCare, setIsNivanCare] = useState(false);
  const [profileQuestions, setProfileQuestions] = useState([]);
  const getCockpitData = useGetCockpitDataQuery();
  let configData = getCockpitData?.data?.data?.business_config;

  useEffect(() => {
    if (configData) {
      configData.map((item) => {
        if (item.key == 'enable_summary' && item.isActive) {
          setIsNivanCare(item.isActive);
        }
      });
    }
  }, [configData]);

  useEffect(() => {
    if (id) {
      if (Object.keys(patientDetails).length > 0) {
        setUserDetails(patientDetails);
      } else {
        getPatientData();
      }
    }
  }, [id, patientDetails]);
  useEffect(() => {
    if (location?.state?.name) {
      setUserDetails({ full_name: location?.state?.name });
    }
  }, [location?.state?.name]);

  const getPatientData = () => {
    setIsLoading(true);
    ApiClient.getPatientProfile(id)
      .then((res) => {
        setIsLoading(false);
        let newuserData = { ...res.data };
        newuserData.status_uuid = res.data.status.uuid;
        let tempLocations = [];
        let auxSelectedLocation = [];
        newuserData?.locations?.map((item) => {
          tempLocations.push(item.id);
          auxSelectedLocation.push({ id: item?.id, title: item?.name });
        });
        setSelectedLocation(auxSelectedLocation);
        let reformattedData = { ...newuserData, locations: tempLocations };
        setUserDetails(reformattedData);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getProfileQuestions();
  }, []);

  const getProfileQuestions = () => {
    ApiClient.getProfileQuestions()
      .then((res) => {
        setProfileQuestions(
          res.data.map((item5) => {
            let newObje = { ...item5, profile_question_id: item5.id, answer: null };
            delete newObje.id;
            delete newObje.admin;
            return newObje;
          })
        );
      })
      .catch((err) => {
        console.warn('getProfileQuestions', err.response);
      });
  };

  // const sideBar = useMemo(() => {
  //   return <SideBar />;
  // }, []);

  const showTree = id
    ? [
        {
          iconClass: 'person_outline',
          title: 'Patient',
          link: '/Patient',
        },
        {
          title: userLocalData?.name,
          link: `/patient/${id}/dashboard`,
          icon: profileImage(userLocalData?.gender, userLocalData?.image),
        },
        {
          title: 'Update Patient',
        },
      ]
    : [
        {
          iconClass: 'person_outline',
          title: 'Patient',
          link: '/Patient',
        },
        {
          title: 'Create Patient',
        },
      ];

  // const leftSideData = [
  //   { title: !id ? "Create Patient" : "Update patient", style: { color: "var(--themeDarkColor)" } },
  // ];

  const renderAdminPanelHeader = useMemo(() => {
    return <AdminPanelHeader showTree={showTree} iconTitle='person_outline' title='Patient' />;
  }, []);

  // const renderDashBoardHeader = useMemo(() => {
  //   return <DashBoardHeader leftData={leftSideData} />;
  // }, []);

  useEffect(() => {
    const getData = async () => {
      const res = await ApiClient.searchLocations();
      if (res.status == 200) {
        let locationsData = [];
        res.data.map((item) => locationsData.push({ id: item.id, title: item.name }));
        dispatch(setLocations(locationsData));
      }
    };
    try {
      getData();
    } catch (e) {
      console.log(e);
    }
  }, []);

  const createPatient = async (formData, questionAnswer) => {
    setIsLoading(true);

    let apiDataJsonData = {
      full_name: formData.full_name || '',
      gender: formData.gender || '',
      city: formData.city || '',
      phone: formData.phone || '',
      email: formData.email || '',
      pincode: formData?.pincode || '',
      state: formData?.state || '',
      address: formData?.address || '',
      profile_age: formData.age || '',
      required_fields: 'age,gender,location_ids',
      care_manager_id: formData.care_manager_id || '',
      care_plan_id: formData.care_plan_id || '',
      c_plan_activation_date: formData.c_plan_activation_date || dateFormatStringSingleDate(new Date()),
      secondary_phone_num: formData.secondary_phone_num || '',
      revenue_manager_id: formData.revenue_manager_id ||'',
    };
    let location_ids = [];

    // formData.locations?.map((item) => {
    //   location_ids.push(item);
    // });

    formData?.locations && location_ids.push(formData?.locations);

    if (location_ids?.length > 0) {
      apiDataJsonData.location_ids = location_ids;
    }
    let profileAnswers = questionAnswer;
    const ageProfileId = profileAnswers.findIndex((item) => item.key == 'age');
    console.warn('ageProfileId', ageProfileId);
    if (ageProfileId != -1) {
      profileAnswers[ageProfileId].answer = formData.age;
    }
    if (profileAnswers.length > 0) {
      apiDataJsonData.profile_answers = profileAnswers;
    }

    if (questionAnswer?.length > 0) {
      apiDataJsonData.profile_answers = questionAnswer;
    }
    if (id) {
      ApiClient.updatePatient(apiDataJsonData, id)
        .then(async (res) => {
          setIsLoading(false);
          navigate(`/patient/${id}/dashboard`);
          dispatch(setAlerts({ patient: { title: 'Patient Updated Successfully', type: 'alert-success' } }));
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response.status == 413) {
            setShowAlert('image size is too large');
          } else if (err.response.status == 500) {
            setShowAlert('Internal server Error');
          } else {
            err?.response?.data?.detail && setShowAlert(err?.response?.data?.detail);
            serErrors(err.response.data.errors);
          }
          setShowAlert(err.response.data.detail);
        });
    } else {
      ApiClient.createPatient(apiDataJsonData)
        .then((res) => {
          console.warn('s', res);
          setIsLoading(false);
          if (location?.state?.key === 'appointment') {
            navigate(`/appointment`, { state: { key: 'comingPatient', patientName: res?.data?.id } });
          } else {
            navigate('/patient');
          }
          dispatch(setAlerts({ patientCreate: { title: 'Patient created successfully', type: 'alert-success' } }));
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
          if (err.response.status == 413) {
            setShowAlert('image size is too large');
          } else if (err.response.status == 500) {
            setShowAlert('Internal server Error');
          } else {
            err?.response?.data?.detail && setShowAlert(err?.response?.data?.detail);
            serErrors(err.response.data.errors);
          }
        });
    }
  };

  const renderPageHeader = useMemo(() => {
    const postLinks = [
      {
        title: !id ? 'Create Patient' : 'Update patient',
        link: 'patient_profile',
      },
    ];

    if (id) {
      postLinks.push({
        title: `Profile Questions`,
        link: 'profile_questions',
      });
    }
    return (
      <div>
        <PostHeader
          postLinks={postLinks}
          onClickHandle={(data) => {
            setStatus(data);
          }}
        />

        <div className='hrLine mt-4' />
      </div>
    );
  }, []);

  const renderCreatePost = () => {
    return (
      <div className=' px-4 wrapperContent'>
        <AddPatientForm
          getPatientData={getPatientData}
          setShowAlert={setShowAlert}
          setAlertType={setAlertType}
          patientData={userDetails}
          errors={errors}
          createPatient={createPatient}
          id={id}
          selectedLocation={selectedLocation}
          profileQuestions={id ? userDetails.profile_info : profileQuestions}
        />
      </div>
    );
  };

  const renderQuestionsTab = () => {
    return (
      <div className='px-4 wrapperContent'>
        <CommonBorderBox className='d-flex justify-content-between teamBorderBox'>
          <div className='w-100'>
            <QuestionsTab data={userDetails} />
          </div>
        </CommonBorderBox>
      </div>
    );
  };

  const renderBody = () => {
    const renderDataObj = {
      patient_profile: renderCreatePost,
      profile_questions: renderQuestionsTab,
    };

    return (
      <>
        <div className='headerFiles articleHeader stickyHeader'>
          {renderAdminPanelHeader}
          {showAlert && (
            <div className='mt-2'>
              <CommonAlert isShowAlert={showAlert} alertType={alertType} setIsShowAlert={setShowAlert} />
            </div>
          )}
          {/* {renderPageHeader} */}
        </div>
        {renderDataObj[status.link]()}
      </>
    );
  };

  return (
    <div>
      <div>
        {/* {sideBar} */}
        {renderBody()}
      </div>
      <Loader show={isLoading} />
    </div>
  );
}

export default CreatePatient;
