import useApiManager from 'networking/ApiManager';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DatePicker, Radio, RadioGroup } from 'rsuite';
import { CoreCheckBox, CoreInputBox, RenderCheckBoxes, Select2DropDown } from 'whealth-core-web/components';
import { dateFormatStringSingleDate, iconImages } from 'whealth-core-web/components/Helper';
import { setAlerts } from 'redux/Slices';
import { useDispatch } from 'react-redux';
import CommonMaterialUiDatePicker from 'whealth-core-web/components/CommonMaterialUiDatePicker';

function CustomActivityGoalAdd(props) {
  const { setLoading, handleSubmit, data, setIsShowAlert, getGraphData, getPatientData } = props;
  const { id } = useParams();
  const ApiClient = useApiManager();
  const [error, setError] = useState('');
  const [formVal, setFormVal] = useState({
    patient_goal_id: data.id,
    prescription_medicine_id: data.prescription_medicine_id,
  });
  const [activityValue, setActivityValue] = useState({});
  const [disableSave, setDisableSave] = useState(false);
  const isMedicinalGoal = data?.goal?.medicinal_goal;
  const isMultiValueSupport = data?.goal?.multi_value_support;
  const dispatch = useDispatch();

  useEffect(() => {
    getLatestActivity();
  }, []);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    activityLogsCreate();
  };

  const getLatestActivity = () => {
    const timeformat = dateFormatStringSingleDate(formVal.dateTime || new Date());
    const obj = {
      record_date: timeformat,
      patient_goal_id: data.id,
    };

    ApiClient.activityLogsOnchange(id, obj)
      .then((res) => {
        setActivityValue(res.data);
        setFormVal({ ...formVal, value: res.data.value });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const activityLogsCreate = () => {
    if (disableSave) return;
    setDisableSave(true);
    setError({});
    setLoading(true);
    ApiClient.activityLogsCreate(id, formVal)
      .then((res) => {
        handleSubmit && handleSubmit();
        dispatch(setAlerts({ patient: { title: 'Goal activity created successfully', type: 'alert-success' } }));
        getGraphData();
        getPatientData();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        !err.response.data.errors.value && handleSubmit && handleSubmit();
        {
          !err.response.data.errors.value
            ? dispatch(
                setAlerts({
                  patient: {
                    title: err.response.data.errors.record_date,
                    type: 'alert-danger',
                  },
                })
              )
            : setError(err.response.data.errors || err.response.data.errors.value);
        }
        console.log(err);
      });
  };

  const renderImageValues = (imageObj) => {
    return Object.keys(imageObj).map((item, index) => {
      let isactive = '';
      if (formVal.value == item) {
        isactive = 'active-sign';
      }
      return (
        <div
          key={index}
          onClick={() => {
            formVal.value = item;
            setFormVal({ ...formVal });
          }}
        >
          <img src={imageObj[item]} className={isactive} />
        </div>
      );
    });
  };

  const rendercheckboxes = () => {
    return (
      <div>
        <div className='d-flex'>
          <span className='requiredInput me-1'> * </span>
          <div className='mb-2'>Value</div>
        </div>
        <RenderCheckBoxes
          data={{
            value: activityValue?.value,
            targetValue: data?.value,
            returnValue: formVal,
            setreturnValue: setFormVal,
          }}
        />
        <div>
          <div style={{ color: 'red', textAlign: 'center', display: 'flex' }}>{error?.value}</div>
        </div>
      </div>
    );
  };

  const moodtrackerValue = () => {
    return <div className='d-flex goal-sign-images justify-content-center mt-4'>{renderImageValues(iconImages().mood)}</div>;
  };

  const energyTrackerValue = () => {
    return <div className='d-flex goal-sign-images justify-content-center mt-4'>{renderImageValues(iconImages().energy)}</div>;
  };

  let renderValue2 = () => {
    if (isMultiValueSupport) {
      return (
        <div className='w-100 d-flex ' style={{ margin: '0px 0 0px 6px' }}>
          <div className='w-100 mt-4'>
            <CoreInputBox
              retuired
              validateZeroToTen={data?.goal?.code == 'pain'}
              validateZero={data?.goal?.code !== 'pain' && true}
              showMSG={error?.value2 || error.value}
              label=''
              validatedecimal
              placeholder={data?.extra_details?.value2}
              inputStyle={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              value={formVal.value2}
              setValue={(text) => {
                formVal.value2 = text;
                setFormVal({ ...formVal });
              }}
              msgStyle={{ color: 'red' }}
            />
          </div>
          <div>
            <div className='unitBox mt-4 capitalize'>{data?.goal?.measuring_event?.measuring_unit ?? 'unit'}</div>
          </div>
        </div>
      );
    } else {
      return;
    }
  };

  const renderRadiobox = () => {
    return (
      <>
        <div className='d-flex'>
          <span className='requiredInput mt-2'> * </span>
          <RadioGroup
            inline
            name='radio-name'
            value={formVal.value}
            onChange={(e) => {
              setFormVal({ ...formVal, value: e });
            }}
          >
            <Radio value={'1'}>Yes</Radio>
            <Radio value={'0'}>no</Radio>
          </RadioGroup>
        </div>
        <div className='inputMsg'>{error.value}</div>
      </>
    );
  };

  const renderInputField = () => {
    let inputStyle = !isMultiValueSupport && { borderTopRightRadius: 0, borderBottomRightRadius: 0 };
    return (
      <div className='w-100 d-flex mt-4' style={{ margin: '20px 0 0px 6px' }}>
        <div className='w-100'>
          {data?.goal?.input_type !== 'radio' ? (
            <CoreInputBox
              validatenumberAfterDecimal
              retuired
              validateZeroToTen={data?.goal?.code == 'pain'}
              validateZero={data?.goal?.code !== 'pain' && true}
              showMSG={error?.value}
              maxLength={data?.goal?.code == 'pain' ? 2 : ''}
              validatedecimal
              label='Value'
              inputStyle={{ ...inputStyle }}
              placeholder={data?.extra_details?.value}
              value={formVal.value}
              setValue={(text) => {
                formVal.value = text.startsWith('.') ? text.slice(1) : text;
                setFormVal({ ...formVal });
              }}
              msgStyle={{ color: 'red' }}
            />
          ) : (
            renderRadiobox()
          )}
        </div>
        {renderValue2()}
        {!isMultiValueSupport && data?.goal?.input_type !== 'radio' && (
          <div>
            <div className='unitBox mt-4 capitalize'>{data?.goal?.measuring_event?.measuring_unit ?? 'unit'}</div>
          </div>
        )}
      </div>
    );
  };

  const renderValueField = () => {
    let dataObj = {
      mood: moodtrackerValue,
      energy: energyTrackerValue,
    };

    if (dataObj[data?.goal?.measuring_event?.measuring_unit]) {
      return (
        <div>
          {dataObj[data?.goal?.measuring_event?.measuring_unit]()}
          <div style={{ color: 'red', textAlign: 'center' }}>{error?.value}</div>
        </div>
      );
    } else {
      return renderInputField();
    }
  };

  return (
    <div>
      <form onSubmit={handleFormSubmit} className='p-2 py-4'>
        <div className='mb-4 w-100'>
          {/* <DatePicker
            disabledDate={(date) => {
              if (date >= new Date()) {
                return true;
              } else {
                return false;
              }
            }}
            format='yyyy-MM-dd HH:mm:ss'
            placeholder='YYYY-MM-DD HH:MM:SS'
            calendarDefaultDate={new Date()}
            defaultValue={formVal.dateTime || Date.now()}
            onChange={(text) => {
              formVal.dateTime = text;
              formVal.record_date = new Date(text).toISOString();
              setFormVal({ ...formVal });
            }}
            ranges={[
              {
                label: 'Now',
                value: new Date(),
              },
            ]}
            style={{ width: '100%' }}
          /> */}
          <CommonMaterialUiDatePicker
            disableFuture
            value={formVal.dateTime || Date.now()}
            onChange={(text) => {
              formVal.dateTime = text;
              formVal.record_date = new Date(text).toISOString();
              setFormVal({ ...formVal });
            }}
            placeholder={'YYYY-MM-DD'}
          />
        </div>

        {isMedicinalGoal ? rendercheckboxes() : <div className='goal-sign-images-div'>{renderValueField()}</div>}
        <div>
          <button type='submit' value={formVal.value} className='form-control coreBtn text-white'>
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

export default CustomActivityGoalAdd;
