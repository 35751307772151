import React, { useEffect, useState } from 'react';
import { Select2DropDown } from '../whealth-core-web/components/index';
import { setLocations } from 'redux/Slices';
import { useDispatch, useSelector } from 'react-redux';
import useApiManager from 'networking/ApiManager';

function ClinicsFilterView(props) {
  const { filterData, setFilterData, clearFiltersData } = props;
  const [selectedClinic, setSelectedClinic] = useState();
  const locations = useSelector((state) => state.sliceReducer.locations);
  const ApiClient = useApiManager();
  const dispatch = useDispatch();

  useEffect(() => {
    let obj = { clinic: selectedClinic };
    setFilterData(obj);
  }, [selectedClinic]);

  useEffect(() => {
    setSelectedClinic(filterData.clinic);
  }, [filterData]);

  useEffect(() => {
    if (!filterData?.clinic) setSelectedClinic();
  }, [clearFiltersData]);

  useEffect(() => {
    const getData = async () => {
      const res = await ApiClient.searchLocations();
      if (res.status == 200) {
        let locationsData = [];
        res.data.map((item) => locationsData.push({ id: JSON.stringify(item.id), title: item.name }));
        dispatch(setLocations(locationsData));
      }
    };
    try {
      getData();
    } catch (e) {
      console.log(e);
    }
  }, []);

  const renderHeading = () => {
    return <div className='filterHeadings mt-3'>Hospitals</div>;
  };

  const renderChips = () => {
    return (
      <>
        <div className='position-relative'>
          <div className='mt-3' />
          <Select2DropDown
            container={document.getElementById('clinic-dropdown-container')}
            className='zindex-popover'
            placement='bottomStart'
            placeholder='Select Hospital'
            data={locations}
            labelStyle={{ fontWeight: 'var(--lightFont)' }}
            onSelect={(data) => {
              setSelectedClinic(data);
            }}
            value={selectedClinic || ''}
            msgStyle={{ color: 'red' }}
            style={{ position: 'relative', width: '100%' }}
          />
          <div id='clinic-dropdown-container' style={{ position: 'absolute', bottom: '0px' }}></div>
        </div>
      </>
    );
  };

  return (
    <div>
      {renderHeading()}
      {renderChips()}
    </div>
  );
}

export default ClinicsFilterView;
