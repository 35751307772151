import React, { createContext, useContext, useState } from 'react';

const FilterContext = createContext();

export const FilterProvider = ({ children, isActiveFilter }) => {
  const [filterData, setFilterData] = useState(isActiveFilter ? { active_patients: true } : {});
  const [selectedClinic, setSelectedClinic] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState([]);
  const [selectedDep, setSelectedDep] = useState([]);
  const [activeDate, setActiveDate] = useState(new Date());

  return (
    <FilterContext.Provider
      value={{
        filterData,
        setFilterData,
        selectedClinic,
        setSelectedClinic,
        selectedDoc,
        setSelectedDoc,
        selectedDep,
        setSelectedDep,
        activeDate,
        setActiveDate,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useFilter = () => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error('useFilter must be used within a FilterProvider');
  }
  return context;
};
