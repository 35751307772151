import React, { useEffect, useState } from 'react';
import { Select2DropDown } from '../whealth-core-web/components/index';

function PayerFilterView(props) {
  const { filterData, setFilterData, clearFiltersData, title } = props;
  const [selectedPayerStatus, setSelectedPayerStatus] = useState();

  useEffect(() => {
    let obj = { payer: selectedPayerStatus };
    setFilterData(obj);
  }, [selectedPayerStatus]);

  useEffect(() => {
    setSelectedPayerStatus(filterData.payer);
  }, [filterData]);

  useEffect(() => {
    if (!filterData?.payer) setSelectedPayerStatus();
  }, [clearFiltersData]);

  const renderHeading = () => {
    return <div className='filterHeadings mt-3'>{title}</div>;
  };

  const payer_status = [{ title: 'Self' }, { title: 'Insurance' }, { title: 'CGHS' }];

  const renderChips = () => {
    return (
      <div className='position-relative'>
        <div className='mt-3' />
        <Select2DropDown
          container={document.getElementById('clinic-dropdown-container2')}
          placeholder='Select Payer'
          data={payer_status}
          labelStyle={{ fontWeight: 'var(--lightFont)' }}
          valueKey={'title'}
          onSelect={(data) => {
            setSelectedPayerStatus(data);
          }}
          value={selectedPayerStatus || ''}
          msgStyle={{ color: 'red' }}
          placement='top'
        />
        <div id='clinic-dropdown-container2' style={{ position: 'relative', bottom: '0px' }}></div>
      </div>
    );
  };

  return (
    <div>
      {renderHeading()}
      {renderChips()}
    </div>
  );
}

export default PayerFilterView;
