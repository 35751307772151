import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  acessToken: '',
  userData: {},
  patientActiveTab: 'timeline',
  updatePatinetTab: { title: 'timeline', update: true },
  patientDetails: {},
  updatePatientDetails: true,
  medicineActiveCard: [],
  alerts: {},
  doctorData: [],
  patientStatus: [],
  adminStatus: [],
  medicineActiveCard: [],
  summaries: [],
  departments: [],
  locations: [],
  careManagerLeads: [],
  statusList: [],
  reduxReportData: [],
  reduxNotesData: [],
  reduxPrescriptionData: [],
  reduxGoalData: [],
  reduxRegimeData: [],
  allDepartments: [],
  activeTabTeamForm: '',
  selectedRole: '',
  currentConsultationStatus: '',
  currentPaymentStatus: '',
  allDoctorsList: [],
};

export const slice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    acessTokens(state, action) {
      state.acessToken = action.payload;
    },

    setMedicineActiveCard(state, action) {
      state.medicineActiveCard = action.payload;
    },

    setDoctorData(state, action) {
      state.doctorData = action.payload;
    },

    updatePatinetTabs(state, action) {
      state.updatePatinetTab = action.payload;
    },

    setAlerts(state, action) {
      state.alerts = action.payload;
    },

    setReduxReportData(state, action) {
      state.reduxReportData = action.payload;
    },
    setReduxNotesData(state, action) {
      state.reduxNotesData = action.payload;
    },

    setReduxPrescriptionData(state, action) {
      state.reduxPrescriptionData = action.payload;
    },
    setReduxGoalData(state, action) {
      state.reduxGoalData = action.payload;
    },

    setReduxRegimeData(state, action) {
      state.reduxRegimeData = action.payload;
    },

    setMedicineActiveCard(state, action) {
      state.medicineActiveCard = action.payload;
    },

    setPatientActiveTab(state, action) {
      state.patientActiveTab = action.payload;
    },

    setPatientDetails(state, action) {
      state.patientDetails = action.payload;
    },

    updatePatientDetailsApi(state, action) {
      state.updatePatientDetails = !state.updatePatientDetails;
    },

    setUserData(state, action) {
      state.userData = action.payload;
    },

    setPatientStatus(state, action) {
      state.patientStatus = action.payload;
    },

    setAdminStatus(state, action) {
      state.adminStatus = action.payload;
    },
    addSummary(state, action) {
      state.summaries = [...state.summaries, action.payload];
    },
    updateSummaries(state, action) {
      state.summaries = action.payload;
    },
    addDepartment(state, action) {
      state.departments = [...state.departments, action.payload];
    },
    setLocations(state, action) {
      state.locations = action.payload;
    },
    setCareManagerLeads(state, action) {
      state.careManagerLeads = action.payload;
    },
    setStatus(state, action) {
      state.statusList = action.payload;
    },
    setAllDepartments(state, action) {
      state.allDepartments = action.payload;
    },
    setActiveTabTeamForm(state, action) {
      state.activeTabTeamForm = action.payload;
    },
    setSelectedRole(state, action) {
      state.selectedRole = action.payload;
    },
    setCurrentConsultationStatus(state, action) {
      state.currentConsultationStatus = action.payload;
    },
    setCurrentPaymentStatus(state, action) {
      state.currentPaymentStatus = action.payload;
    },
    setDoctorsList(state, action) {
      state.allDoctorsList = action.payload;
    },
  },
});

export default slice.reducer;
export const {
  setAllDepartments,
  setMedicineActiveCard,
  acessTokens,
  setLocations,
  setCareManagerLeads,
  setStatus,
  updatePatinetTabs,
  setUserData,
  setPatientActiveTab,
  updatePatientDetailsApi,
  setPatientDetails,
  setAlerts,
  setReduxReportData,
  setReduxNotesData,
  setReduxGoalData,
  setReduxPrescriptionData,
  setReduxRegimeData,
  setDoctorData,
  setPatientStatus,
  setAdminStatus,
  addSummary,
  updateSummaries,
  addDepartment,
  setActiveTabTeamForm,
  setSelectedRole,
  setCurrentConsultationStatus,
  setCurrentPaymentStatus,
  setDoctorsList,
} = slice.actions;
