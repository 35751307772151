import React, { useState } from 'react';
import { CoreDropDown, ReusableButton } from 'whealth-core-web/components';
import { ZohoConnectButton } from 'whealth-core-web/components/ZohoCalendar/ZohoConnectButton';

function SimpleHeader(props) {
  const { title, dropDownTitle, zohoOauthLink, isZohoOauthExpired, isZohoMeetingEnabled, consultationCockpit } = props;
  const [filterValue, setFilterValue] = useState('');

  const selectData = [
    {
      id: 1,
      title: 'Text Title',
    },
    {
      id: 2,
      title: 'Text Title 2',
    },
  ];

  return (
    <div className='d-flex  align-items-center  justify-content-between'>
      <div className='dashboardTitle'>{title}</div>
      {isZohoMeetingEnabled && !consultationCockpit && (
        <ZohoConnectButton zohoOauthLink={zohoOauthLink} isZohoOauthExpired={isZohoOauthExpired} />
      )}

      {dropDownTitle && (
        <div className=''>
          <CoreDropDown
            setValue={setFilterValue}
            value={filterValue}
            data={selectData}
            selectStyle={{ width: '320px', height: '40px' }}
            placeholder={dropDownTitle}
          />
        </div>
      )}
    </div>
  );
}

export default SimpleHeader;
