import { AdminPanelHeader, SideBar } from 'components';
import useApiManager from 'networking/ApiManager';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import { Table, Pagination } from 'rsuite';
import { DateRangePicker } from 'rsuite';
import { CoreDropDown, Loader, RenderGraph } from 'whealth-core-web/components';
import {
  dateFormat,
  timeFormat,
  profileImage,
  subtractMonths,
  dateFormatString,
  titlizeString,
  showMedicineTimeString,
  scrollToTop,
} from 'whealth-core-web/components/Helper';
import { Stack } from 'rsuite';
const { Column, HeaderCell, Cell } = Table;

function GraphListView() {
  const ApiClient = useApiManager();
  const { id, gid, pageTitle } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState('');
  const [goalsData, setGoalsData] = useState([]);
  const [date, setDate] = useState('');
  const [tableData, setTableData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  let isMedicineGoal = data?.goal?.medicinal_goal;

  useEffect(() => {
    getGoalsData();
    scrollToTop();
  }, [id]);

  useEffect(() => {
    getGraphData();
  }, [gid, date]);

  useEffect(() => {
    if (data?.activity_logs) {
      let tmpData = [...data.activity_logs];
      setTableData(tmpData.reverse((a, b) => a - b));
    }
  }, [data]);

  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };

  const pageData = tableData.filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });

  const getDuration = (duration) => {
    getGraphData(duration);
  };

  const renderDataTable = () => {
    return (
      <div>
        <Table height={600} bordered cellBordered data={pageData}>
          <Column align='center'>
            <HeaderCell>Sr. No. </HeaderCell>
            <Cell>
              {(rowData, index) => {
                return <span>{index + 1}</span>;
              }}
            </Cell>
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>value</HeaderCell>
            <Cell>
              {(rowData) => {
                let showVal = rowData.value;
                if (isMedicineGoal) {
                  showVal = showMedicineTimeString(rowData.value).join(', ');
                }

                return (
                  <>
                    {rowData?.value2 && data?.goal?.multi_value_support ? (
                      <span>{showVal + '/' + rowData.value2 + ' '}</span>
                    ) : (
                      <span>{showVal + ' '}</span>
                    )}
                  </>
                );
              }}
            </Cell>
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Logged At</HeaderCell>
            <Cell>
              {(rowData) => {
                return (
                  <span>
                    {dateFormat(rowData.record_date)} · {timeFormat(rowData.record_date)}
                  </span>
                );
              }}
            </Cell>
          </Column>
          <Column>
            <HeaderCell>Source</HeaderCell>
            <Cell>
              {(rowData) => {
                return <span className='capitalize'>{titlizeString(rowData.channel)}</span>;
              }}
            </Cell>
          </Column>
        </Table>
        <div style={{ padding: 20 }}>
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size='xs'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={tableData?.length}
            limitOptions={[10, 30, 50]}
            limit={limit}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={handleChangeLimit}
          />
        </div>
      </div>
    );
  };

  let userDetails = localStorage.getItem('userDetails');
  userDetails = JSON.parse(userDetails);
  const navigate = useNavigate();

  const getGoalsData = () => {
    const query = {
      date_range: dateFormatString(new Date(subtractMonths(5, new Date())).setDate(1)),
    };
    setIsLoading(true);
    ApiClient.getPatientgoals(id, false, query)
      .then((res) => {
        setIsLoading(false);
        setGoalsData(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const renderDatePicker = () => {
    const predefinedBottomRanges = [
      {
        label: 'Today',
        value: [new Date(), new Date()],
        placement: 'left',
      },
      {
        label: 'Yesterday',
        value: [addDays(new Date(), -1), addDays(new Date(), -1)],
        placement: 'left',
      },
      {
        label: 'This week',
        value: [startOfWeek(new Date()), endOfWeek(new Date())],
        placement: 'left',
      },
      {
        label: 'Last 7 days',
        value: [subDays(new Date(), 6), new Date()],
        placement: 'left',
      },
      {
        label: 'Last 30 days',
        value: [subDays(new Date(), 29), new Date()],
        placement: 'left',
      },
      {
        label: 'This month',
        value: [startOfMonth(new Date()), new Date()],
        placement: 'left',
      },
      {
        label: 'Last month',
        value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
        placement: 'left',
      },
      {
        label: 'This year',
        value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
        placement: 'left',
      },
      {
        label: 'Last year',
        value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
        placement: 'left',
      },
    ];

    return (
      <div className='w-100'>
        <Stack direction='column' spacing={8} alignItems='flex-start'>
          <DateRangePicker
            disabledDate={(date) => {
              if (date >= new Date()) {
                return true;
              } else {
                return false;
              }
            }}
            showOneCalendar
            onChange={setDate}
            ranges={predefinedBottomRanges}
            placeholder='Select Date'
            defaultValue={date}
            style={{ width: '100%' }}
            format='yyyy-MM-dd'
            calendarDefaultDate={new Date()}
          />
        </Stack>
      </div>
    );
  };

  const renderDropDown = useMemo(() => {
    return (
      <div className='ps-md-3 ps-sm-0 mt-3'>
        <CoreDropDown
          showKey='goal'
          nestedKey='title'
          setValue={(item) => {
            navigate(`/patient/${id}/${pageTitle}/${item}/show`);
          }}
          value={gid}
          data={goalsData}
          selectStyle={{ height: '40px' }}
          placeholder='Select Goal'
        />
      </div>
    );
  }, [goalsData, gid]);

  // const sideBar = useMemo(() => {
  //   return <SideBar />;
  // }, []);

  const getGraphData = (dateData) => {
    let dateParams = dateFormatString(subtractMonths(6, new Date()));

    if (dateData) {
      dateParams = dateData;
    } else if (date) {
      dateParams = dateFormatString(date[0], date[1]);
    }

    const params = {
      date_range: dateParams,
      query_str: 'months_6',
    };

    setIsLoading(true);
    ApiClient.getPatientgoals(id, gid, params)
      .then((res) => {
        setIsLoading(false);
        setData(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const renderHeader = useMemo(() => {
    const showTree = [
      { iconClass: 'person_outline', title: 'Patient', link: '/patient' },
      {
        icon: profileImage(userDetails.gender, userDetails.image),
        title: userDetails.name,
        link: `/patient/${id}/dashboard/`,
      },
      {
        title: pageTitle.replace('-', ' '),
        link: `/patient/${id}/dashboard/`,
      },
      {
        title: data?.goal?.title + ' Graph',
      },
    ];
    return (
      <div className='my-3'>
        <AdminPanelHeader iconTitle='person_outline' showTree={showTree} title='Patient' />
      </div>
    );
  }, [data]);

  const renderGraphData = () => {
    return (
      <>
        <div className='articleHeader stickyHeader'>{renderHeader}</div>
        <div className='px-4 wrapperContent'>
          <div className='d-md-flex align-items-center justify-content-end me-3 '>
            <div className='graphDatePicker mt-3'>{renderDatePicker()}</div>
            {renderDropDown}
          </div>
          <div className='p-3 my-3'>
            {data && <RenderGraph getDuration={getDuration} healthTab customDate={date} data={data} />}
            {renderDataTable()}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className='wrapper'>
      {/* {sideBar} */}
      {renderGraphData()}
      <Loader show={isLoading} />
    </div>
  );
}

export default GraphListView;
