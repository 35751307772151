import React, { useEffect, useRef, useState } from 'react';
import { closeIcon } from 'res/images';
// import '../../whealth-core-web/components/filterModal.scss';
import RenderNotesTab from 'whealth-core-web/components/RenderNotesTab';
import useApiManager from 'networking/ApiManager';
import { Select2DropDown } from 'whealth-core-web/components';
import PatientNotesView from './PatientNoteView';

function PatientNotes(props) {
  const ApiClient = useApiManager();
  const { patientId, showPatientNotes, content, setContent, titleReal, setTitleReal, noteCategory, setNoteCategory } =
    props;
  const [selectedPayerStatus, setSelectedPayerStatus] = useState();

  const filterHeader = () => {
    return (
      <div className='filterHeader'>
        <div>Old Notes</div>
        <div
          onClick={() => {
            clearVal();
          }}
          className=''
          data-bs-dismiss='offcanvas'
          aria-label='Close'
        >
          <img src={closeIcon} />
        </div>
      </div>
    );
  };

  const clearVal = () => {
    setContent('');
    setTitleReal('');
    setNoteCategory('');
  };
  const getNotes = () => {
    // setIsLoading(true);
    let params = { page: 1 };
    ApiClient.getNotes(patientId, params)
      .then((res) => {
        // setIsLoading(false);
        // setNotesData(res.data);
        console.log('notes val', res?.data);
      })
      .catch((err) => {
        // setIsLoading(false);
      });
  };

  useEffect(() => {
    if (patientId) {
      getNotes();
    }
  }, []);
  const payer_status = [
    { title: 'Self', id: '1' },
    { title: 'Insurance', id: '2' },
    { title: 'CGHS', id: '3' },
  ];
  const createNote = () => {
    return (
      <>
        <PatientNotesView
          content={content}
          setContent={setContent}
          titleReal={titleReal}
          setTitleReal={setTitleReal}
          noteCategory={noteCategory}
          setNoteCategory={setNoteCategory}
          patientId={patientId}
        />
        {/* <RenderNotesTab patientId={patientId} /> */}
      </>
    );
  };

  const renderModal = () => {
    return (
      <div className='filterModal'>
        <div
          className='offcanvas offcanvas-end'
          tabIndex='-1'
          id='offcanvasRight2'
          aria-labelledby='offcanvasRightLabel'
        >
          <div className='whiteBackground'>
            {filterHeader()}
            <div className='hrLine' />
            <div className='filterHeaderData'>{createNote()}</div>
          </div>
        </div>
      </div>
    );
  };

  return renderModal();
}

export default PatientNotes;
