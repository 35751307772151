import { AdminPanelHeader } from 'components';
import useApiManager from 'networking/ApiManager';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DatePicker, Panel, PanelGroup } from 'rsuite';
import AddQuestionForm from './AddQuestionForm';
import { CommonAlert, CoreButton, CoreInputBox, Loader, Select2DropDown } from 'whealth-core-web/components';
import { profileImage } from 'whealth-core-web/components/Helper';

function AddQuestions(props) {
  const { id, pid } = useParams();
  const ApiClient = useApiManager();
  const navigation = useNavigate();
  const { onClick, patientConversion, patientConversionId, getQuestion, isClearVal } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [errors, setErrors] = useState({});
  const [alertType, setAlertType] = useState('alert-success');
  const [prescriptionData, setPrescriptionData] = useState('');
  const [templateList, setTemplateList] = useState([]);
  const [templateValue, setTemplateValue] = useState();
  const [isEditable, setIsEditable] = useState(false);
  const [searchTemplateList, setSearchTemplateList] = useState([]);
  const [categoryQuestions, setCategoryQuestions] = useState({});
  const [patientDetails, setPatientDetails] = useState({});
  const [followupDate, setFollowupDate] = useState(null);
  const [nextFollowupDate, setNextFollowupDate] = useState(null);
  const [behaviourQuestion, setBehaviourQuestion] = useState([]);
  const [isByCategoryData, setIsByCategoryData] = useState(false);

  useEffect(() => {
    if (pid) {
      getPrescriptionData();
    }
  }, [pid]);

  useEffect(() => {
    if (isClearVal) {
      setTemplateValue('');
      setNextFollowupDate(null);
      setErrors('');
    }
  }, [isClearVal]);

  useEffect(() => {
    getTemplatesData();
  }, []);

  useEffect(() => {
    if (!pid && templateValue) {
      getTemplateQuestion();
    }
  }, [templateValue]);

  useEffect(() => {
    getPatientData();
  }, []);

  const getPatientData = () => {
    setIsLoading(true);
    ApiClient.getPatientDetails(id)
      .then((res) => {
        setIsLoading(false);
        setPatientDetails(res.data);
        let nextFollowupDateQuestion = res?.data?.profile_info.find((item3) => item3.key == 'next_followup_date');
        if (nextFollowupDateQuestion?.answer) {
          if (pid) {
            setNextFollowupDate(nextFollowupDateQuestion?.answer);
          } else {
            setFollowupDate(nextFollowupDateQuestion?.answer);
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (prescriptionData && prescriptionData.behaviour_questionnaire_answers) {
      const initialCategoryQuestions = {};
      const otherQuestions = []; // Store "Other Questions" separately

      prescriptionData.behaviour_questionnaire_answers.forEach((question) => {
        const isDefaultSection = question?.is_default || question?.behaviour_question?.is_default;
        const category = isByCategoryData
          ? question?.category?.title || question?.behaviour_question?.category?.title || 'Miscellaneous'
          : isDefaultSection
          ? 'Default Question'
          : 'Other Questions';

        if (!isDefaultSection && !pid && !isByCategoryData) {
          question.behaviour_question = '';
        }

        if (category === 'Other Questions') {
          if (otherQuestions.length < 1) otherQuestions.push(question);
        } else {
          if (!initialCategoryQuestions[category]) {
            initialCategoryQuestions[category] = {
              questions: [question],
              errors: [],
            };
          } else {
            initialCategoryQuestions[category].questions.push(question);
          }
        }
      });

      // Add "Other Questions" as the first section
      let finalCategoryQuestions = initialCategoryQuestions;

      // Reorder only if isByCategoryData is true
      if (!isByCategoryData) {
        finalCategoryQuestions = {
          'Other Questions': {
            questions: otherQuestions,
            errors: [],
          },
          ...initialCategoryQuestions,
        };
      }

      setCategoryQuestions(finalCategoryQuestions);
    }
  }, [prescriptionData]);

  const getTemplateQuestion = () => {
    setIsLoading(true);
    ApiClient.getRegimeTemplate(templateValue)
      .then((res) => {
        let newQuestionData = { behaviour_questionnaire_answers: [] };
        res.data.behaviour_questions.map((item, index) => {
          newQuestionData.behaviour_questionnaire_answers[index] = {
            ...item,
            behaviour_question: { id: item.id, question: item.question },
          };
        });
        setPrescriptionData({
          behaviour_questionnaire_answers: newQuestionData.behaviour_questionnaire_answers,
          can_add_questions: res.data.editable,
        });
        if (!isByCategoryData) setBehaviourQuestion(res.data.behaviour_questions);
        setIsEditable(res.data.editable);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const searchTemplate = (data) => {
    setSearchTemplateList([]);
    if (data?.length) {
      ApiClient.searchTemplateList({ search_str: data })
        .then((res) => {
          setSearchTemplateList(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getTemplatesData = () => {
    setIsLoading(true);
    ApiClient.getTemplateList()
      .then((res) => {
        setTemplateList(res.data);
        let defaultTemplate = res.data.findIndex((item) => item.default);
        if (defaultTemplate != -1 && !pid) {
          setTemplateValue(res.data[defaultTemplate].id);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getPrescriptionData = () => {
    setIsLoading(true);
    ApiClient.questionData(id, pid)
      .then((res) => {
        setPrescriptionData(res.data);
        setIsEditable(res.data.can_add_questions);
        setFollowupDate(res.data.followup_date);
        setTemplateValue(res.data.behaviour_questionnaire_template);
        setIsByCategoryData(res.data.behaviour_questionnaire_template ? false : true);
        if (res.data.behaviour_questionnaire_template)
          getBehaviourQueByTemplate(res.data.behaviour_questionnaire_template, res.data.behaviour_questionnaire_answers);
        // setBehaviourQuestion(res.data.behaviour_questionnaire_answers);

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getBehaviourQueByTemplate = (templateId, updatedData) => {
    ApiClient.getRegimeTemplate(templateId)
      .then((res) => {
        // setIsEditable(res.data.editable);
        let modifiedBQ = parseArrays(updatedData, res.data.behaviour_questions);

        setBehaviourQuestion(modifiedBQ);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  function parseArrays(a, b) {
    // Create a map for quick lookup based on behaviour_question.id
    const map = new Map();
    a.forEach((item) => {
      if (item.behaviour_question && item.behaviour_question.id) {
        map.set(item.behaviour_question.id, item);
      }
    });

    // Process array b and replace items with matching behaviour_question.id from array a
    return b.map((itemB) => {
      // Use itemB's structure as the base
      const baseItem = { ...itemB };

      // Find the matching object in array a
      const matchingItem = map.get(itemB.id);

      if (matchingItem) {
        // Copy properties from the matched item from array a into the baseItem
        baseItem.behaviour_question = matchingItem.behaviour_question;
        baseItem.answer = matchingItem.answer;
        baseItem.patient_goal = matchingItem.patient_goal;
        baseItem.recommendation = matchingItem.recommendation;
        baseItem.adherence = matchingItem.adherence;
        baseItem.created_at = matchingItem.created_at;
        baseItem.updated_at = matchingItem.updated_at;
      }

      return baseItem;
    });
  }

  const behaviourQuestionCreate = (data) => {
    let params = modifyParams({ ...data });
    setIsLoading(true);
    ApiClient.behaviorQuestionCreate(params, id || patientConversionId)
      .then((res) => {
        setIsLoading(false);
        setAlertType('alert-success');
        setIsShowAlert('Conversion Log created successfully');
        setTemplateValue('');
        setNextFollowupDate(null);
        setTimeout(() => {
          !patientConversion ? navigation(`/patient/${id}/dashboard`) : getQuestion(patientConversionId);
        }, 2500);
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors(err.response.data.errors || err.response.data?.detail);
        if (err.response.data?.errors?.non_field_errors || err.response.data?.errors?.details || err.response.data?.detail) {
          setAlertType('alert-danger');
          setIsShowAlert(err.response.data?.detail || err.response.data?.errors?.non_field_errors || Object.values(err.response.data.errors[0]));
        }
      });
  };

  const modifyParams = (data) => {
    let newData = { ...data };
    data.behaviour_questionnaire_answers.map((item, index) => {
      if (typeof item.behaviour_question == 'object') {
        item.behaviour_question = item.behaviour_question.id;
      }
    });

    return newData;
  };

  const updateBehaviourQuestion = (data) => {
    let params = modifyParams({ ...data });

    setIsLoading(true);
    ApiClient.updateBehaviorPrescription(id, pid, params)
      .then((res) => {
        setIsLoading(false);
        setAlertType('alert-success');
        setIsShowAlert('Conversion Log updated successfully ');
        setTimeout(() => {
          navigation(`/patient/${id}/dashboard`);
        }, 2500);
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors(err.response.data.errors || err.response.data?.detail);
        if (err.response.data?.errors?.non_field_errors || err.response.data?.errors?.details || err.response.data?.detail) {
          setAlertType('alert-danger');
          setIsShowAlert(err.response.data?.detail || err.response.data?.errors?.non_field_errors || Object.values(err.response.data.errors[0]));
        }
      });
  };

  let userDetails = localStorage.getItem('userDetails');
  userDetails = JSON.parse(userDetails);

  const showTree = [
    { iconClass: 'person_outline', title: 'Patient', link: '/patient' },
    {
      icon: profileImage(userDetails?.gender, userDetails?.image),
      title: userDetails?.name,
      link: `/patient/${id}/dashboard`,
    },
    { title: (pid ? 'Update' : 'New') + ' Conversion Log', link: '' },
  ];

  const addQuestionToCategory = (category) => {
    const newCategoryQuestions = { ...categoryQuestions };
    if (!newCategoryQuestions[category]) {
      newCategoryQuestions[category] = {
        questions: [{ behaviour_question: '' }],
        errors: [{}],
      };
    } else {
      newCategoryQuestions[category].questions.push({
        behaviour_question: '',
      });
      newCategoryQuestions[category].errors.push({});
    }

    setCategoryQuestions(newCategoryQuestions);
  };

  const removeQuestion = (category, index) => {
    const updatedCategoryQuestions = { ...categoryQuestions };
    const questions = updatedCategoryQuestions[category].questions;
    const errors = updatedCategoryQuestions[category].errors;

    if (questions.length > index) {
      const newQuestions = [...questions];
      newQuestions.splice(index, 1);
      updatedCategoryQuestions[category].questions = newQuestions;
    }

    if (errors.length > index) {
      const newErrors = [...errors];
      newErrors.splice(index, 1);
      updatedCategoryQuestions[category].errors = newErrors;
    }

    setCategoryQuestions(updatedCategoryQuestions);
  };

  const renderQuestionsByCategory = useCallback(() => {
    const orderedCategories = ['Other Questions', 'Default Question'];
    
    return (
      <div className='AccordionForm'>
        {orderedCategories.map((category, index) => {
          if (!categoryQuestions[category]) return null;

          return (
            <Panel header={category} key={category} defaultExpanded={true} collapsible>
              <AddQuestionForm
                errors={errors?.behaviour_questionnaire_answers}
                questionCategoryID={
                  categoryQuestions[category]?.questions[0]?.category?.id ||
                  categoryQuestions[category]?.questions[0]?.behaviour_question?.category?.id ||
                  'misc'
                }
                setErrors={setErrors}
                onAddQuestion={() => addQuestionToCategory(category)}
                onRemoveQuestion={(index) => removeQuestion(category, index)}
                data={{
                  behaviour_questionnaire_answers: categoryQuestions[category].questions,
                }}
                setIsLoading={setIsLoading}
                isEditable={isEditable}
                behaviourQuestion={behaviourQuestion}
                setBehaviourQuestion={setBehaviourQuestion}
                isByCategoryData={isByCategoryData}
              />
            </Panel>
          );
        })}
      </div>
    );
  }, [categoryQuestions, errors, isEditable, behaviourQuestion, isByCategoryData]);

  const showPatient = () => (
    <>
      <div className={`articleHeader ${!patientConversion && 'stickyHeader'}`}>
        {!patientConversion && (
          <div className='headerFiles '>
            <AdminPanelHeader iconTitle='person_outline' showTree={showTree} title='Patient' />
          </div>
        )}
        <div className='addnewform-part'>{pid ? 'Update' : 'Add New'} Conversion Log</div>
        <div className='hrLine' />
      </div>
      <div className={`${!patientConversion && 'wrapperContent px-4 '}`}>
        {isShowAlert && (
          <div className='mt-5'>
            <CommonAlert isShowAlert={isShowAlert} alertType={alertType} setIsShowAlert={setIsShowAlert} />
          </div>
        )}
        {!pid ? (
          <div className='w-100'>
            <Select2DropDown
              retuired={true}
              className='mb-4'
              label={'Select Template'}
              data={searchTemplateList?.length ? searchTemplateList : templateList}
              isValidation
              onSearch={(searchData) => searchTemplate(searchData)}
              onSelect={(val, objData) => {
                if (objData?.id && templateList.findIndex((item) => item.id == objData.id) == -1) {
                  templateList.push(objData);
                  setTemplateList([...templateList]);
                  setSearchTemplateList([]);
                }
                setTemplateValue(val);
              }}
              value={templateValue}
            />
          </div>
        ) : null}
        {pid || templateValue ? renderQuestionsByCategory() : null}
        {/* {!pid ? ( */}
        <div className='d-flex justify-content-between mt-3'>
          {/* <div className='w-50 me-4'>
            <div>Follow up Date</div>
            <DatePicker
              className='w-50'
              disabled
              placeholder='Follow up Date'
              value={followupDate ? new Date(followupDate) : null}
              onSelect={(data) => {
                setFollowupDate(data);
              }}
            />
          </div> */}
          <div className={`w-100  position-relative`} id='patientDatePickerContainer'>
            <div>
              <label class='inputlabel'>
                <span className='requiredInput'> * </span>Next Follow up Date
              </label>
            </div>
            <DatePicker
              className='w-100'
              container={() => document.getElementById('patientDatePickerContainer')}
              placeholder='Next Follow up Date'
              format='yyyy-MM-dd HH:mm'
              onClean={() => setNextFollowupDate(null)}
              disabledDate={(date) => {
                if (new Date(date) < new Date().setHours(0, 0, 0)) {
                  return true;
                } else {
                  return false;
                }
              }}
              value={nextFollowupDate ? new Date(nextFollowupDate) : null}
              onSelect={(data) => {
                setNextFollowupDate(data);
              }}
            />
            {errors.next_followup_date && <div className='inputMsg'>{errors.next_followup_date}</div>}
          </div>
        </div>
        {/* ) : null} */}
        {/* <AddPrescriptionForm
        setErrors={setErrors}
        data={prescriptionData}
        errors={errors}
        onClick={pid ? updatePrescription : prescriptionCreate}
      /> */}
      </div>
    </>
  );
  const checkFieldsNotFilled = () => {
    for (let section in categoryQuestions) {
      if (categoryQuestions.hasOwnProperty(section)) {
        const questions = categoryQuestions[section].questions;
        for (let i = 0; i < questions.length; i++) {
          const question = questions[i];
          if (!question.behaviour_question || !question.answer || question.answer.trim() === '') {
            return true; // A required field is not filled
          }
        }
      }
    }
    return false; // All required fields are filled
  };

  return (
    <div className={`${!patientConversion && 'wrapper '}`}>
      {/* {sideBar} */}
      {showPatient()}
      <div className={` ${!patientConversion && 'wrapperContent px-4'}`}>
        <CoreButton
          onClick={() => {
            if ((!isByCategoryData && !templateValue) || !nextFollowupDate || checkFieldsNotFilled()) {
              setAlertType('alert-danger');
              setIsShowAlert('Please fill all mandatory fields');
              return;
            }
          
            setErrors({ prescription_medicines: [] });
          
            const newPayload = {
              behaviour_questionnaire_answers: [],
              can_add_questions: isEditable,
              followup_date: followupDate,
              next_followup_date: nextFollowupDate,
              behaviour_questionnaire_template: templateValue,
            };
          
            Object.keys(categoryQuestions).forEach((category) => {
              newPayload.behaviour_questionnaire_answers = newPayload.behaviour_questionnaire_answers.concat(categoryQuestions[category].questions);
            });
          
            if (pid) {
              updateBehaviourQuestion(newPayload);
            } else {
              newPayload.behaviour_questionnaire_answers = newPayload.behaviour_questionnaire_answers.map((item) => {
                const { id, ...rest } = item;
                return rest;
              });
              behaviourQuestionCreate(newPayload);
            }
          }}
          customClass='btn btn-secondary btn-lg'
          title='Save Details'
        />
      </div>
      <Loader show={isLoading} />
    </div>
  );
}

export default AddQuestions;