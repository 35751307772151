import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

if (process.env.REACT_APP_NODE_ENV === 'staging' || process.env.REACT_APP_NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    environment: process.env.REACT_APP_NODE_ENV,
    // Performance Monitoring
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', /^\//, /^https:\/\/www\.staging.nivaancare\.co.in/],
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
