import React, { useEffect, useState } from 'react';
import { Select2DropDown } from '../whealth-core-web/components/index';
import inputFields from '../inputFields.json';
import { useSelector } from 'react-redux';

function SubStatusFilterView(props) {
  const { filterData, setFilterData, clearFiltersData, title } = props;
  const statusList = useSelector((state) => state.sliceReducer.statusList);

  const [selectedSubStatus, setSelectedSubStatus] = useState();
  const [subStatusList, setSubStatusList] = useState([]);
  useEffect(() => {
    let obj = { sub_status: selectedSubStatus };
    setFilterData(obj);
  }, [selectedSubStatus]);

  useEffect(() => {
    setSelectedSubStatus(filterData?.sub_status || '');
  }, [filterData]);

  useEffect(() => {
    setSelectedSubStatus();
  }, [clearFiltersData]);

  const renderHeading = () => {
    return <div className='filterHeadings mt-3'>{title}</div>;
  };

  useEffect(() => {
    if (filterData?.status) {
      let findStatusName = statusList?.find((item1) => item1.id == filterData?.status)?.title;
      setSubStatusList(inputFields?.subStatus[findStatusName]);
    }
  }, [filterData?.status]);
  // const enagagement_status = [
  //   { title: 'Hot' },
  //   { title: 'Cold' },
  //   { title: 'Warm' },
  //   { title: 'Dropped-Off' },
  //   { title: 'Converted' },
  // ];

  const renderChips = () => {
    return (
      <div className='position-relative'>
        <div className='mt-3' />
        <Select2DropDown
          container={document.getElementById('clinic-dropdown-container1')}
          placeholder='Select Status'
          data={subStatusList}
          labelStyle={{ fontWeight: 'var(--lightFont)' }}
          valueKey={'title'}
          onSelect={(data) => {
            setSelectedSubStatus(data);
          }}
          value={selectedSubStatus}
          msgStyle={{ color: 'red' }}
        />
        <div id='clinic-dropdown-container1' style={{ position: 'absolute', bottom: '0px' }}></div>
      </div>
    );
  };

  return (
    <div>
      {renderHeading()}
      {renderChips()}
    </div>
  );
}

export default SubStatusFilterView;
