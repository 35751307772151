import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DatePicker } from 'rsuite';
import { CommonAlert, CoreButton, CoreInputBox, CoreTextArea, Select2DropDown, TagSelectPicker } from './components';
import { splitArrObjData, splitArrData } from './components/Helper';
import CurrencyInput from 'react-currency-input-field';

function AddServiceForm(props) {
  const {
    appointmentVal,
    ifSingleMultiSpec,
    crpDetails,
    serviceSchemaData,
    estimateCost,
    setEstimateCost,
    onClick,
    setheaderEstimateCostDetail,
    dynamicServiceData,
    setDynamicServiceData,
    serviceDropdown,
    serviceCategoryValue,
    statusValue,
    pid,
    careManagerDropdown,
    doctorDropdown,
    clinicDropdown,
    setSelectedCatObj,
    selectedCatObj,
    adminData,
  } = props;
  const [fieldErrors, setFieldErrors] = useState([]);

  const location = useLocation();

  useEffect(() => {
    setSelectedCatObj(location?.state?.item?.service);
  }, [selectedCatObj]);

  const renderInputDateField = (item, error) => {
    let defaultValue = '';
    if (item?.answer) {
      let date = new Date(item.answer);
      if (date != 'Invalid Date') {
        defaultValue = date;
      }
    }

    return (
      <div style={{ position: 'relative' }}>
        <div>
          <label class='inputlabel'>
            {item.required && <span class='requiredInput'> * </span>}
            {item.label}
          </label>
        </div>
        <DatePicker
          disabledDate={(date) => {
            if (new Date(date) < new Date().setHours(0, 0, 0)) {
              return true;
            } else {
              return false;
            }
          }}
          defaultValue={defaultValue}
          preventOverflow
          format='yyyy-MM-dd'
          placeholder='YYYY-MM-DD'
          onChange={(date) => {
            item.answer = date;
            setDynamicServiceData({ ...dynamicServiceData });
          }}
          placement='autoVerticalStart'
          calendarDefaultDate={defaultValue || new Date()}
          onOk={(date) => {
            item.answer = date;
            setDynamicServiceData({ ...dynamicServiceData });
          }}
          onClean={(date) => {
            item.answer = '';
          }}
          style={{ width: '100%', padding: 0, margin: 0 }}
        />
        {!defaultValue && <div class='inputMsg'>{error}</div>}
      </div>
    );
  };
  const renderSingalSelect = (item, error) => {
    let arrData = splitArrObjData(item.options, '|', 'title');

    return (
      <Select2DropDown
        value={item?.answer}
        data={arrData}
        label={item.label}
        placeholder={item.label}
        valueKey='value'
        labelKey='title'
        getObjData
        retuired={true}
        onSelect={(selectedValue, dataObj) => {
          item.answer = dataObj.title;
          setDynamicServiceData({ ...dynamicServiceData });
        }}
        showMSG={error}
      />
    );
  };

  const renderMultiSelectWithAdd = (item, error) => {
    let activeData = splitArrData(item?.answer?.toLowerCase(), '|');
    let arrData = splitArrObjData(item?.options, '|', 'title');
    if (item?.searchData?.length) {
      arrData.push({ title: 'Add : ' + item.searchData });
    }
    return (
      <div className='mb-3 capitalize'>
        <TagSelectPicker
          showMSG={error}
          label={item.label}
          data={arrData}
          style={{ width: '100%' }}
          labelKey='title'
          valueKey='value'
          placeholder={item.label}
          onSelect={(val, titleArray) => {
            item.answer = val.join('|');
            setDynamicServiceData({ ...dynamicServiceData });
          }}
          // onSearch={(data) => checkOptionExit(data, item)}
          msgStyle={{ color: 'red' }}
          value={activeData}
        />
      </div>
    );
  };

  const renderDateTimeField = (item, error) => {
    const itemAnswer = item.answer ? dayjs(item.answer) : null;
    const previousDate = itemAnswer ? itemAnswer.subtract(1, 'day') : null;

    return (
      <div className='w-100 '>
        <label className='mb-1'>
          <span class='requiredInput'> * </span>
          {item.label}
        </label>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDateTimePicker
            renderInput={(props) => <TextField {...props} />}
            disablePast={
              !(
                item?.previous_date_allow &&
                (adminData?.role === 'super_admin' ||
                  adminData.role === 'admin' ||
                  adminData?.role === 'revenue_manager' ||
                  adminData?.role === 'health_coach_lead')
              )
            }
            value={item.answer ? dayjs(item.answer) : null}
            shouldDisableDate={(day) => pid && day.isBefore(itemAnswer, 'day')}
            shouldDisableMonth={(day) => pid && day.isBefore(itemAnswer, 'month')}
            shouldDisableYear={(day) => pid && day.isBefore(itemAnswer, 'year')}
            // minDateTime={pid ? dayjs(item.answer) : null}
            placeholder={item.label}
            onAccept={(date) => {
              if (!item?.changed) {
                item.changed = item.answer;
              }
              item.answer = date;

              setDynamicServiceData({ ...dynamicServiceData });
            }}
            format='DD/MM/YYYY hh:mm A'
            className='MobileDatePickerinputCommon'
          />
        </LocalizationProvider>
        {<div className='inputMsg'>{error}</div>}
      </div>
    );
  };

  const renderMultiSelect = (item, error) => {
    let activeData = splitArrData(item?.answer?.toLowerCase(), '|');
    let arrData = splitArrObjData(item?.options, '|', 'title');
    return (
      <div className='mb-3 capitalize'>
        <TagSelectPicker
          showMSG={error}
          label={item.label}
          data={arrData}
          style={{ width: '100%' }}
          labelKey='title'
          valueKey='value'
          placeholder={item.label}
          onSelect={(val, titleArray) => {
            item.answer = titleArray.join('|');
            setDynamicServiceData({ ...dynamicServiceData });
          }}
          msgStyle={{ color: 'red' }}
          value={activeData}
        />
      </div>
    );
  };

  const renderTextArea = (item, error) => {
    return (
      <CoreTextArea
        disabled={item.name == 'primary_diagnosis'}
        showMSG={error}
        labelStyle={{
          margin: '0 0 8px 0px',
          fontWeight: 'var(--lightWeight)',
        }}
        rows={2}
        label={item.label}
        value={item.answer || ''}
        retuired={item?.required}
        setValue={(data) => {
          item.answer = data;
          setDynamicServiceData({ ...dynamicServiceData });
        }}
      />
    );
  };

  const renderInputBox = (item, error) => {
    return (
      <CoreInputBox
        validateNumbers={item.type == 'number'}
        validatedecimal={item.type == 'float'}
        retuired={item?.required}
        labelStyle={{ fontWeight: 'var(--lightWeight)' }}
        label={item.label}
        value={item.answer || ''}
        showMSG={error}
        setValue={(data) => {
          item.answer = data;
          setDynamicServiceData({ ...dynamicServiceData });
        }}
      />
    );
  };

  const renderFields = (item, error) => {
    if (item.type == 'date') {
      return renderInputDateField(item, error);
    } else if (item.type == 'single_select') {
      return renderSingalSelect(item, error);
    } else if (
      item.type == 'multi_select' &&
      (item.name == 'diagnosis' || item.name == 'co_morbidities' || item.name == 'allergies' || item.name == 'investigations')
    ) {
      return renderMultiSelectWithAdd(item, error);
    } else if (item.type == 'multi_select') {
      return renderMultiSelect(item, error);
    } else if (item.type == 'date_time') {
      return renderDateTimeField(item, error);
    } else if (item.type == 'text') {
      return renderTextArea(item, error);
    } else {
      return renderInputBox(item, error);
    }
  };
  const renderDataFields = () => {
    let renderData = () => {
      return serviceSchemaData?.service_schema?.map((item, index) => {
        let fieldError = '';
        if (fieldErrors[index]) {
          fieldError = fieldErrors[index].answer;
        }
        return (
          <div key={index} className='w-100 mb-3'>
            {renderFields(item, fieldError)}
          </div>
        );
      });
    };
    return <>{renderData()}</>;
  };

  const checkrequired = (data) => {
    let isErrors = false;
    let errors = [];

    data?.map((item) => {
      if (item.required) {
        if (item.answer) {
          if (typeof item.answer == 'string' && item.answer.trim().length == 0) {
            errors.push({ answer: 'This field is required' });
            isErrors = true;
          } else {
            errors.push({});
          }
        } else {
          errors.push({ answer: 'This field is required' });
          isErrors = true;
        }
      } else {
        errors.push({});
      }
    });

    return { isErrors, errors };
  };

  return (
    <>
      {serviceDropdown && serviceSchemaData?.service_schema?.length ? (
        <div className=''>
          {renderDataFields()}
          <div className='w-100 mt-4'>
            {/* <CoreInputBox
              maxLength={10}
              disabled={ifSingleMultiSpec}
              onlyNumTwoDigitAfterDecimals
              label='Service Cost'
              placeholder='Service Cost'
              value={estimateCost || null}
              setValue={(data, dataObj) => {
                setheaderEstimateCostDetail(data);
                setEstimateCost(data);
              }}
            /> */}
            <div className='inputFieldHeader'>
              <label className='inputlabel' htmlFor='validationCustom01 mt-3 slot-name'>
                {/* <span style={{ color: 'red' }}>*&nbsp;</span> */}
                {'Service Cost'}
              </label>
            </div>
            <CurrencyInput
              disabled={ifSingleMultiSpec}
              id='validationCustom01'
              name='input-1'
              className={`form-control coreInput`}
              label='Service Cost'
              value={estimateCost || 0}
              // showMSG={errors?.crp_final_bill_amounts?.[crpTypeFinalAmount?.title?.toLowerCase()]}
              msgStyle={{ color: 'red' }}
              onValueChange={(data) => {
                setheaderEstimateCostDetail(data);
                setEstimateCost(data);
              }}
              placeholder={'Service Cost'}
              prefix={'₹'}
              step={1}
            />
            {/* <p className='capitalize' style={{ color: 'red' }}>
              {errors?.crp_final_bill_amounts?.[crpTypeFinalAmount?.title?.toLowerCase()]}
            </p> */}
          </div>
          {serviceSchemaData?.service_schema?.length && (
            <CoreButton
              onClick={() => {
                let service_schema_data = {
                  crp_details: crpDetails?.length ? crpDetails : [],
                  data: serviceSchemaData?.service_schema,
                  service_category: serviceCategoryValue,
                  service: serviceDropdown,
                  status: statusValue,
                  doctor: doctorDropdown,
                  care_manager: careManagerDropdown,
                  location: clinicDropdown,
                  estimated_cost: estimateCost || null,
                  appointment: appointmentVal,
                };
                let checkIserror = checkrequired(service_schema_data.data);

                if (checkIserror.isErrors) {
                  setFieldErrors(checkIserror.errors);
                }
                onClick(service_schema_data);
              }}
              customClass='btn btn-secondary btn-lg'
              title='Save Details'
            />
          )}
        </div>
      ) : null}
    </>
  );
}

export default AddServiceForm;
