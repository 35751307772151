import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

function RenderFamilyMember(props) {
  const { patientId, data, isPrimaryUser } = props;

  const navigate = useNavigate();

  const textStyle = {
    color: 'rgb(36, 59, 134)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '14px', // 116.667%
    textDecoration: 'underline',
    cursor: 'pointer',
  };

  const handleFamilyMemberClick = (memberId) => {
    navigate(`/patient/${memberId}/dashboard`);
  };
  return (
    <>
      <br />
      <div className='d-flex patientNote flex-column pb-3'>
        <div className='table-responsive tableContainer'>
          <table className='w-100 table removeBottomMargin'>
            <thead className='tableHeader'>
              <tr>
                <th>SI. No.</th>
                <th>Name</th>
                <th> Phone</th>
                <th> Relation</th>
              </tr>
            </thead>
            <tbody>
              {data?.length > 0 ? (
                data?.map((member, index) => (
                  <tr key={index} className='capitalize'>
                    <td>{index + 1}</td>
                    <td className='capitalize'>
                      <span style={textStyle} onClick={() => handleFamilyMemberClick(member?.id)}>
                        {member?.full_name}
                      </span>
                    </td>
                    <td>{member?.phone}</td>
                    <td>{member?.relationship}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan='4' className='text-center'>
                    No Family Members
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default RenderFamilyMember;
