import useApiManager from 'networking/ApiManager';
import React, { useEffect, useMemo, useState } from 'react';
import CommonBorderBox from './CommonBorderBox';
import Loader from './Loader';
import WatiConfigForm from 'whealth-core-web/forms/WatiConfigForm';

function WatiConfig(props) {
  const { setIsShowAlert, setAlertType } = props;

  const [modelOperator, setModelOperator] = useState('DISTINCT');
  const [fieldDetails, setFieldDetails] = useState([
    {
      field: 'Conversion_Log_Question',
      operator: '',
      value: [],
      rowOperator: false,
    },
  ]);
  const [periodDetails, setPeriodDetails] = useState([
    {
      isActive: false,
      day: '',
      templateName: '',
    },
  ]);

  //////////////////v2
  const [conversionLogV2, setConversionLogV2] = useState([
    {
      field: 'Question',
      operator: '',
      rowOperator: true,
      value: [],
    },
  ]);

  const steps = ['Rules', 'Account Configuration'];
  const [currentStep, setCurrentStep] = useState(0);
  const [activeTab, setActiveTab] = useState(steps[currentStep]);

  //////////////////v2

  const ApiClient = useApiManager();
  const [data, setData] = useState([]);
  const [conversionLogData, setConversionlogData] = useState();
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [logo, setLogo] = useState('');
  const [showPmsDetails, setShowPmsDetails] = useState(false);
  const [showConversionDetails, setShowConversionDetails] = useState(false);
  const [isNotDistint, setIsNotDistinct] = useState(false);
  const [CommonTemplateName, setCommonTemplateName] = useState('');

  useEffect(() => {
    getDashBoardConfigData();
  }, []);

  const dataTobeSent = (inputObject) => {
    let arr1 = fieldDetails,
      arr2 = periodDetails,
      stringValue = modelOperator;
    console.log('datatobesent', fieldDetails, periodDetails, data, modelOperator);
    const response = {
      user_profile: {
        status: ['prc'], // Set as constant
        sub_status: ['pitched'], // Set as constant
      },
      conversion_log: {
        question_list: [],
        answer_list: [],
        operator_list: [],
        operators: {
          question_operator: '',
          answer_operator: '',
        },
        counter: {},
        templates_name: {},
      },
      next_checkin_date: {
        templates_name: '',
      },
      model_operators: [],
    };

    // Parsing arr1 for conversion_log
    arr1.forEach((item, index) => {
      if (item.field === 'Conversion_Log_Question') {
        let quesList = item.value.map((val) => val.toLowerCase());
        response.conversion_log.question_list = quesList; // Corrected assignment
        response.conversion_log.counter = quesList.reduce((acc, question) => {
          acc[`${question}_counter_list`] = []; // Unchanged
          arr2.forEach((item) => {
            if (item.isActive) {
              if (!acc[`${question}_counter_list`]) {
                acc[`${question}_counter_list`] = [];
              }
              acc[`${question}_counter_list`].push(item.day);
            }
          });
          return acc;
        }, {}); // Unchanged
        response.conversion_log.templates_name = quesList.reduce((acc, question) => {
          acc[`${question}_templates`] = {}; // Unchanged
          arr2.forEach((item) => {
            if (item.isActive) {
              if (!acc[`${question}_templates`]) {
                acc[`${question}_templates`] = {};
              }
              acc[`${question}_templates`][item.day] = item.templateName;
            }
          });
          return acc;
        }, {});
        response.conversion_log.operators.question_operator = item.operator.toLowerCase();
      } else if (item.field === 'Conversion_Log_Answer') {
        response.conversion_log.answer_list = item?.value?.map((val) => val.toLowerCase().replace(/ /g, '_'));
        response.conversion_log.operators.answer_operator = item.operator.toLowerCase();
      }
      if (index != arr1.length - 1) response.conversion_log.operator_list.push(!item.rowOperator ? 'or' : 'and');
    });

    // Parsing arr2 for templates_name

    // Parsing inputObject for next_checkin_date and model_operators
    response.next_checkin_date.templates_name = inputObject.next_pms_checkin_date_notification_template_name || '';
    response.is_pms_show = showPmsDetails;
    response.is_conversion_show = showConversionDetails;
    response.field_details = fieldDetails;
    response.model_operators.push(stringValue);

    return response;
  };

  const dataTobeSentV2 = (inputObject) => {
    console.log('datatobesent2', conversionLogV2, modelOperator, inputObject.next_pms_checkin_date_notification_template_name);
    let arr = conversionLogV2,
      string1 = modelOperator,
      string2 = inputObject.next_pms_checkin_date_notification_template_name || '';
    const questionList = [];
    const answerList = [];
    const counter = {};
    const templates = {};
    const temp15 = conversionLogV2?.[0].value;
    console.log(temp15, 'temp15');
    arr.forEach((item) => {
      const { field, operator, value } = item;

      value.forEach((val) => {
        const key = val.id.toLowerCase().replace(/\s+/g, '_');

        // Add to question or answer list based on field
        if (field === 'Question') {
          questionList.push(val.id);
        } else if (field === 'Answer') {
          answerList.push(val.id);
        }

        // Add to counter and templates
        val.period.forEach((period) => {
          if (period.isActive) {
            if (!counter[`${key}_counter_list`]) {
              counter[`${key}_counter_list`] = [];
              templates[`${key}_templates`] = {};
            }
            counter[`${key}_counter_list`].push(period.day);
            templates[`${key}_templates`][period.day] = period.templateName;
          }
        });
      });
    });

    const result = {
      next_pms_checkin_date_notification: true,
      next_pms_checkin_date_notification_template_name: string2,
      model_configuration: {
        user_profile: {
          status: ['prc'],
          sub_status: ['pitched'],
        },

        model_operators: [string1],

        conversion_log_details: conversionLogV2,
        is_conversion_show: showConversionDetails,
        is_pms_show: showPmsDetails,
      },
      wati_api_key: 'api-key',
      wati_token_secret: 'secret',
      getData: true,
    };

    if (showPmsDetails) {
      result.model_configuration.next_checkin_date = {
        templates_name: string2,
      };
    }
    if (showConversionDetails) {
      result.model_configuration.conversion_log = {
        question_list: questionList,
        answer_list: answerList,
        operator_list: [arr.find((item) => item.field === 'Question')?.rowOperator ? true : false],
        operators: {
          question_operator: arr.find((item) => item.field === 'Question')?.operator || '',
          answer_operator: arr.find((item) => item.field === 'Answer')?.operator || '',
        },
        counter,
        templates_name: templates,
      };
    }
    return result;
  };

  const prepateFormData = (data) => {
    const formData = new FormData();
    data.model_configuration = dataTobeSentV2(data).model_configuration;
    data.logo_url && formData.append('logo_url', data.logo_url);
    delete data.logo_url;
    delete data.conversion_log;
    delete data.conversion_log_details;
    delete data.next_checkin_date;
    delete data.is_conversion_show;
    delete data.is_pms_show;

    formData.append('configuration', JSON.stringify(data));
    formData.append('conversion_log_configuration', JSON.stringify(conversionLogData));
    console.log('conversion_log_configuration', data);
    return formData;
  };

  const dashboardConfigration = () => {
    setError('');
    prepateFormData(data);
    setIsLoading(true);
    if (!data?.wati_api_key) {
      data.wati_api_key = 'api-key';
    }
    if (!data?.wati_token_secret) {
      data.wati_token_secret = 'secret';
    }
    ApiClient.dashboardConfigration(prepateFormData(data))
      .then((res) => {
        setIsLoading(false);
        getDashBoardConfigData();
        if (isCreate) {
          setIsShowAlert('Configration created successfully.');
        } else {
          setIsShowAlert('Configration updated successfully.');
        }
        setAlertType('alert-success');
        setIsCreate(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setIsCreate(false);
        setAlertType('alert-danger');
        setIsShowAlert(err.response.data.detail);
      });
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function parseInput(input) {
    const { user_profile, conversion_log, model_operators, next_checkin_date } = input;

    // Create arr1 dynamically
    const arr1 = [];
    if (conversion_log?.operators) {
      const operatorKeys = Object.keys(conversion_log.operators);
      operatorKeys.forEach((key) => {
        const [fieldType] = key.split('_');
        const list = conversion_log[`${fieldType}_list`];

        if (list) {
          arr1.push({
            field: `Conversion_Log_${capitalizeFirstLetter(fieldType)}`,
            operator: conversion_log.operators[key],
            value: list,
            rowOperator: key === 'question_operator',
          });
        }
      });
    }

    // Create arr2 dynamically
    const arr2 = [];
    if (conversion_log?.templates_name) {
      Object.entries(conversion_log?.templates_name).forEach(([templateKey, templateValue]) => {
        Object.entries(templateValue).forEach(([day, templateName]) => {
          arr2.push({
            isActive: true,
            day: parseInt(day),
            templateName,
          });
        });
      });
    }

    // Create obj dynamically
    const obj = {
      business_name: 'Nivaan',
      wati_api_key: 'api-key',
      wati_token_secret: 'secret',
      getData: true,
      business_hours: '2024-03-11T00:30:00.000Z',
      time_zone: 'Asia/Kolkata',
      business_start_time: '6:00',
      business_end_time: '23:00',
      patient_id_format: '{prefix}/{location}/{patient_id}',
      patient_id_prefix: 'RHPL',
      next_pms_checkin_date_notification: true,
      next_pms_checkin_date_notification_template_name: next_checkin_date?.templates_name,
      model_configuration: {
        user_profile,
        conversion_log,
      },
    };

    const str = model_operators?.join(' ') ?? '';

    return {
      arr1,
      arr2,
      obj,
      str,
    };
  }

  const getDashBoardConfigData = () => {
    setIsLoading(true);
    ApiClient.getDashBoardConfigData()
      .then((res) => {
        let tmpData = res.data.configuration;
        setIsCreate(!res.data.configuration);
        const parsedData = parseInput(tmpData.model_configuration);
        setShowPmsDetails(tmpData?.model_configuration?.is_pms_show);
        setShowConversionDetails(tmpData?.model_configuration?.is_conversion_show);

        setModelOperator(parsedData.str);
        setFieldDetails(tmpData?.model_configuration?.field_details ? tmpData?.model_configuration?.field_details : parsedData.arr1);
        setPeriodDetails(parsedData.arr2);
        setConversionLogV2(
          tmpData?.model_configuration?.conversion_log_details || [
            {
              field: 'Question',
              operator: '',
              rowOperator: true,
              value: [],
            },
          ]
        );
        tmpData.getData = true;
        setData(res.data.configuration);
        setLogo(res.data.logo_url);
        setIsLoading(false);
        console.log('parsedData', parsedData);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log('parsedData err', err);
        setError(err?.response?.data?.errors);
      });
  };

  const renderWatiConfigForm = useMemo(() => {
    return (
      <div>
        <WatiConfigForm
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          error={error}
          valueObj={data}
          setValueObj={setData}
          conversionLogData={conversionLogData}
          setConversionlogData={setConversionlogData}
          onClick={dashboardConfigration}
          logo={logo}
          modelOperator={modelOperator}
          setModelOperator={setModelOperator}
          fieldDetails={fieldDetails}
          setFieldDetails={setFieldDetails}
          periodDetails={periodDetails}
          setPeriodDetails={setPeriodDetails}
          showConversionDetails={showConversionDetails}
          setShowConversionDetails={setShowConversionDetails}
          showPmsDetails={showPmsDetails}
          setShowPmsDetails={setShowPmsDetails}
          conversionLogV2={conversionLogV2}
          setConversionLogV2={setConversionLogV2}
          isNotDistint={isNotDistint}
          setIsNotDistinct={setIsNotDistinct}
          setCommonTemplateName={setCommonTemplateName}
          CommonTemplateName={CommonTemplateName}
          currentStep={currentStep}
          activeTab={activeTab}
        />
      </div>
    );
  }, [
    isLoading,
    error,
    data,
    conversionLogData,
    logo,
    modelOperator,
    fieldDetails,
    periodDetails,
    showPmsDetails,
    showConversionDetails,
    modelOperator,
    conversionLogV2,
    isNotDistint,
    CommonTemplateName,
    currentStep,
    activeTab,
  ]);

  const handleTabClick = (index) => {
    // Check if there are unsaved changes

    setCurrentStep(index);
    setActiveTab(steps[index]);
  };

  return (
    <div className='medicine-form'>
      <div>
        <ul className='nav nav-tabs'>
          {steps.map((tab, index) => (
            <li className='nav-item' key={tab}>
              <button
                className={`nav-link ${currentStep === index ? 'active' : ''}`}
                onClick={() => {
                  // if (props.id) {
                  handleTabClick(index);
                  // }
                }}
                style={{
                  backgroundColor: activeTab === tab ? '#DEE5FB' : 'white',
                  color: activeTab === tab ? '#243B86' : 'black',
                  border: '1px solid #DEE5FB',
                }}
              >
                {tab}
              </button>
            </li>
          ))}
        </ul>

        <div className='tab-content'>{renderWatiConfigForm}</div>
      </div>

      <Loader show={isLoading} />
    </div>
  );
}

export default WatiConfig;
