import { AdminPanelHeader, SideBar, DashBoardHeader } from 'components';
import useApiManager from 'networking/ApiManager';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { addIconWhite, filters } from 'res/images';
import {
  ArticleDesign,
  CommonAlert,
  Pagination,
  PostHeader,
  Loader,
  EmptyView,
  FilterModal,
  FilterChips,
} from 'whealth-core-web/components';
import SearchBox from 'whealth-core-web/components/common/SearchBox';
import { checkObjectLength, genrageFilterParams } from 'whealth-core-web/components/Helper';

function ArticleList() {
  const location = useLocation();
  const ApiClient = useApiManager();
  const [articlesData, setArticlesData] = useState([]);
  const [articlesCount, setArticlesCount] = useState({});
  const [status, setStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filterData, setFilterData] = useState({});
  const [hideEmpty, setHideEmpty] = useState(true);
  const [clearFiltersData, setClearFilterData] = useState(false);

  const navigation = useNavigate();

  useEffect(() => {
    if (location?.state?.showAlert) {
      setIsShowAlert(location.state.showAlert);
    }
  }, [location?.state?.showAlert]);

  useEffect(() => {
    getArticles();
  }, [filterData, currentPage, status]);

  useEffect(() => {
    getArticlesCount();
  }, [filterData]);

  const createFilterParams = (data) => {
    const removekeys = ['author_data', 'category_Data', 'tag_SelectedData', 'tags_data', 'date'];
    let qryObject = {};
    genrageFilterParams(data, qryObject, removekeys);
    return qryObject;
  };

  const getArticles = (filter) => {
    let qryParams = {};
    let newFilter = filter || filterData;
    if (newFilter) {
      qryParams = createFilterParams(newFilter);
    }
    qryParams = { ...qryParams, page: currentPage, status: status.url };

    setIsLoading(true);
    ApiClient.getArticles(qryParams)
      .then((res) => {
        setTotalPages(res.headers['total-pages']);
        setArticlesData(res.data);
        setIsLoading(false);
        setHideEmpty(false);
      })
      .catch((err) => {
        setHideEmpty(false);
        setIsLoading(false);
      });
  };

  const getArticlesCount = () => {
    let qryParams = createFilterParams(filterData);

    setIsLoading(true);
    ApiClient.getArticlesCount(qryParams)
      .then((res) => {
        setArticlesCount(res.data);
        setIsLoading(false);
      })
      .catch((err) => setIsLoading(false));
  };

  // const sideBar = useMemo(() => {
  //   return <SideBar />;
  // }, []);

  const headerData = [
    {
      title: 'New Article',
      icon: addIconWhite,
      status: true,
      onClick: () => navigation('/articles/new'),
    },
  ];

  const postLinks = [
    {
      title: `All Articles (${articlesCount.all_article_count || 0})`,
      link: 'articles',
      url: '',
    },
    {
      title: `Live (${articlesCount.live_article_count || 0})`,
      link: 'live',
      url: 'live',
    },
    {
      title: `Draft (${articlesCount.draft_article_count || 0})`,
      link: 'draft',
      url: 'draft',
    },
    {
      title: `Unpublished (${articlesCount.unpublished_article_count || 0})`,
      link: 'unpublished',
      url: 'unpublished',
    },
  ];

  const leftSideData = [
    {
      title: 'Apply Filter',
      className: 'themeColor boldFont',
      showBtn: true,
      icon: filters,
      // onClick: openFilter,
    },
  ];

  const getFilterData = (data) => {
    setFilterData(data);
    setCurrentPage(1);
  };

  const showFilters = useCallback(() => {
    return (
      <FilterModal
        getData={getFilterData}
        data={filterData}
        clearFiltersData={clearFiltersData}
        setClearFilterData={setClearFilterData}
      />
    );
  }, [filterData, clearFiltersData]);

  const renderSearchBox = useMemo(() => {
    return (
      <SearchBox
        placeHolder='Search a article by title'
        searchMethod='SEARCHARTICLE'
        label='title'
        searchId='Article'
        renderPath='/articles/{source_id}/edit'
        imagePath='image_url'
      />
    );
  }, []);

  const clearFilters = () => {
    setFilterData(new Object());
    setClearFilterData(!clearFiltersData);
  };

  const renderFilterChips = useMemo(() => {
    const ignoreKeys = ['tag_ids', 'tags_data', 'category_ids', 'author_ids'];
    if (checkObjectLength(filterData, ignoreKeys)) {
      return (
        <div className='mt-3'>
          <FilterChips clearData={clearFilters} setData={setFilterData} data={filterData} />
        </div>
      );
    } else {
      if (Object.keys(filterData).length > 0) {
        clearFilters();
      }
      return;
    }
  }, [filterData]);

  const articleBody = () => (
    <>
      <div className='articleHeader stickyHeader'>
        <div className='headerFiles'>
          <AdminPanelHeader iconTitle='description' title='Article' searchBox={renderSearchBox} />
          <DashBoardHeader leftData={leftSideData} data={headerData} />
        </div>
        <div className='hrLine' />
        <div className='teamBody'>
          {isShowAlert && (
            <div className='mt-3'>
              <CommonAlert isShowAlert={isShowAlert} setIsShowAlert={setIsShowAlert} />{' '}
            </div>
          )}
          <PostHeader
            postLinks={postLinks}
            data={articlesCount}
            onClickHandle={(status) => {
              setCurrentPage(1);
              setStatus(status);
            }}
          />
        </div>
      </div>
      <div className='articleBody wrapperContent'>
        {renderFilterChips}

        <div className='articlePosts'>
          {articlesData?.length > 0 ? <ArticleDesign data={articlesData} /> : renderEmptyView()}
        </div>
        <div className='d-flex justify-content-end'>
          <Pagination
            data={articlesData}
            length={articlesCount?.all_article_count}
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </>
  );

  const renderEmptyView = () => {
    return <EmptyView hide={isLoading || hideEmpty} title='No data found' />;
  };

  return (
    <div className='wrapper'>
      {/* {sideBar} */}
      {articleBody()}
      <Loader show={isLoading} />
      {showFilters()}
    </div>
  );
}

export default ArticleList;
