import React, { useEffect, useRef, useState } from 'react';
import { closeIcon } from 'res/images';
import '../../whealth-core-web/components/filterModal.scss';
import useApiManager from 'networking/ApiManager';
import PatientQuestionsView from './PatientQuestionsView';

function PatientConversion(props) {
  const ApiClient = useApiManager();

  const { patientId, getAdminProfiles, isClearVal, setIsClearVal } = props;

  const [questionData, setQuestionData] = useState([]);
  const filterHeader = () => {
    return (
      <div className='filterHeader'>
        <div>Conversion Logs</div>
        <div
          onClick={() => {
            setIsClearVal(true);
          }}
          className=''
          data-bs-dismiss='offcanvas'
          aria-label='Close'
        >
          <img src={closeIcon} />
        </div>
      </div>
    );
  };

  const getQuestion = (newId) => {
    // setIsLoading(true);
    let params = { page: 1 };
    ApiClient.behaviourQuestionList(patientId || newId, params)
      .then((res) => {
        // setIsLoading(false);
        setQuestionData(res.data);
      })
      .catch((err) => {
        // setIsLoading(false);
      });
  };

  useEffect(() => {
    if (patientId) {
      getQuestion();
    }
  }, [patientId]);
  const payer_status = [
    { title: 'Self', id: '1' },
    { title: 'Insurance', id: '2' },
    { title: 'CGHS', id: '3' },
  ];
  const createQuestions = () => {
    return (
      <>
        <PatientQuestionsView
          isClearVal={isClearVal}
          getAdminProfiles={getAdminProfiles}
          patientId={patientId}
          questionData={questionData}
          getQuestion={getQuestion}
        />
        {/* <RenderNotesTab patientId={patientId} /> */}
      </>
    );
  };

  const renderModal = () => {
    return (
      <div className='filterModal'>
        <div
          className='offcanvas offcanvas-end'
          tabIndex='-1'
          id='offcanvasRight3'
          aria-labelledby='offcanvasRightLabel'
        >
          <div style={{}} className='whiteBackground'>
            {filterHeader()}
            <div className='hrLine' />
            <div className='filterHeaderData mt-3'>{createQuestions()}</div>
          </div>
        </div>
      </div>
    );
  };

  return renderModal();
}

export default PatientConversion;
