import React, { useState } from 'react';
import { editTheme, search, trashRed } from 'res/images';

// import { dateFormat, teamRoles, timeFormat } from './Helper';
import '../listView.css';
import CommonToolTip from '../CommonToolTip';
import CoreInputBox from '../CoreInputBox';
import { dateFormat, teamRoles } from '../Helper';
import EmptyView from '../EmptyView';
import { Toggle } from 'rsuite';
import useApiManager from 'networking/ApiManager';

function RegimeTemplateList(props) {
  const {
    showKey,
    regimeTemplateList,
    editRegimeTemplate,
    deleteRegimeTemplate,
    searchRegimeTemplate,
    getTemplatesList,
    setIsUpdateAlert,
    setIsUpdateAlertType,
    setAlertType,
    setIsShowAlert,
  } = props;

  const [errors, setErrors] = useState({});
  const ApiClient = useApiManager();

  const setAlert = (alertMsg, alertType) => {
    setAlertType(alertType);
    setIsShowAlert(alertMsg);
  };

  const disableOrEnableTemplate = (item, status) => {
    ApiClient.updateRegimeTemplate(item.id, { is_disabled: status })
      .then((res) => {
        getTemplatesList();
        setAlert(`Conversion log Template ${status ? 'Disabled' : 'Enabled'} Successfully`, 'alert-success');
      })
      .catch((err) => {
        console.warn('err?.response', err?.response);
        if (err?.response?.data?.errors?.non_field_errors) {
          setIsUpdateAlert(err?.response?.data?.errors?.non_field_errors[0]);
          setIsUpdateAlertType('alert-danger');
        } else if (err?.response?.data?.errors) {
          setErrors(err?.response?.data?.errors);
        } else {
          setErrors({});
        }
      });
  };

  const showData = () => {
    return (
      <>
        <div className='d-flex mt-4 justify-content-end'>
          <CoreInputBox placeholder='All Templates Search' setValue={searchRegimeTemplate} leftIcon={search} />
        </div>
        {regimeTemplateList?.length ? (
          <div className={!showKey ? 'tagsView' : 'notesList'}>
            <div className='table-responsive'>
              <table className='w-100 table'>
                <tbody>
                  {regimeTemplateList?.length > 0
                    ? regimeTemplateList.map((item, index) => {
                        return (
                          <tr>
                            <td className='w-100' style={{ textAlign: 'left' }}>
                              <div className=''>
                                <span class=' capitalize lightGrayBadge'>{item.title}</span>
                              </div>
                              <div>
                                {item.questions_count ? (
                                  <span class='badge capitalize lightGrayBadge my-2'>
                                    {item.questions_count} {item.questions_count > 1 ? 'questions' : 'question'} in Template
                                  </span>
                                ) : null}
                                {item.default ? <span class='badge capitalize lightGrayBadge m-2'>Default Template</span> : null}
                              </div>
                            </td>
                            <td>
                              <div className='listNotsright'>
                                <div className='metaDetails mb-4 me-3'>
                                  <div>
                                    Created by · {item.admin.full_name} ({teamRoles(item.admin.role)})
                                    <div>
                                      Created <span className='lowercase'> at </span> · {dateFormat(item.created_at)}
                                    </div>
                                  </div>
                                </div>
                                <div className='d-flex tagsViewIcons  mb-4'>
                                  <div
                                    onClick={() => {
                                      editRegimeTemplate(item);
                                    }}
                                  >
                                    <CommonToolTip placement={'top'} item={<img src={editTheme} />} showValue={'Edit'} />
                                  </div>
                                  <div
                                    onClick={() => {
                                      const windowConfirm = window.confirm('Are you sure you want to delete ?');
                                      if (windowConfirm) {
                                        deleteRegimeTemplate(item.id);
                                      }
                                    }}
                                  >
                                    <CommonToolTip placement={'top'} item={<img src={trashRed} />} showValue={'Delete'} />
                                  </div>
                                  <Toggle
                                    key={item?.id}
                                    className='me-2 '
                                    onChange={() => disableOrEnableTemplate(item, !item?.is_disabled)}
                                    type='checkbox'
                                    id={`labelcheck${item?.id}}`}
                                    defaultChecked={!item?.is_disabled}
                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    : null}

                  {/* );
                })} */}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <EmptyView />
        )}
      </>
    );
  };

  return <div>{showData()} </div>;
}

export default RegimeTemplateList;
