import React, { useEffect, useState } from 'react';
import StatusBar from './StatusBar';
import { Popover, Whisper, Dropdown } from 'rsuite';
import { laptop, laptopLink, payment, paymentLink, user, userLink } from 'whealth-core-web/assets/images';
import { dateFormat, timeFormat, titlizeString } from '../Helper';
import { Link } from 'react-router-dom';

function DoctorSchedulTile(props) {
  let environment = process.env.REACT_APP_ENVIRONMENT;
  let serviceID = 3;

  const { className, appointmentDetail, handleAppointmentEdit, setOpen, setCancelModal, height, isBabyMd, setCreatedAppointment, userData, setStatusItem } =
    props;

  const renderStatus = () => {
    if (appointmentDetail?.consultation_status == 'overdue') {
      return <StatusBar className='overdue' icon='schedule' status='Overdue' />;
    } else if (appointmentDetail?.consultation_status == 'no_show') {
      return <StatusBar className='noshow' icon='cancel' status='No Show' />;
    } else if (appointmentDetail?.consultation_status == 'arrived') {
      return <StatusBar className='visited' icon='check_circle' status='Arrived' />;
    } else if (appointmentDetail?.consultation_status == 'canceled') {
      return <StatusBar className='overdue' icon='cancel' status='Canceled' />;
    } else if (appointmentDetail?.consultation_status == 'show') {
      return <StatusBar className='visited' icon='check_circle' status='Visited' />;
    } else {
      return <StatusBar className='scheduled' icon='check_circle' status='Scheduled' />;
    }
  };

  const status = [
    { title: 'Arrived', id: 'show' },
    { title: 'No Show', id: 'no_show' },
    (appointmentDetail.consultation_status == 'no_show' ||
      appointmentDetail.consultation_status == null ||
      appointmentDetail.consultation_status == 'overdue') && {
      title: 'canceled',
      id: 'canceled',
    },
  ];

  const calculateAgeInMonths = (dob) => {
    const currentDate = new Date();
    const birthDate = new Date(dob);
    const months = (currentDate?.getFullYear() - birthDate?.getFullYear()) * 12 + (currentDate?.getMonth() - birthDate?.getMonth());
    return months;
  };
  const ageInMonths = calculateAgeInMonths(appointmentDetail?.user?.date_of_birth);
  const schedulPopover = (
    <Popover visible className='schedulePopover'>
      <div className='popoverPart'>
        <div className='schedulePopover-head'>
          <div className='tileName'>
            <Link to={userData?.role != 'ee' ? `/patient/${appointmentDetail?.user.id}/dashboard` : null}>
              {appointmentDetail?.user?.full_name} ({appointmentDetail?.user?.gender && appointmentDetail?.user?.gender[0].toUpperCase()},{' '}
              {appointmentDetail?.user?.date_of_birth && isBabyMd && ` ${ageInMonths} M`}
              {!isBabyMd && appointmentDetail?.user?.age})
            </Link>
          </div>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='number'>{appointmentDetail?.user?.phone}</div>
            {!isBabyMd && <div className='number'>{renderStatus()}</div>}
            {appointmentDetail?.consultation_status != 'canceled' && isBabyMd && (
              <div className='number'>
                <Dropdown
                  className={`capitalize pointer patient-list-show-badge ${appointmentDetail.consultation_status}`}
                  title={
                    <span className='capitalize'>
                      {titlizeString(appointmentDetail.consultation_status == 'show' ? 'Arrived' : appointmentDetail.consultation_status) || 'booked'}
                    </span>
                  }
                >
                  {status?.map((statusItem) => {
                    return (
                      <Dropdown.Item
                        onClick={() => {
                          if (statusItem.id == 'show' || statusItem.id == 'no_show' || statusItem.id == 'arrived') {
                            setCreatedAppointment(appointmentDetail);
                            setOpen(true);
                            setStatusItem(statusItem);
                          } else if (statusItem.id == 'canceled') {
                            setCreatedAppointment(appointmentDetail);
                            setCancelModal(true);
                            setStatusItem(statusItem);
                          }
                        }}
                      >
                        {statusItem.title}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown>
              </div>
            )}
          </div>
          <div>Created By : {appointmentDetail?.admin?.full_name}</div>
        </div>

        <div className='schedulePopover-body'>
          <div className='d-flex justify-content-between schedule-part'>
            <div className='d-flex flex-column justify-content-start'>
              <div className='time'>
                {dateFormat(appointmentDetail?.consult_datetime)}, {timeFormat(appointmentDetail?.consult_datetime)} -{' '}
                {timeFormat(appointmentDetail?.consult_end_datetime)}
              </div>
              <span>With {appointmentDetail?.consultant?.full_name}</span>
              {appointmentDetail?.service?.title ? (
                <div className=' d-flex justify-content-start  mt-1'>
                  <div className='follow'>{appointmentDetail?.service?.title}</div>
                </div>
              ) : null}
            </div>
            <div className='d-flex flex-column align-items-center'>
              {appointmentDetail?.consultation_status != 'canceled' &&
              appointmentDetail.patient_attendance_status !== 'show' &&
              appointmentDetail.patient_attendance_status !== 'no_show' ? (
                <div className='d-flex justify-content-center align-items-center flex-column'>
                  {(userData?.role != 'ee' &&
                    userData.role !== 'ee_manager' &&
                    userData?.role !== 'health_coach_lead' &&
                    userData?.role !== 'revenue_manager') ||
                  (appointmentDetail?.admin?.id == userData?.id && userData?.role == 'ee') ||
                  ((userData?.role === 'health_coach_lead' || userData?.role === 'revenue_manager') && appointmentDetail?.admin?.role !== 'ee') ||
                  (userData?.role == 'ee_manager' &&
                    (appointmentDetail?.admin?.role == 'ee' ||
                      (appointmentDetail?.admin?.role == 'ee_manager' && appointmentDetail?.admin?.id == userData?.id))) ? (
                      <div
                        onClick={() => handleAppointmentEdit(appointmentDetail?.id)}
                        className='d-flex justify-content-center align-items-center popover-btn'
                      >
                      <span class='material-icons-outlined'>mode_edit_outline</span> Edit
                    </div>
                  ) : null}
                  {(userData?.role != 'ee' &&
                    userData.role !== 'ee_manager' &&
                    userData?.role !== 'health_coach_lead' &&
                    userData?.role !== 'revenue_manager') ||
                  (appointmentDetail?.admin?.id == userData?.id && userData?.role == 'ee') ||
                  ((userData?.role === 'health_coach_lead' || userData?.role === 'revenue_manager') && appointmentDetail?.admin?.role !== 'ee') ||
                  (userData?.role == 'ee_manager' &&
                    (appointmentDetail?.admin?.role == 'ee' ||
                      (appointmentDetail?.admin?.role == 'ee_manager' && appointmentDetail?.admin?.id == userData?.id))) ? (
                    <div
                      onClick={() => {
                        setCreatedAppointment(appointmentDetail);
                        setCancelModal(true);
                      }}
                      className='d-flex justify-content-center align-items-center popover-btn'
                    >
                      <span class='material-icons-outlined'>close</span> Cancel
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
          {appointmentDetail?.consult_type == 'virtual' ? (
            <div className='d-flex justify-content-between schedule-part'>
              <div className='d-flex flex-column justify-content-start'>
                <span>
                  <img src={laptopLink} /> Online Consultation
                </span>
              </div>
              {((userData?.role != 'ee' && userData?.role !== 'ee_manager') ||
                appointmentDetail?.admin?.id == userData?.id ||
                (userData?.role == 'ee_manager' && (appointmentDetail?.admin?.role == 'ee' || appointmentDetail?.admin?.role == 'ee_manager'))) &&
              appointmentDetail?.consultation_status != 'canceled' &&
              appointmentDetail?.consult_type == 'virtual' &&
              appointmentDetail?.meeting_link &&
              appointmentDetail?.service?.id != serviceID ? (
                <a target='_blank' href={appointmentDetail?.meeting_link} className='d-flex flex-column align-items-center'>
                  <div className='d-flex justify-content-center align-items-center flex-column'>
                    <div className='d-flex justify-content-center align-items-center popover-btn disabled'>Join</div>
                  </div>
                </a>
              ) : null}
            </div>
          ) : null}

          <div className='d-flex justify-content-between  schedule-part border-0'>
            <div className='d-flex flex-column justify-content-start'>
              <span>
                <img src={paymentLink} /> Payment
              </span>
              <p>{titlizeString(appointmentDetail?.payment_type)}</p>
              {appointmentDetail?.payment_type == 'partially_paid' ? (
                <p>
                  Amount paid: ₹{appointmentDetail?.amount} (left ₹{parseFloat(appointmentDetail?.appointment_fee - appointmentDetail?.amount).toFixed(2)})
                </p>
              ) : null}

              {appointmentDetail?.payment_with?.name || appointmentDetail?.payment_with?.full_name ? (
                <p>With: {appointmentDetail?.payment_with?.name || appointmentDetail?.payment_with?.full_name}</p>
              ) : null}
            </div>
            <div className='d-flex flex-column align-items-center'>
              <div className='d-flex justify-content-center align-items-center flex-column'>
                {appointmentDetail?.patient_attendance_status != 'no_show' &&
                appointmentDetail?.patient_attendance_status != 'show' &&
                appointmentDetail?.service?.id != serviceID &&
                ((userData?.role === 'ee' && appointmentDetail?.admin?.id === userData?.id) ||
                  (userData?.role === 'ee_manager' &&
                    (appointmentDetail?.admin?.role === 'ee' ||
                      (appointmentDetail?.admin?.role === 'ee_manager' && appointmentDetail?.admin?.id === userData?.id))) ||
                  (userData?.role !== 'ee' && userData.role !== 'ee_manager')) ? (
                  <div
                    className='d-flex justify-content-center align-items-center popover-btn active'
                    onClick={() => {
                      setCreatedAppointment(appointmentDetail);
                      setOpen(true);
                      setStatusItem('');
                    }}
                  >
                    Record
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Popover>
  );
  // const appointmentPopover = (
  //   <div style={{ height: 100, position: 'absolute' }}>
  //     <Popover visible>
  //       <div className='d-flex w-100 flex-column appointmentPopover schedulButton'>
  //         <div className='reusableBtnActive  reusableBtn text-nowrap  w-100 mb-2'>+ Add New Appointment</div>
  //         <div className='w-100 borderBtn mb-2 text-nowrap'>Mark As Unavailable</div>
  //       </div>
  //     </Popover>
  //   </div>
  // );

  // const actions = [
  //   { first: paymentLink, second: payment },
  //   { first: laptopLink, second: laptop },
  //   { first: user, second: userLink },
  // ];

  // const HoverImage = ({ first, second }) => {
  //   const [firstImage, setFirstImage] = useState(first);
  //   return (
  //     <div className='scheduleIcon'>
  //       <img src={firstImage} onMouseEnter={() => setFirstImage(second)} onMouseLeave={() => setFirstImage(first)} />

  //     </div>
  //   );
  // };

  return (
    <>
      <div className={`d-flex scheduleTile ${className}`} style={{ justifyContent: 'space-between', padding: '3px' }}>
        <div style={{ width: '90%' }}>
          <Whisper placement='auto' trigger='hover' speaker={schedulPopover} controlId='control-id-hover-enterable' enterable>
            <span
              className='tileName'
              style={{
                color: '#333',
                fontFamily: 'Inter',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '12px',
              }}
            >
              {appointmentDetail?.user?.full_name} ({appointmentDetail?.user?.gender && appointmentDetail?.user?.gender[0].toUpperCase()},{' '}
              {appointmentDetail?.user?.date_of_birth && isBabyMd && ` ${ageInMonths} M`}
              {!isBabyMd && appointmentDetail?.user?.age})
            </span>
          </Whisper>
          {height > 32 && <p className='p-0 m-0'>{appointmentDetail?.user?.phone}</p>}
          {height > 40 && <p className='p-0 m-0'>Created By : {appointmentDetail?.admin?.full_name}</p>}
          {height > 60 && (
            <div className='d-flex justify-content-between'>
              {renderStatus()}
              {/* <div className='d-flex'>
              {actions.map((action, index) => (
                <HoverImage key={index} first={action.first} second={action.second} />
              ))}
            </div> */}
              {/* <div className='d-flex'>
            <div className={`scheduleIcon me-1 ${appointmentDetail.amount!=='null'?'active':''}`}>
                {!appointmentDetail.amount!=='null'?<img src={payment}/>:
                <img src={paymentLink}/>}
              </div>
              <div className={`scheduleIcon me-1 ${appointmentDetail.consult_type=='physical'?'active':''}`}>
                {!appointmentDetail.consult_type=='virtual'?<img src={laptop}/>:
                <img src={laptopLink}/>}
              </div>
              <div className={`scheduleIcon ${appointmentDetail.consult_type=='virtual'?'active':''}`}>
                {!appointmentDetail.consult_type=='virtual'?<img src={user}/>:
                <img src={userLink}/>}
              </div>
            </div> */}
            </div>
          )}
        </div>
        {appointmentDetail?.service?.title.toLowerCase() === 'procedure' ||
        appointmentDetail?.service?.title.toLowerCase() === 'consult' ||
        appointmentDetail?.service?.title.toLowerCase() === 'follow up' ||
        appointmentDetail?.service?.title.toLowerCase() === 'break' ||
        appointmentDetail?.service?.title.toLowerCase() === 'care plan' ? (
          <div
            style={{
              backgroundColor: '#DCDFE3',
              height: '14px',
              width: '14px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '0 0',
              borderRadius: '50%',
            }}
          >
            <p
              style={{
                color: '#243B86',
                fontFamily: 'Inter',
                fontSize: '6px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '0px',
              }}
            >
              {appointmentDetail?.service?.title.toLowerCase() === 'procedure' && 'P'}
              {appointmentDetail?.service?.title.toLowerCase() === 'consult' && 'C'}
              {appointmentDetail?.service?.title.toLowerCase() === 'follow up' && 'F'}
              {appointmentDetail?.service?.title.toLowerCase() === 'break' && 'B'}
              {appointmentDetail?.service?.title.toLowerCase() === 'care plan' && 'CP'}
            </p>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default DoctorSchedulTile;
