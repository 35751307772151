import { AdminPanelHeader } from 'components';
import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import AddServiceForm from './AddServiceForm';
import { CommonAlert, CoreInputBox, Loader, Select2DropDown, TagSelectPicker } from './components';
import { profileImage, scrollToTop } from './components/Helper';
import CurrencyInput from 'react-currency-input-field';
import { useGetLoginUserDataQuery } from 'redux/apiSlice';

function AddService(props) {
  const {
    serviceDoctorData,
    setServiceDoctorData,
    crpDetails,
    setCrpDetails,
    setheaderServiceDetail,
    setheaderLocationDetail,
    setheaderLocationDetailList,
    setheaderDoctorDetail,
    setheaderDoctorDetailList,
    setheaderEstimateCostDetail,
    setHasSaveButton,
    setCurrentStep,
    steps,
    currentStep,
    dynamicServiceData,
    hasSaveButton,
    setDynamicServiceData,
    setServiceDataForPayment,
    serviceDataForPayment,
    hospitalData,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [errors, setErrors] = useState({});
  const [patientDetails, setPatientDetails] = useState({});
  const [alertType, setAlertType] = useState('alert-success');
  const [serviceData, setServiceData] = useState([]);
  const [serviceDropdown, setServiceDropdown] = useState('');
  const [doctorDropdown, setDoctorDropdown] = useState('');
  const [careManagerDropdown, setCareManagerDropdown] = useState('');
  const [clinicDropdown, setClinicDropdown] = useState('');
  const [serviceCategoryValue, setServiceCategoryValue] = useState('');
  const [serviceCategoryData, setServiceCategoryData] = useState([]);
  const [careManagersList, setCareManagersList] = useState([]);
  const [selectedCatObj, setSelectedCatObj] = useState({});
  const [serviceDropdownVal, setServiceDropdownVal] = useState('');
  const [statusValue, setStatusValue] = useState('scheduled');
  const [serviceSchemaData, setServiceSchemaData] = useState('');
  const [estimateCost, setEstimateCost] = useState('');
  const [typeOfCrp, setTypeOfCrp] = useState('');
  const [appointmentVal, setAppointmentVal] = useState('');
  const [appointmentList, setAppointmentList] = useState([]);
  const [ifSingleMultiSpec, setIfSingleMultiSpec] = useState(false);
  const ApiClient = useApiManager();
  const navigation = useNavigate();
  const { id, pid } = useParams();
  let userDetails = localStorage.getItem('userDetails');
  userDetails = JSON.parse(userDetails);

  useEffect(() => {
    getServiceList();
    getServiceCategories(serviceDropdown);
    getAppointmentList();
    // getServicesDoctor();
  }, []);

  useEffect(() => {
    if (steps[currentStep] == 'Service Details' && !serviceDropdown && hasSaveButton) {
      getServicesClinic(clinicDropdown);
      setServiceDropdown(serviceDataForPayment?.service);
      setServiceCategoryValue(serviceDataForPayment?.service_category);
      setClinicDropdown(serviceDataForPayment?.location);
      setEstimateCost(serviceDataForPayment?.estimated_cost);
      setDoctorDropdown(serviceDataForPayment?.doctor);
      setCareManagerDropdown(serviceDataForPayment?.care_manager);
      setStatusValue(serviceDataForPayment?.status);
      setServiceSchemaData({ service_schema: serviceDataForPayment?.data });
      setAppointmentVal(serviceDataForPayment?.appointment);
      setTypeOfCrp(serviceDataForPayment?.crp_details?.map((item) => item.crp_type));

      const updatedCrpDetails = serviceDataForPayment?.crp_details;
      updatedCrpDetails?.map((item, index) => {
        ApiClient.getCareManagersByClinic('&serviceflow=true')
          .then((res) => {
            setIsLoading(false);
            updatedCrpDetails[index]['care_manager_list'] = res.data;
          })
          .catch((err) => {
            setIsLoading(false);
            console.log(err);
          });
        let params = { service_id: serviceDataForPayment?.service, location_id: item?.location };

        updatedCrpDetails[index]['doctor'] = updatedCrpDetails[index]['doctor']?.id
          ? updatedCrpDetails[index]['doctor']?.id
          : updatedCrpDetails[index]['doctor'];
        updatedCrpDetails[index]['location'] = updatedCrpDetails[index]['location']?.id
          ? updatedCrpDetails[index]['location']?.id
          : updatedCrpDetails[index]['location'];
        updatedCrpDetails[index]['care_manager'] = updatedCrpDetails[index]['care_manager']?.id
          ? updatedCrpDetails[index]['care_manager']?.id
          : updatedCrpDetails[index]['care_manager'];
        // ApiClient.getPatientServiceDoctor(params)
        //   .then((res) => {
        //     setIsLoading(false);
        //     updatedCrpDetails[index]['doctor_list'] = res.data;

        //     setErrors({});
        //   })
        //   .catch((err) => {
        //     setIsLoading(false);
        //     setErrors(err.response.data);

        //     console.log(err);
        //   });
      });
      setCrpDetails(updatedCrpDetails);
    }
  }, [steps, currentStep]);

  useEffect(() => {
    // setClinicDropdown('');
    // setServiceSchemaData({});
    // setDoctorDropdown('');
    // setCareManagerDropdown('');
    if (dynamicServiceData?.service_schema) {
      setServiceSchemaData({ service_schema: dynamicServiceData?.service_schema });
    }
  }, [serviceDropdownVal]);

  useEffect(() => {
    if (pid) {
      getServiceData();
      getServicesDoctor(dynamicServiceData?.service?.id, dynamicServiceData?.location?.id);
      getServicesClinic(clinicDropdown);
    } else {
      getServicesDoctor(dynamicServiceData?.service?.id, dynamicServiceData?.location?.id);
      getServicesClinic(clinicDropdown);
    }
  }, [pid, dynamicServiceData?.service?.id, dynamicServiceData?.location?.id, serviceDropdown]);

  useEffect(() => {
    let temp = serviceCategoryData.some(
      (item) => (item?.title?.toLowerCase() === 'single spec' || item?.title?.toLowerCase() === 'multi spec') && item.id === serviceCategoryValue
    );
    setIfSingleMultiSpec(temp);
  }, [serviceCategoryValue, serviceCategoryData]);

  useEffect(() => {
    const currentEstimateCost = crpDetails?.reduce((total, item) => {
      const price = item?.price ? parseInt(item.price, 10) : 0;
      return total + price;
    }, 0);
    setheaderEstimateCostDetail(currentEstimateCost?.toString());

    setEstimateCost(currentEstimateCost?.toString());
  }, [crpDetails]);

  useEffect(() => {
    if (dynamicServiceData?.service?.id && !serviceDropdown) {
      setServiceDropdown(dynamicServiceData?.service?.id);
    }
    if (dynamicServiceData?.service_category?.id) {
      setServiceCategoryValue(dynamicServiceData?.service_category?.id);
    }
    if (dynamicServiceData?.location?.id && !serviceDropdown) {
      setClinicDropdown(dynamicServiceData?.location?.id);
    }
    if (dynamicServiceData?.estimated_cost) {
      setEstimateCost(dynamicServiceData?.estimated_cost);
    }
    if (dynamicServiceData?.doctor?.id && !serviceDropdown) {
      setDoctorDropdown(dynamicServiceData?.doctor?.id);
    }
    if (dynamicServiceData?.care_manager?.id && !serviceDropdown) {
      setCareManagerDropdown(dynamicServiceData?.care_manager?.id);
    }
    if (dynamicServiceData?.status) {
      setStatusValue(dynamicServiceData?.status);
    }
    if (dynamicServiceData?.appointment) setAppointmentVal(dynamicServiceData?.appointment);
    if (dynamicServiceData?.crp_details?.length && !serviceDropdownVal) {
      setTypeOfCrp(dynamicServiceData?.crp_details?.map((item) => item.crp_type));

      const updatedCrpDetails = dynamicServiceData?.crp_details;
      updatedCrpDetails?.map((item, index) => {
        ApiClient.getCareManagersByClinic('&serviceflow=true')
          .then((res) => {
            setIsLoading(false);
            updatedCrpDetails[index]['care_manager_list'] = res.data;
          })
          .catch((err) => {
            setIsLoading(false);
            console.log(err);
          });
        let params = { service_id: serviceDropdown, location_id: item?.location };
        updatedCrpDetails[index]['doctor'] = updatedCrpDetails[index]['doctor']?.id
          ? updatedCrpDetails[index]['doctor']?.id
          : updatedCrpDetails[index]['doctor'];
        updatedCrpDetails[index]['location'] = updatedCrpDetails[index]['location']?.id
          ? updatedCrpDetails[index]['location']?.id
          : updatedCrpDetails[index]['location'];
        updatedCrpDetails[index]['care_manager'] = updatedCrpDetails[index]['care_manager']?.id
          ? updatedCrpDetails[index]['care_manager']?.id
          : updatedCrpDetails[index]['care_manager'];
        // ApiClient.getPatientServiceDoctor(params)
        //   .then((res) => {
        //     setIsLoading(false);
        //     updatedCrpDetails[index]['doctor_list'] = res.data;

        //     setErrors({});
        //   })
        //   .catch((err) => {
        //     setIsLoading(false);
        //     setErrors(err.response.data);

        //     console.log(err);
        //   });
      });
      setCrpDetails(updatedCrpDetails);
    }
    if (dynamicServiceData?.data && !serviceDropdownVal) {
      setServiceSchemaData({ service_schema: dynamicServiceData?.data });
    }
  }, [dynamicServiceData?.data, dynamicServiceData?.service_category?.id, dynamicServiceData?.data]);

  const handleCrpDetailsChange = (item, key, value) => {
    const updatedCrpDetails = JSON.parse(JSON.stringify(crpDetails));
    const itemIndex = updatedCrpDetails.findIndex((crpItem) => crpItem?.crp_type === item?.crp_type);

    if (itemIndex !== -1) {
      if ((key === 'session_booked' && value === '') || (key === 'session_delivered' && value === '') || (key === 'price' && value === '')) {
        value = '0';
      }
      updatedCrpDetails[itemIndex][key] = value;
      if (key === 'location') {
        updatedCrpDetails[itemIndex]['care_manager'] = '';
        updatedCrpDetails[itemIndex]['doctor'] = '';
        ApiClient.getCareManagersByClinic('&serviceflow=true')
          .then((res) => {
            setIsLoading(false);
            updatedCrpDetails[itemIndex]['care_manager_list'] = res.data;
          })
          .catch((err) => {
            setIsLoading(false);
            console.log(err);
          });
        let params = { service_id: serviceDropdown, location_id: value };

        // ApiClient.getPatientServiceDoctor(params)
        //   .then((res) => {
        //     setIsLoading(false);
        //     updatedCrpDetails[itemIndex]['doctor_list'] = res.data;

        //     setErrors({});
        //   })
        //   .catch((err) => {
        //     setIsLoading(false);
        //     setErrors(err.response.data);
        //     updatedCrpDetails[itemIndex]['doctor_list'] = [];

        //     console.log(err);
        //   });
      }
      // Set the state with the updated array
      setCrpDetails(updatedCrpDetails);
    }
  };
  const renderCrpDetails = () => {
    return crpDetails?.map((item, index) => {
      return (
        <>
          <h4>{item?.crp_type}</h4>

          <div className='mt-3'>
            <CoreInputBox
              // retuired
              validateNumbers
              validateZero
              validatedecimal
              label={'Session Booked'}
              placeholder={'Session Booked'}
              // showMSG={''}
              value={item?.session_booked || '0'}
              setValue={(data, dataObj) => handleCrpDetailsChange(item, 'session_booked', data)}
            />
          </div>
          <div className='mt-3'>
            <CoreInputBox
              // disabled={data?.payment_type == 'paid' || appointmentDetail?.payment_type == 'paid' || appointmentDetail?.payment_type == 'free'}
              // retuired
              validateNumbers
              validateZero
              validatedecimal
              label={'Session Delivered'}
              placeholder={'Session Delivered'}
              // showMSG={errors?.amount}
              value={item?.session_delivered || 0}
              setValue={(data, dataObj) => handleCrpDetailsChange(item, 'session_delivered', data)}
            />
          </div>
          <div className='mt-3'>
            {/* <CoreInputBox
              // disabled={data?.payment_type == 'paid' || appointmentDetail?.payment_type == 'paid' || appointmentDetail?.payment_type == 'free'}
              // retuired
              validateNumbers
              validateZero
              validatedecimal
              label={'Price'}
              placeholder={'Price'}
              // showMSG={errors?.amount}
              value={item?.price || 0}
              setValue={(data, dataObj) => handleCrpDetailsChange(item, 'price', data)}
            /> */}

            <div className='inputFieldHeader'>
              <label className='inputlabel' htmlFor='validationCustom01 mt-3 slot-name'>
                {/* <span style={{ color: 'red' }}>*&nbsp;</span> */}
                {'Price'}
              </label>
            </div>
            <CurrencyInput
              // disabled={editIndex > 0 || (!editData?.id && dynamicServiceData?.final_bill_amount > 0)}
              id='validationCustom01'
              name='input-1'
              className={`form-control coreInput`}
              label='Price'
              value={item?.price || 0}
              // showMSG={errors?.crp_final_bill_amounts?.[crpTypeFinalAmount?.title?.toLowerCase()]}
              msgStyle={{ color: 'red' }}
              onValueChange={(data) => {
                handleCrpDetailsChange(item, 'price', data);
              }}
              placeholder={'Price'}
              prefix={'₹'}
              step={1}
            />
            {/* <p className='capitalize' style={{ color: 'red' }}>
              {errors?.crp_final_bill_amounts?.[crpTypeFinalAmount?.title?.toLowerCase()]}
            </p> */}
          </div>
          <div className='mt-3'>
            <div className='daypart-input behaviourpart_box w-100'>
              <Select2DropDown
                showMSG={errors?.data?.length ? errors?.data?.find((obj) => 'location_' + item?.crp_type in obj)?.['location_' + item?.crp_type] || '' : ''}
                value={item?.location}
                data={hospitalData}
                label={'Select Hospital'}
                placeholder={'Select Hospital'}
                valueKey='id'
                labelKey='title'
                getObjData
                retuired
                onSelect={(locationId, obj) => {
                  handleCrpDetailsChange(item, 'location', locationId);
                }}
              />
            </div>
          </div>
          <div className='mt-3'>
            <div className='daypart-input behaviourpart_box w-100'>
              <Select2DropDown
                showMSG={errors?.data?.length ? errors?.data?.find((obj) => 'doctor_' + item?.crp_type in obj)?.['doctor_' + item?.crp_type] || '' : ''}
                value={item?.doctor}
                data={serviceDoctorData}
                label={'Select Doctor'}
                placeholder={'Select Doctor'}
                valueKey='id'
                labelKey='full_name'
                getObjData
                retuired
                onSelect={(locationId, obj) => {
                  handleCrpDetailsChange(item, 'doctor', locationId);
                }}
              />
            </div>
          </div>
          <div className='mt-3'>
            <div className='daypart-input behaviourpart_box w-100'>
              <Select2DropDown
                showMSG={
                  errors?.data?.length ? errors?.data?.find((obj) => 'care_manager_' + item?.crp_type in obj)?.['care_manager_' + item?.crp_type] || '' : ''
                }
                value={item?.care_manager}
                data={careManagersList}
                label={'Select Care Manager'}
                placeholder={'Select Care Manager'}
                valueKey='id'
                labelKey='full_name'
                getObjData
                retuired
                onSelect={(locationId, obj) => {
                  handleCrpDetailsChange(item, 'care_manager', locationId);
                }}
              />
            </div>
          </div>
          <br />
        </>
      );
    });
  };

  const getServicesDoctor = (serviceId, locationId) => {
    if (serviceDropdown || serviceId) {
      setIsLoading(true);
      let params = { service_id: serviceDropdown || serviceId, location_id: locationId || 1 };
      ApiClient.getPatientServiceDoctor(params)
        .then((res) => {
          setIsLoading(false);
          setServiceDoctorData(res.data);
          setErrors({});
        })
        .catch((err) => {
          setDoctorDropdown('');
          setIsLoading(false);
          setErrors(err.response.data);
          console.log(err);
        });
    }
  };

  const getServicesDropdownSearch = (text) => {
    setIsLoading(true);
    ApiClient.getServiceDropdownSearch(text)
      .then((res) => {
        setIsLoading(false);
        setServiceData(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getPatientData();
  }, []);

  const getServicesClinic = (HospitalId) => {
    setIsLoading(true);
    ApiClient.getCareManagersByClinic('&serviceflow=true')
      .then((res) => {
        setIsLoading(false);
        setCareManagersList(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getServiceList = () => {
    setIsLoading(true);
    ApiClient.getAppointmentService(1, true)
      .then((res) => {
        setServiceData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  function timeStringChange(utcDateString) {
    const istTimeString = new Date(utcDateString)?.toLocaleTimeString('en-IN', {
      timeZone: 'Asia/Kolkata',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
    const formattedTimeString = istTimeString?.replace(' am', ' AM')?.replace(' pm', ' PM');
    return formattedTimeString;
  }

  const getAppointmentList = () => {
    setIsLoading(true);
    let params = { active: true };
    ApiClient.consultationList(params, id)
      .then((res) => {
        const outputArray = res?.data.map((item) => ({
          ...item,
          display_key: `${item.consult_datetime?.substring(0, 10)} | ${timeStringChange(item.consult_datetime)} - ${timeStringChange(
            item.consult_end_datetime
          )}   | ${item.consultant?.full_name} | ${item.location?.name}`,
        }));
        setAppointmentList(outputArray);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  const getServiceCategories = (serviceId) => {
    setIsLoading(true);
    let params = { service_id: serviceId, enable: true };
    ApiClient.getServiceCategories(params)
      .then((res) => {
        setIsLoading(false);
        setServiceCategoryData(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  const getServiceData = (prescription_id = pid) => {
    setIsLoading(true);
    ApiClient.singleDataService(id, prescription_id || serviceDataForPayment?.id)
      .then((res) => {
        setIsLoading(false);
        if (!!Object.entries(res.data?.data).length) {
          let tmpData = res.data;
          // tmpData.service.service_schema = res.data.data;
          tmpData.isUpdate = true;
          tmpData.emptySchema = res.data.service;
          setDynamicServiceData(tmpData);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const serviceDataCreate = (data) => {
    setIsLoading(true);
    ApiClient.patientServiceCreate(data, id)
      .then((res) => {
        setIsLoading(false);
        setAlertType('alert-success');
        setIsShowAlert('Service created successfully ');
        setHasSaveButton(true);
        setCurrentStep(1);
        scrollToTop();
        setServiceDataForPayment(res.data);
        setCrpDetails(res?.data?.crp_details);
        // setTimeout(() => {
        //   navigation(`/patient/${id}/dashboard`);
        // }, 2500);
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors(err.response.data.errors);
      });
  };

  const updateServiceData = (data) => {
    setIsLoading(true);
    ApiClient.updateServiceData(id, pid || serviceDataForPayment?.id, data)
      .then((res) => {
        setIsLoading(false);
        setAlertType('alert-success');
        setIsShowAlert('Prescription updated successfully ');
        setHasSaveButton(true);
        setCurrentStep(1);
        scrollToTop();
        getServiceData(serviceDataForPayment?.id);
        setCrpDetails(res?.data?.crp_details);
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors(err.response.data.errors);
        if (err.response.data?.errors?.non_field_errors || err.response.data?.errors?.details) {
          setAlertType('alert-danger');
          setIsShowAlert(Object.values(err.response.data?.errors?.non_field_errors || err.response.data.errors)[0]);
        }
      });
  };

  const getPatientData = () => {
    setIsLoading(true);
    ApiClient.getPatientDetails(id)
      .then((res) => {
        setIsLoading(false);
        setPatientDetails(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const statusValueData = [
    {
      title: 'Scheduled',
      id: 'scheduled',
    },
    {
      title: 'Scheduled Due',
      id: 'scheduled-due',
    },
    {
      title: 'Done',
      id: 'done',
    },
    {
      title: 'Dropped',
      id: 'dropped',
    },
    {
      title: 'Delivered',
      id: 'delivered',
    },
    {
      title: 'In-Progress',
      id: 'in-progress',
    },
    {
      title: 'Incomplete',
      id: 'incomplete',
    },
  ];
  const showTree = [
    { iconClass: 'person_outline', title: 'Patient', link: '/patient' },
    {
      icon: profileImage(userDetails.gender, userDetails.image),
      title: userDetails.name,
      link: `/patient/${id}/dashboard`,
    },
    { title: (pid ? 'Update' : 'New') + ' ' + 'Service', link: '' },
  ];
  const showService = (adminData) => (
    <>
      {/* <div className='articleHeader stickyHeader '>
        <div className='headerFiles'>
          <AdminPanelHeader iconTitle='person_outline' showTree={showTree} title='Patient' />
        </div>
        <div className='addnewform-part'>{pid ? 'Update' : 'Add New'} Service</div>
        <div class='hrLine'></div>
        <>
          <br />
          <div className='d-flex justify-content-around'>
            <div className='capitalize'>Gender : {patientDetails?.gender}</div>
            <div>Age : {patientDetails?.age}</div>
            <div className='capitalize'>
              Insurance Category :{' '}
              {patientDetails?.profile_info?.find((item3) => item3?.key == 'insurance_category')?.answer || 'N/A'}
            </div>
          </div>
      </>
      </div> */}
      <div className=''>
        {isShowAlert && (
          <div className='mt-5'>
            <CommonAlert isShowAlert={isShowAlert} alertType={alertType} setIsShowAlert={setIsShowAlert} />
          </div>
        )}
        <div className=' mt-3'>
          <div className='mt-3'>{renderServiceDropdown()}</div>
          {serviceDropdown && serviceData?.some((obj) => obj?.title?.toLowerCase() === 'procedure' && obj?.id === serviceDropdown) && (
            <div className='mt-3'>{renderAppointmentDropdown()}</div>
          )}
          {serviceDropdown && <div className='mt-3'>{renderServiceCategoryDropdown()}</div>}
          {/* {serviceCategoryValue && <div className='mt-3'>{renderTypeCrpDropdown()}</div>} */}
          {ifSingleMultiSpec ? (
            <>
              <div className='mt-3'>{renderTypeCrpDropdown()}</div>
              <div className='mt-3'>{renderCrpDetails()}</div>
            </>
          ) : (
            serviceCategoryValue && (
              <>
                <div className='mt-3'>{renderClinicDropdown()}</div>
                <div className='mt-3'>{renderDoctorDropdown()}</div>
                <div className='mt-3'>{renderCareManagerDropdown()}</div>
              </>
            )
          )}
          {/* {crpDetails?.length ? <div className='mt-3'>{renderClinicDropdown()}</div> : null}
          {crpDetails?.length ? <div className='mt-3'>{renderDoctorDropdown()}</div> : null}
          {crpDetails?.length ? <div className='mt-3'>{renderCareManagerDropdown()}</div> : null} */}

          {(pid || serviceDropdown) && (
            <div className='mt-3'>
              <Select2DropDown
                retuired
                label='status'
                data={statusValueData}
                value={statusValue}
                onClean={() => setStatusValue(null)}
                showMSG={errors?.status}
                onSelect={(id) => setStatusValue(id)}
              />
            </div>
          )}
          {serviceDropdown ? (
            serviceCategoryData?.length && serviceCategoryData[0]?.service?.service_schema?.length ? (
              <div className='mt-3'>
                <AddServiceForm
                  estimateCost={estimateCost}
                  setEstimateCost={setEstimateCost}
                  setheaderEstimateCostDetail={setheaderEstimateCostDetail}
                  selectedCatObj={selectedCatObj}
                  setSelectedCatObj={setSelectedCatObj}
                  careManagerDropdown={careManagerDropdown}
                  doctorDropdown={doctorDropdown}
                  clinicDropdown={clinicDropdown}
                  patientId={id}
                  errors={errors}
                  serviceSchemaData={serviceSchemaData}
                  statusValue={statusValue}
                  onClick={pid || serviceDataForPayment?.id ? updateServiceData : serviceDataCreate}
                  dynamicServiceData={dynamicServiceData}
                  setDynamicServiceData={setDynamicServiceData}
                  serviceDropdown={serviceDropdown}
                  serviceCategoryValue={serviceCategoryValue}
                  pid={pid}
                  crpDetails={crpDetails}
                  ifSingleMultiSpec={ifSingleMultiSpec}
                  appointmentVal={appointmentVal}
                  adminData={adminData}
                />
              </div>
            ) : (
              <div className='card mb-3 m-3' style={{ textAlign: 'center' }}>
                {serviceCategoryData?.length ? <div className='card-body'> No Schema Found</div> : <div className='card-body'> No Category Found</div>}
              </div>
            )
          ) : null}
        </div>
      </div>
    </>
  );

  const renderServiceDropdown = () => {
    return (
      <div className='daypart-input behaviourpart_box w-100'>
        <Select2DropDown
          disabled={pid || serviceDataForPayment?.id}
          onSearch={(text) => getServicesDropdownSearch(text)}
          showMSG={errors?.service}
          value={serviceDropdown}
          data={!serviceData.find((item) => selectedCatObj?.id == item.id) && selectedCatObj?.id ? [...serviceData, selectedCatObj] : serviceData}
          isValidation
          label={'Select Service'}
          placeholder={'Select Service'}
          valueKey='id'
          labelKey='title'
          getObjData
          retuired
          onSelect={(serviceId, serviceObj) => {
            getServiceCategories(serviceId);
            setServiceDropdown(serviceId);
            setServiceSchemaData(serviceObj);
            setDynamicServiceData(serviceObj);
            setSelectedCatObj(serviceObj);
            setServiceDropdownVal(serviceId);
            setheaderServiceDetail(serviceObj);
            setServiceCategoryValue('');
            setTypeOfCrp('');
            setCrpDetails([]);
            setEstimateCost('');
          }}
        />
      </div>
    );
  };

  const renderServiceCategoryDropdown = () => {
    return (
      <div className='daypart-input behaviourpart_box w-100'>
        <Select2DropDown
          disabled={pid || serviceDataForPayment?.id}
          showMSG={errors?.service_category}
          value={serviceCategoryValue}
          data={serviceCategoryData}
          label={'Service Category'}
          placeholder={'Service Category'}
          valueKey='id'
          labelKey='title'
          getObjData
          retuired
          onSelect={(serviceId, serviceObj) => {
            setTypeOfCrp('');
            setCrpDetails([]);
            setEstimateCost('');
            setClinicDropdown('');
            setDoctorDropdown('');
            setCareManagerDropdown('');
            setServiceCategoryValue(serviceId);
          }}
        />
      </div>
    );
  };
  const renderTypeCrpDropdown = () => {
    const serviceCategoryValueName = serviceCategoryData.find((item) => item?.id === serviceCategoryValue);
    const typeOfCrpList = [
      {
        title: 'Physio',
        value: 'Physio',
      },
      {
        title: 'Psych',
        value: 'Psych',
      },
      {
        title: 'Pain-Th',
        value: 'Pain-Th',
      },
      {
        title: 'Nutri',
        value: 'Nutri',
      },
    ];

    // serviceCategoryValueName === 'Single Spec';
    return serviceCategoryValueName?.title?.toLowerCase() === 'single spec' ? (
      <div className='daypart-input behaviourpart_box w-100'>
        <Select2DropDown
          disabled={pid || serviceDataForPayment?.id}
          showMSG={errors?.data?.length ? errors?.data?.find((obj) => 'crp_type' in obj)?.['crp_type'] || '' : ''}
          value={Array.isArray(typeOfCrp) && typeOfCrp.length === 1 ? typeOfCrp[0] : typeOfCrp}
          data={typeOfCrpList}
          label={'Type Of CRP'}
          placeholder={'Type Of CRP'}
          valueKey='title'
          labelKey='value'
          getObjData
          retuired
          onSelect={(serviceId, serviceObj) => {
            setTypeOfCrp(serviceId);
            setCrpDetails([
              {
                crp_type: serviceId,
                session_booked: '0',
                session_delivered: '0',
                price: '0',
                location: '',
                care_manager: '',
                care_manager_list: [],
                doctor: '',
                doctor_list: [],
              },
            ]);
          }}
        />
      </div>
    ) : (
      <div className='mb-3 capitalize'>
        <TagSelectPicker
          retuired
          disabled={pid || serviceDataForPayment?.id}
          showMSG={errors?.data?.length ? errors?.data?.find((obj) => 'crp_type' in obj)?.['crp_type'] || '' : ''}
          label={'Type Of CRP'}
          data={typeOfCrpList}
          style={{ width: '100%' }}
          labelKey='title'
          valueKey='value'
          placeholder={'Type Of CRP'}
          onSelect={(val, titleArray) => {
            setTypeOfCrp(titleArray);

            const updatedCrpDetails = JSON.parse(JSON.stringify(crpDetails));

            // Add missing objects
            titleArray.forEach((title) => {
              const exists = updatedCrpDetails.some((item) => item.crp_type === title);
              if (!exists) {
                updatedCrpDetails.push({
                  crp_type: title,
                  session_booked: '0',
                  session_delivered: '0',
                  price: '0',
                  location: '',
                  care_manager: '',
                  care_manager_list: [],
                  doctor: '',
                  doctor_list: [],
                });
              }
            });

            // Remove objects not present in titleArray
            const filteredCrpDetails = updatedCrpDetails.filter((item) => titleArray.includes(item.crp_type));

            setCrpDetails(filteredCrpDetails);
            // setDynamicServiceData({ ...dynamicServiceData });
          }}
          msgStyle={{ color: 'red' }}
          value={typeOfCrp}
        />
      </div>
    );
  };

  const renderAppointmentDropdown = () => {
    return (
      <div className='daypart-input behaviourpart_box w-100'>
        <Select2DropDown
          disabled={pid || serviceDataForPayment?.id}
          showMSG={errors?.data?.length ? errors?.data?.find((obj) => 'appointment' in obj)?.['appointment'] || '' : ''}
          value={appointmentVal}
          data={appointmentList}
          label={'Appointment'}
          placeholder={'Appointment'}
          valueKey='id'
          labelKey={'display_key'}
          getObjData
          retuired
          onSelect={(serviceId, serviceObj) => {
            setAppointmentVal(serviceId);
          }}
        />
      </div>
    );
  };

  const renderDoctorDropdown = () => {
    return (
      <div className='daypart-input behaviourpart_box w-100'>
        <Select2DropDown
          showMSG={errors?.doctor || errors?.message}
          value={doctorDropdown}
          data={serviceDoctorData}
          label={'Select Doctor'}
          placeholder={'Select Doctor'}
          valueKey='id'
          labelKey='full_name'
          getObjData
          retuired
          onSelect={(serviceId, serviceObj) => {
            setDoctorDropdown(serviceId);
            setheaderDoctorDetail(serviceObj);
          }}
        />
      </div>
    );
  };

  const renderClinicDropdown = () => {
    return (
      <div className='daypart-input behaviourpart_box w-100'>
        <Select2DropDown
          showMSG={errors?.location}
          value={clinicDropdown}
          data={hospitalData}
          label={'Select Hospital'}
          placeholder={'Select Hospital'}
          valueKey='id'
          labelKey='title'
          getObjData
          retuired
          onSelect={(locationId, obj) => {
            setClinicDropdown(locationId);
            getServicesClinic(locationId);
            getServicesDoctor(false, locationId);
            setheaderLocationDetail(obj);
            setCrpDetails([]);
          }}
        />
      </div>
    );
  };

  const renderCareManagerDropdown = () => {
    return (
      <div className='daypart-input behaviourpart_box w-100'>
        <Select2DropDown
          showMSG={errors?.care_manager}
          value={careManagerDropdown}
          data={careManagersList}
          label={'Select CareManager'}
          placeholder={'Select CareManager'}
          valueKey='id'
          labelKey='full_name'
          getObjData
          retuired
          onSelect={(serviceId) => {
            setCareManagerDropdown(serviceId);
          }}
        />
      </div>
    );
  };

  return (
    <div className=''>
      {showService(useGetLoginUserDataQuery()?.data?.data || {})}
      <Loader show={isLoading} />
    </div>
  );
}

export default AddService;
