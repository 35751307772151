import React, { useEffect, useState } from 'react';
import { Select2DropDown } from '../whealth-core-web/components/index';
import { setLocations, setStatus } from 'redux/Slices';
import { useDispatch, useSelector } from 'react-redux';
import useApiManager from 'networking/ApiManager';

function StatusFilterView(props) {
  const { filterData, setFilterData, clearFiltersData } = props;
  const [selectedStatus, setSelectedStatus] = useState();

  const statusList = useSelector((state) => state.sliceReducer.statusList);
  const ApiClient = useApiManager();
  const dispatch = useDispatch();

  useEffect(() => {
    let obj = { status: selectedStatus, sub_status: '' };
    setFilterData(obj);
  }, [selectedStatus]);

  useEffect(() => {
    setSelectedStatus(filterData?.status);
  }, [filterData?.status]);

  useEffect(() => {
    if (!filterData?.status) setSelectedStatus();
  }, [clearFiltersData]);

  useEffect(() => {
    const getData = async () => {
      const type = { type: 'patient' };
      const res = await ApiClient.allStatus(type);
      if (res.status == 200) {
        let statusData = [];
        res.data.map((item) => statusData.push({ id: item.uuid, title: item.title }));
        dispatch(setStatus(statusData));
      }
    };
    try {
      getData();
    } catch (e) {
      console.log(e);
    }
  }, []);

  const renderHeading = () => {
    return <div className='filterHeadings mt-3'>Status</div>;
  };

  const renderChips = () => {
    return (
      <>
        <div className='position-relative'>
          <div className='mt-3' />
          <Select2DropDown
            container={document.getElementById('clinic-dropdown-container')}
            className='zindex-popover'
            placement='bottomStart'
            placeholder='Select Status'
            data={statusList}
            labelStyle={{ fontWeight: 'var(--lightFont)' }}
            onSelect={(data, item) => {
              setSelectedStatus(data);
            }}
            value={selectedStatus || ''}
            msgStyle={{ color: 'red' }}
            style={{ position: 'relative', width: '100%' }}
          />
          <div id='clinic-dropdown-container' style={{ position: 'absolute', bottom: '0px' }}></div>
        </div>
      </>
    );
  };

  return (
    <div>
      {renderHeading()}
      {renderChips()}
    </div>
  );
}

export default StatusFilterView;
