import React, { useEffect, useState } from 'react';
import { ChipsTagsView } from 'whealth-core-web/components';
import { TagsShow, CoreInputBox } from './index';

function CarePathFilter(props) {
  const { filterData, setFilterData } = props;
  const [activeData, setActiveData] = useState([]);

  useEffect(() => {
    let stringActiveData = activeData?.toString();
    let obj = { care_pathway: stringActiveData };
    setFilterData(obj);
  }, [activeData]);

  useEffect(() => {
    setActiveData(new Array());
  }, []);

  useEffect(() => {
    const tempActiveData = filterData?.care_pathway?.split(',');
    setActiveData(tempActiveData || []);
  }, [filterData]);

  const carePathway = [
    { title: 'MEDS', id: 'Meds' },
    { title: 'Tests', id: 'Tests' },
    { title: 'External Consult', id: 'External Consult' },
    { title: 'CRP', id: 'CRP' },
    { title: 'PRC', id: 'PRC' },
  ];

  const renderHeading = () => {
    return <div className='filterHeadings mt-3'>Care PathWay</div>;
  };

  const renderChips = () => {
    return (
      <div>
        <div className='mt-3' />
        <ChipsTagsView setActiveTags={setActiveData} activeTags={activeData} data={carePathway} />
      </div>
    );
  };

  return (
    <div>
      {renderHeading()}
      {renderChips()}
    </div>
  );
}

export default CarePathFilter;
