import React, { useEffect, useState } from 'react';
import { Select2DropDown } from '../whealth-core-web/components/index';

function PatientEngagementFilterView(props) {
  const { filterData, setFilterData, clearFiltersData, title } = props;
  const [selectedEngagementStatus, setSelectedEngagementStatus] = useState();

  useEffect(() => {
    let obj = { conversion_probability: selectedEngagementStatus };
    setFilterData(obj);
  }, [selectedEngagementStatus]);

  useEffect(() => {
    setSelectedEngagementStatus(filterData?.conversion_probability);
  }, [filterData]);

  useEffect(() => {
    if (!filterData?.conversion_probability) setSelectedEngagementStatus();
  }, [clearFiltersData]);

  const renderHeading = () => {
    return <div className='filterHeadings mt-3'>{title}</div>;
  };

  const enagagement_status = [
    { title: 'Hot' },
    { title: 'Cold' },
    { title: 'Warm' },
    { title: 'Dropped-Off' },
    { title: 'Converted' },
  ];

  const renderChips = () => {
    return (
      <div className='position-relative'>
        <div className='mt-3' />
        <Select2DropDown
          container={document.getElementById('clinic-dropdown-container1')}
          placeholder='Select Status'
          data={enagagement_status}
          labelStyle={{ fontWeight: 'var(--lightFont)' }}
          valueKey={'title'}
          onSelect={(data) => {
            setSelectedEngagementStatus(data);
          }}
          value={selectedEngagementStatus}
          msgStyle={{ color: 'red' }}
        />
        <div id='clinic-dropdown-container1' style={{ position: 'absolute', bottom: '0px' }}></div>
      </div>
    );
  };

  return (
    <div>
      {renderHeading()}
      {renderChips()}
    </div>
  );
}

export default PatientEngagementFilterView;
