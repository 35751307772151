import AllRoutes from './routes/Routes';
import Routes from './routes/Routes';
import { store } from './redux/Store';
import { Provider } from 'react-redux';
import React, { useCallback, useEffect, useMemo } from 'react';
import { s3Url } from 'whealth-core-web/components/Helper';
import 'whealth-core-web/assets/style/main.css';
import 'whealth-core-web/assets/style/main-style.scss';
import { BrowserRouter as Router, Route, useLocation } from 'react-router-dom';
import { SideBar } from 'components';
import { FilterProvider } from 'whealth-core-web/components/FilterContext';

const RenderSideBar = () => {
  const { pathname } = useLocation();

  const returnSideBar = useMemo(() => <SideBar />, []);

  const sideBar = () => {
    const hideKeys = [['/article', '/details']];
    const token = localStorage.getItem('token');

    let includedPath = false;
    hideKeys.map((item) => {
      if (typeof item == 'string') {
        if (pathname.includes(item)) {
          includedPath = true;
        }
      } else if (Array.isArray(item)) {
        let newInclude = 0;
        item.map((item) => {
          if (pathname.includes(item)) {
            newInclude += 1;
          }
        });
        if (item.length == newInclude) {
          includedPath = true;
        }
      }
    });

    if (includedPath || !token) {
      return;
    }
    return returnSideBar;
  };

  return sideBar();
};

const App = () => {
  useEffect(() => {
    document.title = process.env.REACT_APP_TITLE || 'W Health Ventures';
    const favicon = document.getElementById('favicon');
    favicon.href = s3Url('icon-fav.svg', '/favicon.ico');
    const appleIcon = document.getElementById('apple-touch-icon');
    appleIcon.href = s3Url('apple-fav-icon.svg', '/logo192.png');
  }, []);

  const ignorePath = ['/', ''];

  if (!localStorage.getItem('token') && !ignorePath.includes(window.location.pathname)) {
    window.location.pathname = '/';
  }
  return (
    <Provider store={store}>
      <Router>
        <FilterProvider isActiveFilter={true}>
          <RenderSideBar />
          <AllRoutes />
        </FilterProvider>
      </Router>
    </Provider>
  );
};

export default App;
