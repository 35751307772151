import { AdminPanelHeader, SideBar } from 'components';
import useApiManager from 'networking/ApiManager';
import React, { useEffect, useMemo } from 'react';
import { useState } from 'react';
import { unstable_HistoryRouter, useLocation, useNavigate, useParams } from 'react-router-dom';
import 'whealth-core-web/components/prescription.scss';
import { useGetLoginUserDataQuery } from 'redux/apiSlice';
import { useGetCockpitDataQuery } from 'redux/cockpitSlice';
import { profileImage } from 'whealth-core-web/components/Helper';
import { Select2DropDown, AddPrescriptionFormDynamic, CommonAlert, Loader } from 'whealth-core-web/components';

function AddPrescription() {
  const location = useLocation();
  const duplicateId = location?.state?.id;
  const navigation = useNavigate();
  const ApiClient = useApiManager();
  const { id, pid } = useParams();
  let userData = useGetLoginUserDataQuery()?.data?.data || {};
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [dynamicPerscriptionData, setDynamicPerscriptionData] = useState('');
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [errors, setErrors] = useState({ prescription_medicines: [] });
  const [alertType, setAlertType] = useState('alert-success');
  const [prescriptionData, setPrescriptionData] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [departmentList, setDepartmentList] = useState([]);
  const [defaultDepartmentData, setDefaultDepartmentData] = useState([]);

  const [mandatoryMedicine, setMandatoryMedicine] = useState(false);
  const [departmentApi, setDepartmentApi] = useState(false);

  const getCockpitData = useGetCockpitDataQuery();

  let configData = getCockpitData?.data?.data?.business_config;

  useEffect(() => {
    configData?.map((item) => {
      if (item.key == 'mandatory_medicine' && item.isActive) setMandatoryMedicine(true);
    });
  }, configData);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message = 'Are you sure you want to leave? All provided data will be lost.';
    e.returnValue = message;
    return message;
  };

  useEffect(() => {
    let isEql = dynamicPerscriptionData?.department?.id == userData?.department?.id;
    if (dynamicPerscriptionData?.department?.id) {
      setSelectedDepartment(dynamicPerscriptionData?.department?.id);
    } else if (userData?.department?.id) {
      setSelectedDepartment(userData?.department?.id);
    }
    if (!pid && duplicateId) {
      if (!isEql && dynamicPerscriptionData.department && userData?.department?.uuid) {
        handleDefaultDepartment();
      }
    } else {
      if (userData?.department?.uuid) {
        handleDefaultDepartment();
      }
    }
  }, [userData, dynamicPerscriptionData?.department?.id]);

  // useEffect(() => {
  //   if (!pid && !duplicateId && userData?.department?.uuid) {
  //     getDepartment();
  //   }
  // }, [userData]);
  console.warn('location?.state?.id', location?.state?.id);
  useEffect(() => {
    getPrescriptionDepartment();
  }, []);

  const handleDefaultDepartment = (department_id) => {
    if (Object.keys(defaultDepartmentData).length) {
      getPrescriptionSchema(department_id, defaultDepartmentData);
    } else if (!location?.state?.id) {
      getDefaultPrescriptionData(department_id);
    }
  };

  const getDefaultPrescriptionData = (department_id) => {
    setIsLoading(true);
    ApiClient.getPublishedPrescriptionData(id)
      .then((res) => {
        setIsLoading(false);
        setDefaultDepartmentData(res.data);
        getPrescriptionSchema(department_id, res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getPrescriptionDepartment = () => {
    ApiClient.prescriptionDepartments(id)
      .then((res) => {
        setDepartmentList(res.data);
      })
      .catch((err) => {});
  };

  // const getDepartment = () => {
  //   ApiClient.searchDepartments()
  //     .then((res) => {
  //       setDepartmentList(res.data);
  //     })
  //     .catch((err) => {});
  // };

  useEffect(() => {
    if (pid) {
      getPrescriptionData();
    } else if (duplicateId) {
      getPrescriptionData(duplicateId);
    }
  }, [pid, duplicateId]);

  const filterDefaultData = (data, key, getIndex) => {
    let newData = {};
    let itemIndex;

    if (data) {
      data?.map((item, index) => {
        if (item.name == key) {
          newData = item;
          itemIndex = index;
        }
      });
    }

    if (getIndex) {
      return itemIndex;
    } else {
      return newData;
    }
  };

  const getPrescriptionSchema = (departmentID, data) => {
    let filterData = filterDefaultData(data.data, 'diagnosis');
    let ans = filterData?.answer;

    let params = { uuid: departmentID || userData?.department?.uuid };
    setIsLoading(true);
    ApiClient.prescriptionSchema(id, pid || duplicateId, params)
      .then((res) => {
        let prescriptionSchemaData = res.data;
        let filterDataIndex = filterDefaultData(prescriptionSchemaData.prescription_schema, 'primary_diagnosis', true);
        if (
          ans &&
          prescriptionSchemaData?.prescription_schema &&
          prescriptionSchemaData.prescription_schema[filterDataIndex] &&
          !prescriptionSchemaData.prescription_schema[filterDataIndex].answer
        ) {
          prescriptionSchemaData.prescription_schema[filterDataIndex].answer = ans;
        }

        if (prescriptionSchemaData?.enable_medicine) {
          if (mandatoryMedicine) {
            prescriptionSchemaData.prescription_medicines = [
              {
                dosage: '000',
                medicine_id: '',
                duration: 0,
                frequency: '',
                note: '',
                medicine: { name: '' },
              },
            ];
          } else {
            prescriptionSchemaData.prescription_medicines = [];
          }
        }
        setDynamicPerscriptionData(prescriptionSchemaData);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getPrescriptionData = (prescription_id = pid) => {
    setIsLoading(true);
    ApiClient.prescriptionData(id, prescription_id)
      .then((res) => {
        setIsLoading(false);
        if (!!Object.entries(res.data?.data).length) {
          let tmpData = res.data;
          tmpData.prescription_schema = res.data.data;
          tmpData.isUpdate = true;
          let checkInDateIndex = tmpData.prescription_schema.findIndex((item2) => item2.name === 'next_followup_date');
          if (checkInDateIndex != -1 && !pid) {
            tmpData.prescription_schema[checkInDateIndex].answer = '';
          }
          setDynamicPerscriptionData(tmpData);
        } else {
          setPrescriptionData(res.data);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const updatePrescription = (data) => {
    setIsLoading(true);
    if (dynamicPerscriptionData?.department?.id) {
      data.department = dynamicPerscriptionData?.department?.id;
    }
    ApiClient.updatePrescription(id, pid, data)
      .then((res) => {
        setIsLoading(false);
        setAlertType('alert-success');
        setIsShowAlert('Prescription updated successfully ');
        setTimeout(() => {
          navigation(`/patient/${id}/dashboard`);
        }, 2500);
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors(err.response.data.errors);
        if (err.response.data?.errors?.non_field_errors || err.response.data?.errors?.details) {
          setAlertType('alert-danger');
          setIsShowAlert(Object.values(err.response.data?.errors?.non_field_errors || err.response.data.errors)[0]);
        }
      });
  };

  const prescriptionCreate = (data) => {
    setIsLoading(true);
    if (selectedDepartment) {
      data.department = selectedDepartment;
      data.doctor_id = dynamicPerscriptionData.doctor_id;
    } else if (dynamicPerscriptionData?.department?.id) {
      data.department = dynamicPerscriptionData.department.id;
    }

    ApiClient.prescriptionCreate(data, id)
      .then((res) => {
        setIsLoading(false);
        setAlertType('alert-success');
        setIsShowAlert('Prescription created successfully ');
        setTimeout(() => {
          navigation(`/patient/${id}/dashboard`);
        }, 2500);
      })
      .catch((err) => {
        console.warn('er', err);

        setIsLoading(false);
        setErrors(err.response.data.errors);
        if (err.response.data?.errors?.non_field_errors || err.response.data?.errors?.details) {
          setAlertType('alert-danger');
          setIsShowAlert(Object.values(err.response.data?.errors?.non_field_errors || err.response.data.errors)[0]);
        }
      });
  };

  // const sideBar = useMemo(() => {
  //   return <SideBar />;
  // }, []);

  let userDetails = localStorage.getItem('userDetails');
  userDetails = JSON.parse(userDetails);

  const showTree = [
    { iconClass: 'person_outline', title: 'Patient', link: '/patient' },
    {
      icon: profileImage(userDetails?.gender, userDetails?.image),
      title: userDetails?.name,
      link: `/patient/${id}/dashboard`,
    },
    { title: (pid ? 'Update' : 'New') + ' Prescription', link: '' },
  ];

  const renderDepartmentDropdown = () => {
    return (
      <div className='daypart-input behaviourpart_box'>
        <Select2DropDown
          value={selectedDepartment}
          data={departmentList}
          labelKey='name'
          label={'Department'}
          placeholder={'Department'}
          valueKey='id'
          getObjData
          retuired
          onSelect={(_, selectedValue) => {
            setSelectedDepartment(selectedValue?.id || '');
            if (selectedValue?.uuid) {
              handleDefaultDepartment(selectedValue?.uuid);
            }
            setShowDialog(true);
          }}
          showMSG={''}
        />
      </div>
    );
  };

  const showPatient = () => {
    const renderPage = () => {
      if (dynamicPerscriptionData) {
        return (
          <AddPrescriptionFormDynamic
            dynamicPerscriptionData={dynamicPerscriptionData}
            setDynamicPerscriptionData={setDynamicPerscriptionData}
            setErrors={setErrors}
            selectedDepartment={selectedDepartment}
            errors={errors}
            onClick={pid ? updatePrescription : prescriptionCreate}
            setAlertType={setAlertType}
            setIsShowAlert={setIsShowAlert}
            isShowAlert={isShowAlert}
            alertType={alertType}
            isNivanCareTeam
          />
        );
      }
    };
    return (
      <>
        <div className='teamHeader stickyHeader'>
          <div className='headerFiles'>
            <AdminPanelHeader iconTitle='person_outline' showTree={showTree} title='Patient' />
          </div>
          <div className='addnewform-part'>{pid ? 'Update' : 'Add New'} Prescription</div>
          <div className='hrLine' />
        </div>
        <div className='px-4 wrapperContent'>
          {isShowAlert && alertType == 'alert-success' && (
            <div className='mt-5'>
              <CommonAlert isShowAlert={isShowAlert} alertType={alertType} setIsShowAlert={setIsShowAlert} />
            </div>
          )}
          {!pid && !duplicateId && departmentList?.length ? (
            <div className='card mt-3'>
              <div className='card-body'>{renderDepartmentDropdown()}</div>
            </div>
          ) : null}
          {selectedDepartment ? renderPage() : null}
        </div>
      </>
    );
  };

  return (
    <div className='wrapper'>
      {showPatient()}
      <Loader show={isLoading} />
    </div>
  );
}

export default AddPrescription;
