import React, { useEffect, useState } from 'react';
import Select2DropDown from '../common/Select2DropDown';
import CommonBorderBox from '../CommonBorderBox';
import CoreButton from '../CoreButton';
import CoreCheckBox from '../CoreCheckBox';
import CoreInputBox from '../CoreInputBox';
import useApiManager from 'networking/ApiManager';

function RegimeTemplateForm(props) {
  const ApiClient = useApiManager();
  const {
    isEditMode,
    setEditMode,
    questionsList,
    getTemplatesList,
    regimeTemplate,
    setRegimeTemplate,
    setAlertType,
    setIsShowAlert,
    setQuestionsList,
    setIsUpdateAlert,
    setIsUpdateAlertType,
    isNivaan,
  } = props;

  const [errors, setErrors] = useState({});
  const [searchQuestionList, setSearchQuestionList] = useState(regimeTemplate.template_behaviour_questions?.map((item) => []));
  const [selectedRadioIndex, setSelectedRadioIndex] = useState(null);
  const handleRadioChange = (index) => {
    setSelectedRadioIndex(index);
    const updatedQuestions = regimeTemplate.template_behaviour_questions.map((item, idx) => ({
      ...item,
      is_default: idx === index,
    }));

    setRegimeTemplate({ ...regimeTemplate, template_behaviour_questions: updatedQuestions });
  };

  const onSearchQuestion = (data, index) => {
    if (data.length > 2) {
      ApiClient.searchBEHAVIOURQUESTION(data).then((res) => {
        searchQuestionList[index] = res.data;
        setSearchQuestionList([...searchQuestionList]);
      });
    } else {
      setSearchQuestionList([]);
    }
  };

  const addQuestion = () => {
    let templateQuestions = [...regimeTemplate.template_behaviour_questions];
    templateQuestions.push({ behaviour_question_id: null, is_default: false });
    setRegimeTemplate({ ...regimeTemplate, template_behaviour_questions: templateQuestions });
  };

  const removeQuestion = (index) => {
    let templateQuestions = [...regimeTemplate.template_behaviour_questions];
    templateQuestions.splice(index, 1);
    setRegimeTemplate({ ...regimeTemplate, template_behaviour_questions: templateQuestions });
    let templateQuestionsError = [...errors.template_behaviour_questions];
    templateQuestionsError.splice(index, 1);
    setErrors({ ...errors, template_behaviour_questions: templateQuestionsError });
  };

  const onsucccess = () => {
    getTemplatesList();
    setRegimeTemplate({
      title: '',
      template_behaviour_questions: [{}],
      default: false,
      editable: true,
    });
    setErrors({});
  };

  const setAlert = (alertMsg, alertType) => {
    setAlertType(alertType);
    setIsShowAlert(alertMsg);
  };

  useEffect(() => {
    let isDefaultIndex = regimeTemplate?.template_behaviour_questions?.findIndex((item) => item.is_default);
    setSelectedRadioIndex(isDefaultIndex);
  }, [regimeTemplate]);

  const createRegimeTemplate = () => {
    // console.log('regimeTemplate', regimeTemplate);
    if (selectedRadioIndex === null) {
      setAlert('One default question is required', 'alert-danger');
      return;
    }
    ApiClient.createRegimeTemplate(regimeTemplate)
      .then((res) => {
        onsucccess();
        setAlert(`${isNivaan ? 'Conversion log Template Added Successfully ' : 'Regime Template Added Successfully'}`, 'alert-success');
      })
      .catch((err) => {
        console.warn('err?.response', err?.response);
        if (err?.response?.data?.errors?.non_field_errors) {
          setAlert(err?.response?.data?.errors?.non_field_errors[0], 'alert-danger');
        } else if (err?.response?.data?.errors) {
          setErrors(err?.response?.data?.errors);
        } else {
          setErrors({});
        }
      });
  };

  const updateRegimeTemplate = () => {
    const modalBody = document.querySelector('.rs-modal-body');
    if (modalBody) {
      modalBody.scrollTop = 0;
    }
    ApiClient.updateRegimeTemplate(regimeTemplate.id, regimeTemplate)
      .then((res) => {
        onsucccess();
        setEditMode(false);
        setAlert(`${isNivaan ? 'Conversion log Template Updated Successfully ' : 'Regime Template Updated Successfully'}`, 'alert-success');
      })
      .catch((err) => {
        console.warn('err?.response', err?.response);
        if (err?.response?.data?.errors?.non_field_errors) {
          setIsUpdateAlert(err?.response?.data?.errors?.non_field_errors[0]);
          setIsUpdateAlertType('alert-danger');
        } else if (err?.response?.data?.errors) {
          setErrors(err?.response?.data?.errors);
        } else {
          setErrors({});
        }
      });
  };

  return (
    <div className='measuringForm'>
      <div className='d-flex w-100 flexmeasuringForm'>
        <div className='w-100'>
          <CoreInputBox
            retuired
            showMSG={errors?.title}
            placeholder={isNivaan ? 'Conversion log Template Name' : 'Regime Template Name'}
            value={regimeTemplate?.title || ''}
            label={isNivaan ? 'Conversion log Template Name' : 'Regime Template Name'}
            setValue={(data) => setRegimeTemplate({ ...regimeTemplate, title: data })}
            msgStyle={{ color: 'red' }}
            maxLength={255}
          />
        </div>
      </div>

      <CommonBorderBox className='w-100 settingFormOutline'>
        <strong className='justify-content-end w-100 d-flex pb-2'>
          {regimeTemplate.template_behaviour_questions.length} {regimeTemplate.template_behaviour_questions.length > 1 ? 'Questions' : 'Question'} Added
        </strong>
        <div className='behaviourpart-bg w-100 mb-2 pb-4' key={'regime-'}>
          {regimeTemplate.template_behaviour_questions.map((item, index) => (
            <div className='d-flex justify-content-between daypart_box pb-0 '>
              <div className='w-10 mt-4 ms-2 d-flex justify-content-center add-question-regime-btn'>
                <div className='d-flex day_part-btn '>
                  <p>Default</p>
                  <div className='radio-btn-container ms-2 pointer'>
                    <input
                      type='radio'
                      name='selectedQuestion'
                      className='pointer'
                      checked={selectedRadioIndex === index}
                      onChange={() => handleRadioChange(index)}
                    />
                  </div>
                </div>
              </div>
              <div className='daypart-input behaviourpart_box w-100 regimetemplate-part'>
                <Select2DropDown
                  data={searchQuestionList[index]?.length ? searchQuestionList[index] : questionsList}
                  value={item?.behaviour_question_id || ''}
                  labelKey={'question'}
                  onSearch={(data) => onSearchQuestion(data, index)}
                  onSelect={(val, dataObj) => {
                    searchQuestionList[index] = [];
                    setSearchQuestionList([...searchQuestionList]);
                    if (val != '' && questionsList.findIndex((dataQ) => dataQ.id == dataObj.id) == -1) {
                      questionsList.push(dataObj);
                      setQuestionsList([...questionsList]);
                    }
                    regimeTemplate.template_behaviour_questions[index].behaviour_question_id = val;
                    regimeTemplate.template_behaviour_questions[index].is_default = regimeTemplate.template_behaviour_questions[index].is_default || false;

                    setRegimeTemplate({ ...regimeTemplate });
                  }}
                  showMSG={
                    errors?.template_behaviour_questions &&
                    errors?.template_behaviour_questions[index] &&
                    errors?.template_behaviour_questions[index]?.behaviour_question_id
                  }
                  retuired
                  label='Select Question'
                  placeholder='Select Question'
                />
              </div>

              <div className='w-10 mt-4 d-flex justify-content-end add-question-regime-btn'>
                <div className=''>
                  <div className='d-flex day_part-btn '>
                    {regimeTemplate.template_behaviour_questions.length > 1 && (
                      <span onClick={() => removeQuestion(index)} class='material-icons pointer'>
                        close
                      </span>
                    )}

                    {regimeTemplate.template_behaviour_questions.length - 1 == index && (
                      <div className='ms-2'>
                        <CoreButton onClick={addQuestion} title='+ Add' />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </CommonBorderBox>

      <div className='mt-4'>
        <div className='d-flex'>
          <CoreCheckBox
            id={isEditMode ? 'defaultTemp' : 'Default'}
            htmlFor={isEditMode ? 'defaultTemp' : 'Default'}
            checked={regimeTemplate.default}
            className={'me-4'}
            title='Make as Default'
            onChange={(val, checked) => {
              setRegimeTemplate({ ...regimeTemplate, default: checked });
            }}
          />
          <CoreCheckBox
            id={isEditMode ? 'editableTemp' : 'editable'}
            htmlFor={isEditMode ? 'editableTemp' : 'editable'}
            checked={!regimeTemplate.editable}
            className={'me-4'}
            title='Disable Editing'
            onChange={(val, checked) => {
              setRegimeTemplate({ ...regimeTemplate, editable: !checked });
            }}
          />
        </div>
      </div>

      <CoreButton onClick={isEditMode ? updateRegimeTemplate : createRegimeTemplate} title={isEditMode ? 'Update' : 'Save'} />
    </div>
  );
}

export default RegimeTemplateForm;
