import React, { useEffect, useState } from 'react';
import { CoreDropDown } from 'whealth-core-web/components';
import { Chart } from 'react-google-charts';
import { dateFormatString, prepareGraphData, subtractMonths } from 'whealth-core-web/components/Helper';
import { useParams } from 'react-router-dom';

function PainScore({ title, graphData, showWeight, setWeight, dateValue, setDateValue, getGraphData, goalData }) {
  const [graphDataArr, setGraphDataArr] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    getGraphData();
  }, [dateValue, goalData, id]);

  useEffect(() => {
    setGraphDataArr(prepareGraphData(graphData?.plot_data?.plot_date));
    setWeight(graphData?.plot_data?.average);
  }, [graphData]);

  const options = {
    legend: 'none',
    pointSize: 8,
    curveType: 'function',
    vAxis: {
      viewWindow: { min: 1 },
      baseline: 1,
      format: '#',
    },
    trendlines: {
      0: {
        type: 'exponential',
        visibleInLegend: true,
        color: 'red',
      },
    },
  };

  const durationData = [
    { title: '1 Year', id: dateFormatString(new Date(subtractMonths(11, new Date())).setDate(1)) },
    { title: '6 Months', id: dateFormatString(new Date(subtractMonths(5, new Date())).setDate(1)) },
    { title: '3 Months', id: dateFormatString(new Date(subtractMonths(2, new Date())).setDate(1)) },
    { title: '1 Month', id: dateFormatString(subtractMonths(1, new Date())) },
  ];

  return (
    <>
      <div className='d-md-flex mb-3'>
        <div className='card w-100 weight-card no-border'>
          <div className='card-body'>
            <div className='d-flex'>
              <div className='flex-grow-1'>
                <h5 className='card-title'> {title || 'Pain Graph'}</h5>
                <h6 className='card-title'>{showWeight ? parseFloat(showWeight) + ' ' : 'N/A'}</h6>
              </div>
              <div className='ms-auto'>
                <CoreDropDown value={dateValue} setValue={setDateValue} data={durationData} />
              </div>
            </div>
            <div className='d-flex'>
              {<Chart chartType='LineChart' data={graphDataArr} options={options} width='100%' />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PainScore;
