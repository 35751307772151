import { AdminPanelHeader, SideBar, DashBoardHeader } from 'components';
import useApiManager from 'networking/ApiManager';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetAdminProfilesCountQuery, useListUserTeamProfileQuery } from 'redux/apiSlice';
import { setAllDepartments, setLocations } from 'redux/Slices';
import { addIconWhite, filters } from 'res/images';
import {
  CommonAlert,
  Pagination,
  PostHeader,
  ShowTeamMembersList,
  Loader,
  EmptyView,
  FilterModal,
  FilterChips,
} from 'whealth-core-web/components';
import SearchBox from 'whealth-core-web/components/common/SearchBox';
import { checkObjectLength, genrageFilterParams, teamRoles } from 'whealth-core-web/components/Helper';
import panelRoles from '../role.json';
function Team() {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const ApiClient = useApiManager();
  const [status, setStatus] = useState({ link: '' });
  const [data, setData] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    const getLocations = async () => {
      const res = await ApiClient.searchLocations();
      if (res.status == 200) {
        let locations = [];
        res.data?.map((item) => locations.push({ id: item.id, title: item.name }));
        dispatch(setLocations(locations));
      }
    };
    getLocations();
  }, []);

  useEffect(() => {
    const getData = async () => {
      const res = await ApiClient.getDepartment();
      if (res.status == 200) {
        let departmentsData = [];
        res.data.map((item) =>
          departmentsData.push({ id: item.id, title: item.name, statuses: item.department_status })
        );
        dispatch(setAllDepartments(departmentsData));
      }
    };
    try {
      getData();
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    if (location?.state?.showAlert) {
      setIsShowAlert(location.state.showAlert);
    }
  }, [location?.state?.showAlert]);

  const createFilterParams = (data, defaultObj) => {
    let ignoreKeys = ['author_data', 'patient_data', 'date', 'tags_data'];
    let filterParams = {};
    if (defaultObj) {
      filterParams = { ...filterParams, ...defaultObj };
    }
    genrageFilterParams(data, filterParams, ignoreKeys);
    return filterParams;
  };

  let params = { params: createFilterParams(filterData, { role: status.link, page: currentPage }) };
  let countParams = { params: createFilterParams(filterData) };
  const alldata = useListUserTeamProfileQuery(params);
  const listCount = useGetAdminProfilesCountQuery(countParams);

  useEffect(() => {
    setIsLoading(listCount.isFetching);
  }, [listCount]);

  useEffect(() => {
    setTotalPages(alldata?.data?.headers['total-pages']);
    setData(alldata?.data?.data);
    setIsLoading(alldata.isFetching);
  }, [alldata]);

  const clearFilters = () => {
    setFilterData(new Object());
  };

  const renderFilterChips = useMemo(() => {
    const ignoreKeys = ['tag_ids', 'tags_data', 'category_ids', 'author_ids'];
    if (checkObjectLength(filterData, ignoreKeys)) {
      return (
        <div className='mt-3'>
          <FilterChips clearData={clearFilters} setData={setFilterData} data={filterData} />
        </div>
      );
    } else {
      if (Object.keys(filterData).length > 0) {
        clearFilters();
      }
      return;
    }
  }, [filterData]);

  const headerData = [
    {
      title: 'Add Team Member',
      icon: addIconWhite,
      status: true,
      onClick: () => navigation('/team/new'),
    },
  ];

  const postLinks = [
    {
      title: `All Team Members (${listCount?.data?.data?.all_team_members || 0})`,
      link: '',
    },
  ];

  Object.keys(panelRoles).map((item) => {
    item != 'doctor_pms' &&
      postLinks.push({
        title: `${teamRoles(item)} (${listCount?.data?.data[item + '_count'] || 0})`,
        link: item,
      });
  });

  const getFilterData = (data) => {
    setFilterData(data);
    setCurrentPage(1);
  };

  const showFilters = () => {
    return <FilterModal getData={getFilterData} data={filterData} filter='team' />;
  };

  const leftSideData = [
    {
      title: 'Apply Filter',
      className: 'themeColor boldFont',
      showBtn: true,
      icon: filters,
    },
  ];

  const renderSearchBox = useMemo(() => {
    return (
      <SearchBox
        placeHolder='Search a team member by name or mobile number'
        searchMethod='SEARCHTEAM'
        label='full_name'
        searchId='Team'
        renderPath='/team/{source_id}/edit'
        imagePath='profile_url'
      />
    );
  }, []);

  const renderDashBoardHeader = useMemo(() => {
    return <DashBoardHeader leftData={leftSideData} data={headerData} />;
  }, []);

  const renderPostHeader = useMemo(
    () => (
      <PostHeader
        postLinks={postLinks}
        onClickHandle={(data) => {
          setCurrentPage(1);
          setStatus(data);
        }}
      />
    ),
    [listCount]
  );

  const showTeam = () => (
    <div className='teamHeader stickyHeader'>
      <div className='headerFiles'>
        <AdminPanelHeader iconTitle='people_alt' title='Team' searchBox={renderSearchBox} />
        {renderDashBoardHeader}
      </div>
      <div className='hrLine' />
      <div className='teamBody'>
        {isShowAlert && (
          <div className='mt-3'>
            <CommonAlert isShowAlert={isShowAlert} setIsShowAlert={setIsShowAlert} />
          </div>
        )}
        {renderPostHeader}
        {renderFilterChips}
      </div>
    </div>
  );

  const renderEmptyView = () => {
    return <EmptyView hide={isLoading} title='No data found' />;
  };

  const showTeamMembers = useMemo(() => <ShowTeamMembersList data={data} />, [data]);

  const teamBody = () => {
    return (
      <>
        {showTeam()}
        <div className='usersList wrapperContent'>
          {data?.length ? showTeamMembers : renderEmptyView()}
          <div className='d-flex justify-content-end mb-4'>
            <Pagination
              data={data}
              length={listCount?.data?.data?.all_team_members}
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className='wrapper'>
      {/* {sideBar} */}
      {teamBody()}
      {showFilters()}
      <Loader show={isLoading} />
    </div>
  );
}

export default Team;
