import AddQuestionForm from 'components/AddQuestionForm';
import AddQuestions from 'components/AddQuestions';
import React, { useState } from 'react';
import { EmptyView } from 'whealth-core-web/components';
import { dateFormat, profileImage, splitArrData, teamRoles, timeFormat } from 'whealth-core-web/components/Helper';

function PatientQuestionsView(props) {
  const { questionData, patientId, getAdminProfiles, getQuestion, isClearVal } = props;
  const [showAll, setShowAll] = useState(false);

  const renderAnswer = (item, key) => {
    if (item.behaviour_question.type == 'multi_select') {
      let ansArr = splitArrData(item[key], '|');
      return (
        <ul>
          {ansArr.map((item, index) => {
            return <li key={index}>{item}</li>;
          })}
        </ul>
      );
    }
    if (item.behaviour_question.type == 'date') {
      const input = item[key];
      let [year, month, day] = typeof input == 'string' ? input.split('-') : '';
      return item[key] ? `${day}/${month}/${year}` : '';
    }
    return item[key];
  };

  const renderQuestionlist = (item, index) => {
    return (
      <div className='border rounded mb-3 p-2 w-100'>
        <div className='d-flex w-100 justify-content-between'>
          <div class='d-flex mt-1 mb-3'>
            <div class='me-2'>
              <img
                className='rounded coachImage float-start img-fluid'
                src={profileImage(item?.admin?.gender, item?.admin?.profile_url)}
              />
            </div>
            <div class=''>
              <h5 class='coachtext capitalize'>{item.admin ? item?.admin?.full_name : 'System'}</h5>
              {dateFormat(item.created_at)} · {timeFormat(item.created_at)}
              <p class='zeefitness capitalize'>{teamRoles(item?.admin?.role)}</p>
            </div>
          </div>
        </div>
        <div>
          <div>
            <b className='capitalize'> {item.title} </b>
          </div>
          <div>
            <b>{item?.behaviour_question_category?.title ? 'Category :' : ''}</b>
            &nbsp;
            {item?.behaviour_question_category?.title}
          </div>
          <div className='table-responsive'>
            <table className='w-100 table '>
              <thead>
                <tr>
                  <th>Sr.No.</th>
                  <th>Questions</th>
                  <th>Answers</th>
                  {/* <th>Recommendations</th> */}
                  {/* <th>Attached Goal</th> */}
                </tr>
              </thead>
              <tbody>
                {item.behaviour_questionnaire_answers.map((item2, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item2.behaviour_question.question}</td>
                      <td>{renderAnswer(item2, 'answer')}</td>
                      {/* <td>{renderAnswer(item, 'recommendation')}</td> */}
                      {/* <td>{item?.patient_goal?.goal?.title}</td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      {questionData.length ? (
        <div>
          {questionData?.slice(0, showAll ? questionData?.length : 1).map((item, index) => {
            return renderQuestionlist(item, index);
          })}
          {questionData?.length > 2 && (
            <a
              style={{ cursor: 'pointer' }}
              className='d-flex justify-content-end align-items-center'
              onClick={() => setShowAll(!showAll)}
            >
              <span class='material-icons-outlined'>{showAll ? 'expand_less' : 'expand_more'}</span>{' '}
              {showAll ? 'View Less' : 'View More'}
            </a>
          )}
        </div>
      ) : (
        <EmptyView title='No conversion log found' />
      )}
      <div className='w-100'>
        <AddQuestions
          isClearVal={isClearVal}
          getQuestion={getQuestion}
          patientConversion
          patientConversionId={patientId}
          getAdminProfiles={getAdminProfiles}
        />
      </div>
    </>
  );
}

export default PatientQuestionsView;
