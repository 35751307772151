import useApiManager from 'networking/ApiManager';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { add, upload, checkmarkSm, starSmall, filterIcon, adherenceGreen } from 'res/images';
import { dateFormat, timeFormat, dayTime, iconImages, titlizeString, getReadTime, exportToCsv, checkObjectLength } from './Helper';
import Loader from './Loader';
import { BorderBtnWithBg, EmptyView, FilterChips, Pagination, PostBody } from 'whealth-core-web/components';
import { useDispatch, useSelector } from 'react-redux';
import { AddDepartmentModal } from 'components/AddDepartmentModal';
import { Button, Table } from 'rsuite';
import { addDepartment, setAlerts, setPatientDetails } from 'redux/Slices';
import { useGetCockpitDataQuery } from 'redux/cockpitSlice';
import { EditDepartmentModal } from 'components/EditDepartmentModal';
import CommonToolTip from './CommonToolTip';
import { toast } from 'react-toastify';
import labels from '../../inputFields.json';
import AddTimelineTab from './AddTimelineTab';
import { useFilter } from './FilterContext';
import FilterModal from 'components/FilterModal';
const { Column, HeaderCell, Cell } = Table;

function RenderTimelineTab(props) {
  const ApiClient = useApiManager();
  const { setStatus, newGoal, newPrescription, newConsultation, uploadReport, getGraphData, addSservice, getPatientData } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dateData, setDateData] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [openDeptModal, setOpenDeptModal] = useState(false);
  const [openEditDeptModal, setOpenEditDeptModal] = useState(false);
  const departmentsFromRedux = useSelector((state) => state?.sliceReducer?.patientDetails?.departments);
  const selectedDepartments = useSelector((state) => state?.sliceReducer?.patientDetails?.selected_departments);
  const [deptTableData, setDeptTableData] = useState([]);
  const [selectedDept, setSelectedDept] = useState();
  const [isNivanCare, setIsNivanCare] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  let modes = labels?.consultantMode?.options || [];

  const textStyle = {
    color: '#656364',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '14px', // 116.667%
  };

  const textHeaderStyle = {
    color: '#656364',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal', // 116.667%
  };

  /////////////////////////
  const { filterData, setFilterData } = useFilter();
  const [clearFiltersData, setClearFilterData] = useState(false);
  const [filterBtnUi, setFilterBtnUi] = useState(false);
  ////////////////////

  function formattedDate(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return null;
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Intl.DateTimeFormat('en-GB', options).format(date);
  }
  const addDept = () => {
    getPatientData();
  };

  const getCockpitData = useGetCockpitDataQuery();
  let configData = getCockpitData?.data?.data?.business_config;

  useEffect(() => {
    if (configData) {
      configData.map((item) => {
        if (item.key == 'departments_clinics' && item.isActive) {
          setIsNivanCare(item.isActive);
        }
      });
    }
  }, [configData]);

  const { updatePatinetTab } = useSelector((state) => state.sliceReducer);

  useEffect(() => {
    setDeptTableData(departmentsFromRedux);
  }, departmentsFromRedux);

  useEffect(() => {
    getTimeline();
  }, [id, currentPage, updatePatinetTab?.update]);

  const getTimeline = () => {
    let params = {
      page: currentPage,
    };
    setIsLoading(false);
    // ApiClient.patientProfileTimeline(id, params)
    //   .then((res) => {
    //     setData(res.data);
    //     setTotalPages(res.headers['total-pages']);
    //     setIsLoading(false);
    //   })
    //   .catch((err) => {
    //     setIsLoading(false);
    //     console.log(err);
    //   });
    ApiClient.patientProfileAuditLog(id, params)
      .then((res) => {
        setData(res.data);
        setTotalPages(res.headers['total-pages']);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  function convertUSTtoIST(ustDate) {
    // Create a new Date object from the UST date string
    let date = new Date(ustDate);

    // Convert UST to IST by adding 5 hours and 30 minutes
    date.setHours(date.getHours() + 5);
    date.setMinutes(date.getMinutes() + 30);

    // Define arrays for month and day names
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    // Get individual date and time components
    let day = date.getDate();
    let month = monthNames[date.getMonth()];
    let year = date.getFullYear();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert hours from 24-hour to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Pad minutes with leading zero if needed
    minutes = minutes < 10 ? '0' + minutes : minutes;

    // Format the date and time
    let formattedDate = `${day} ${month} ${year}, ${hours}:${minutes} ${ampm}`;

    return formattedDate;
  }
  const handleExportChat = (chatID) => {
    setIsLoading(true);
    ApiClient.exportAIChat(id, chatID)
      .then((res) => {
        setIsLoading(false);
        exportToCsv(res.data, `AI_Chat_${dateFormat(new Date())}-${timeFormat(new Date())}.csv`);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const renderActivity = (date) => {
    if (date) {
      return dateFormat(date) + ' · ' + timeFormat(date);
    } else {
      return 'No Activity';
    }
  };

  const getItemVal = (value, unit) => {
    if (unit == 'mood' || unit == 'energy') {
      return (
        <div className='show-graph-emoji ms-1'>
          {' '}
          <img src={iconImages()[unit][value]} />{' '}
        </div>
      );
    } else {
      return value;
    }
  };

  const InnerWraper = (props) => {
    const { children, date } = props;
    return (
      <div>
        <div
          className='d-flex justify-content-between gap-10'
          style={{
            margin: '8px',
          }}
        >
          <div className='d-flex flex-column w-100'>{children}</div>
          {/* {date && <div className='date no-wrap'>{date}</div>} */}
        </div>
      </div>
    );
  };

  const renderTags = (arr) => {
    return arr?.map((item, index) => {
      if (item?.condition) {
        return (
          <div key={index} className={`badge badge-pill milestoneAchived capitalize text-center ${!item.icon && 'pl-10'}`}>
            {item.icon && <img src={item.icon} className='icon' />}
            {item.title}
          </div>
        );
      } else {
        return null;
      }
    });
  };

  const renderMedicineGoal = (data, item, title) => {
    return (
      <PostBody title={title} isEdit={data} handleChange={getTimeline}>
        <InnerWraper>
          <div className='d-flex justify-content-between'>
            <div className='cardHeading capitalize'>
              <b>{item?.goal?.title}</b>
              <div>{item?.medicine?.medicine_category}</div>
            </div>
            <div className='date'>{renderActivity(data?.record_date)}</div>
          </div>

          <div className='tagsHolder mt-2'>
            {showDosageTimes(dayTime(data?.value, checkmarkSm))}
            {data?.channel && <div className='badge badge-pill me-3 milestoneAchived channel capitalize'>{data?.channel}</div>}
          </div>

          <div className='progressBar mt-2  d-flex align-items-center'>
            <div className='progress w-100 flex-fill'>
              <div
                className='progress-bar'
                role='progressbar'
                aria-valuenow='0'
                style={{
                  width: (getArrData(data?.value) / getArrData(data?.target_value)) * 100 + '%',
                }}
                aria-valuemin='0'
                aria-valuemax={item?.value}
              ></div>
            </div>
            <div className='progress-value '>
              {getArrData(data?.value)} / {getArrData(data?.target_value)} Dose
            </div>
          </div>
        </InnerWraper>
      </PostBody>
    );
  };

  const renderGoalData = (data, item, title) => {
    return (
      <PostBody
        dateVal={formattedDate(data?.timestamp) + ', ' + timeFormat(data?.timestamp)}
        getGraphData={getGraphData}
        getPatientData={getPatientData}
        title={title}
        isEdit={data}
        handleChange={getTimeline}
      >
        <InnerWraper>
          <div className='capitalize' style={textStyle}>
            by {data?.changed_by?.full_name}, {data?.changed_by?.department_name}
          </div>
          {data?.action === 'create' ? (
            <div className='capitalize' style={textStyle}>
              <strong style={textHeaderStyle}> {data?.action + 'd'} </strong> |{' '}
              {(data?.changes?.patient_goal?.length || data?.goal_name) && (
                <>
                  {data?.goal_name} | {data?.measuring_unit !== 'Yes/No' ? data?.changes?.value?.[1] : data?.changes?.value?.[1] === '0' ? 'No' : 'Yes'}
                  {data?.changes?.value2?.length && <>/{data?.changes?.value2?.[1]}</>} {data?.measuring_unit !== 'Yes/No' && data?.measuring_unit}
                  <div>
                    <strong style={textHeaderStyle}>Record Date:</strong>
                    {formattedDate(data?.record_date) + ', ' + timeFormat(data?.record_date)}
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className='capitalize' style={textStyle}>
              <strong style={textHeaderStyle}> {data?.action + 'd'} </strong> |{' '}
              {(data?.changes?.patient_goal?.length || data?.goal_name) && (
                <>
                  {data?.goal_name} | {data?.measuring_unit !== 'Yes/No' ? data?.changes?.value?.[0] : data?.changes?.value?.[0] === '0' ? 'No' : 'Yes'} →{' '}
                  {data?.measuring_unit !== 'Yes/No' ? data?.changes?.value?.[1] : data?.changes?.value?.[1] === '0' ? 'No' : 'Yes'}
                  {data?.changes?.value2?.length && <>/{data?.changes?.value2?.[1]}</>} {data?.measuring_unit !== 'Yes/No' && data?.measuring_unit}
                  <div>
                    <strong style={textHeaderStyle}>Record Date:</strong>
                    {formattedDate(data?.record_date) + ', ' + timeFormat(data?.record_date)}
                  </div>
                </>
              )}
            </div>
          )}
        </InnerWraper>
      </PostBody>
    );
  };
  const renderServiceData = (data, item, title) => {
    return (
      <PostBody
        dateVal={formattedDate(data?.timestamp) + ', ' + timeFormat(data?.timestamp)}
        getGraphData={getGraphData}
        getPatientData={getPatientData}
        title={title}
        isEdit={data}
        handleChange={getTimeline}
      >
        <InnerWraper>
          <div className='capitalize' style={textStyle}>
            by {data?.changed_by?.full_name}, {data?.changed_by?.department_name}
          </div>
          <div className='capitalize' style={textStyle}>
            <strong style={textHeaderStyle}> {data?.action + 'd'} </strong>
            <br />
            {data?.action === 'create' ? (
              <>
                {data?.changes && (
                  <DisplayObject
                    isUpdate={false}
                    obj={data.changes}
                    includeKeys={{
                      service: 'Service',
                      status: 'Status',
                      estimated_cost: 'Service Estimate Cost',
                      final_bill_amount: 'Service Final Cost',
                    }}
                  />
                )}
              </>
            ) : (
              <>
                {data?.changes && (
                  <DisplayObject
                    isUpdate={true}
                    obj={data.changes}
                    includeKeys={{
                      service: 'Service',
                      status: 'Status',
                      estimated_cost: 'Service Estimate Cost',
                      final_bill_amount: 'Service Final Cost',
                    }}
                  />
                )}
              </>
            )}
          </div>
        </InnerWraper>
      </PostBody>
    );
  };

  const DisplayObject = ({ isUpdate, obj, includeKeys = {} }) => {
    const traverseObject = (obj) => {
      if (Object.keys(includeKeys).length === 0) {
        return null; // Display nothing if includeKeys is empty
      }

      return Object.entries(obj)
        .filter(([key]) => includeKeys.hasOwnProperty(key))
        .map(([key, value], index) => (
          <div key={index}>
            {isUpdate ? (
              <>
                <strong>{includeKeys[key]}: </strong>
                {value?.[0]} → {value?.[1]}
              </>
            ) : (
              <>
                <strong>{includeKeys[key]}: </strong> {value?.[1]}
              </>
            )}
          </div>
        ));
    };

    return <div>{traverseObject(obj)}</div>;
  };
  const renderNotesData = (data, item, title) => {
    return (
      <PostBody
        dateVal={formattedDate(data?.timestamp) + ', ' + timeFormat(data?.timestamp)}
        getGraphData={getGraphData}
        getPatientData={getPatientData}
        title={title}
        isEdit={data}
        handleChange={getTimeline}
      >
        <InnerWraper>
          <div className='capitalize' style={textStyle}>
            by {data?.changed_by?.full_name}, {data?.changed_by?.department_name}
          </div>
          <div className='capitalize' style={textStyle}>
            <strong style={textHeaderStyle}> {data?.action + 'd'} </strong> | Content | {data?.changes?.content?.[1]}
          </div>
        </InnerWraper>
      </PostBody>
    );
  };

  const renderAppointmentsData = (data, item, title) => {
    return (
      <PostBody
        dateVal={formattedDate(data?.timestamp) + ', ' + timeFormat(data?.timestamp)}
        getGraphData={getGraphData}
        getPatientData={getPatientData}
        title={title}
        isEdit={data}
        handleChange={getTimeline}
      >
        <InnerWraper>
          <div className='capitalize' style={textStyle}>
            by {data?.changed_by?.full_name}, {data?.changed_by?.department_name}
          </div>
          <div className='capitalize' style={textStyle}>
            {data?.changes?.is_closed?.[1] === 'True' ? (
              <strong style={textHeaderStyle}> {'Cancelled'} </strong>
            ) : (
              <strong style={textHeaderStyle}> {data?.action + 'd'} </strong>
            )}
            {data?.changes && (
              <>
                <DisplayObject
                  isUpdate={data?.action != 'create'}
                  obj={data.changes}
                  includeKeys={{
                    consultant: 'Doctor',
                    location: 'Hospital',
                    patient_attendance_status: 'Patient Attendance Status',
                  }}
                />
                {(data?.changes?.consult_datetime || data?.changes?.consult_end_datetime) && (
                  <>
                    {' '}
                    {data?.action === 'create' ? (
                      <>
                        {data?.changes?.consult_datetime && (
                          <div style={textStyle}>
                            <strong>Appointment Start Date & Time: </strong> {convertUSTtoIST(data?.changes?.consult_datetime?.[1])}
                          </div>
                        )}
                        {data?.changes?.consult_end_datetime && (
                          <div style={textStyle}>
                            <strong>Appointment End Date & Time: </strong> {convertUSTtoIST(data?.changes?.consult_end_datetime?.[1])}
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {data?.changes?.consult_datetime && (
                          <div style={textStyle}>
                            <strong>Appointment Start Date & Time: </strong>
                            {data?.changes?.consult_datetime?.[0] === 'None' ? 'None ' : convertUSTtoIST(data?.changes?.consult_datetime?.[0])} →{' '}
                            {convertUSTtoIST(data?.changes?.consult_datetime?.[1])}
                          </div>
                        )}
                        {data?.changes?.consult_end_datetime && (
                          <div style={textStyle}>
                            <strong>Appointment End Date & Time: </strong>
                            {data?.changes?.consult_end_datetime?.[0] === 'None' ? 'None ' : convertUSTtoIST(data?.changes?.consult_end_datetime?.[0])}→{' '}
                            {convertUSTtoIST(data?.changes?.consult_end_datetime?.[1])}
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </InnerWraper>
      </PostBody>
    );
  };

  const renderConversionLogData = (data, item, title) => {
    return (
      <PostBody
        dateVal={formattedDate(data?.timestamp) + ', ' + timeFormat(data?.timestamp)}
        getGraphData={getGraphData}
        getPatientData={getPatientData}
        title={title}
        isEdit={data}
        handleChange={getTimeline}
      >
        <InnerWraper>
          <div className='capitalize' style={textStyle}>
            by {data?.changed_by?.full_name}, {data?.changed_by?.department_name}
          </div>
          <div className='capitalize' style={textStyle}>
            {data?.action === 'create' ? (
              <div className='capitalize'>
                <strong style={textHeaderStyle}> {data?.action + 'd'} </strong> |{' '}
                {(data?.changes?.next_followup_date?.length || data?.changes?.followup_date?.length) && (
                  <>
                    {'Next Follow Up Date'} |{' '}
                    {data?.changes?.next_followup_date?.length
                      ? convertUSTtoIST(data?.changes?.next_followup_date?.[1])
                      : convertUSTtoIST(data?.changes?.followup_date?.[1])}{' '}
                  </>
                )}
              </div>
            ) : (
              <div className='capitalize' style={textStyle}>
                <strong style={textHeaderStyle}> {data?.action + 'd'} </strong> |{' '}
                {data?.changes?.next_followup_date?.length && (
                  <>
                    {'Next Follow Up Date'} |{' '}
                    {data?.changes?.next_followup_date?.[0] === 'None' ? 'None ' : convertUSTtoIST(data?.changes?.next_followup_date?.[0])} →{' '}
                    {convertUSTtoIST(data?.changes?.next_followup_date?.[1])}{' '}
                  </>
                )}
              </div>
            )}
          </div>
        </InnerWraper>
      </PostBody>
    );
  };

  const renderProfileFieldsData = (data, item, title) => {
    return (
      <PostBody
        dateVal={formattedDate(data?.timestamp) + ', ' + timeFormat(data?.timestamp)}
        getGraphData={getGraphData}
        getPatientData={getPatientData}
        title={title}
        isEdit={data}
        handleChange={getTimeline}
      >
        <InnerWraper>
          <div className='capitalize' style={textStyle}>
            by {data?.changed_by?.full_name}, {data?.changed_by?.department_name}
          </div>
          <div className='capitalize' style={textStyle}>
            {
              <div className='capitalize'>
                <strong style={textHeaderStyle}> {data?.action + 'd'} </strong> |{' '}
                {data?.changes?.answer?.length && (
                  <>
                    {data?.profile_answer_key
                      ?.split('_')
                      ?.map((word) => word?.charAt(0)?.toUpperCase() + word.slice(1))
                      ?.join(' ')}{' '}
                    | {data?.changes?.answer?.[0]?.split('|')?.join(', ')} → {data?.changes?.answer?.[1]?.split('|')?.join(', ')}{' '}
                  </>
                )}
              </div>
            }
          </div>
        </InnerWraper>
      </PostBody>
    );
  };
  const showDosageTimes = (arr, icon) => {
    return arr.map((item, index) => {
      const objectKeys = Object.keys(item);
      return (
        <div key={index} className={`badge badge-pill me-3 ${item[objectKeys[1]]}`}>
          <img src={item[objectKeys[0]]} className='icon' /> {objectKeys[0]}
        </div>
      );
    });
  };

  const getArrData = (str) => {
    let val = 0;
    if (str) {
      let arr = str?.split('');
      if (Array.isArray(arr)) {
        arr.map((item) => {
          val += +item;
        });
      }
    }
    return val;
  };

  const renderData = (key, data, item) => {
    // let objData = {
    //   user_subscription: { func: renderUserSubscription, title: 'Subscription' },
    //   // prescription_medicine: { func: renderPrescriptionData, title: "Medical Prescription" },
    //   patient_goal: { func: renderGoalData, title: 'Goal' },
    //   article_read_activity: { func: renderArticleReadTime, title: 'Article Read Activity' },
    //   type: { func: renderTypeData, title: '' },
    // };

    // if (!item) {
    //   return;
    // }

    if (data?.content_type_name === 'patient service') {
      return renderServiceData(data, item, 'Service');
    }
    if (data?.content_type_name === 'patient note') {
      return renderNotesData(data, item, 'Note');
    }
    if (data?.content_type_name === 'user profile') {
      // signup
      return renderSignupData(data, item, 'SignUp');
    }
    if (data?.content_type_name === 'Patient Activity Log') {
      return renderGoalData(data, item, 'Goal');
    }
    if (data?.content_type_name === 'behaviour questionnaire') {
      // conversion log
      return renderConversionLogData(data, item, 'Conversion Log');
    }
    if (data?.content_type_name === 'profile answer') {
      // profileField
      return renderProfileFieldsData(data, item, 'Profile Field');
    }
    if (data?.content_type_name === 'consultation') {
      // appointment
      return renderAppointmentsData(data, item, 'Appointment');
    }
    // if (key in objData) {
    //   return objData[key].func(data, item, objData[key].title);
    // } else {
    //   return;
    // }
  };

  const renderTypeData = (item, key) => {
    let objData = {
      UserSignUp: { func: renderSignupData, title: 'SignUp' },
      AiChat: { func: renderAiChat, title: 'AiChat' },
      AppReferral: { func: renderAppReferral, title: 'AppReferral' },
    };

    if (!item) {
      return;
    }

    if (key in objData) {
      return objData[key].func(item, objData[key].title);
    } else {
      return;
    }
  };

  const renderAiChat = (item, title) => {
    return (
      <PostBody title={title} AiChat={item?.ai_chat} handleExportChat={handleExportChat}>
        <InnerWraper date={`${dateFormat(item.record_date)} · ${timeFormat(item.record_date)}`}>
          <div className='cardHeading mb-2'>
            <b>Summary&nbsp;</b>
          </div>
        </InnerWraper>
        <div className='max-content'>
          <div className='d-flex  capitalize renewalDate badge-pill '>{item?.ai_chat?.summary}</div>
        </div>
      </PostBody>
    );
  };
  const renderAppReferral = (item, title) => {
    return (
      <PostBody title={title}>
        <InnerWraper date={`${dateFormat(item.record_date)} · ${timeFormat(item.record_date)}`}>
          <div className='max-content'>
            <div className='d-flex  capitalize renewalDate badge-pill '>{item?.app_referral?.summary}</div>
          </div>
        </InnerWraper>
      </PostBody>
    );
  };

  const renderSignupData = (data, item, title) => {
    return (
      <PostBody
        dateVal={formattedDate(data?.timestamp) + ', ' + timeFormat(data?.timestamp)}
        title={title}
        getGraphData={getGraphData}
        getPatientData={getPatientData}
        isEdit={data}
        handleChange={getTimeline}
      >
        <InnerWraper>
          <div className='capitalize' style={textStyle}>
            by {data?.changed_by?.full_name}, {data?.changed_by?.department_name}
          </div>
        </InnerWraper>
      </PostBody>
    );
  };

  const renderUserSubscription = (data, item, title) => {
    return (
      <PostBody title={title}>
        <InnerWraper date={`${dateFormat(item.created_at)} · ${timeFormat(item.created_at)}`}>
          <div className='cardHeading mb-2'>
            <b>Renewal on &nbsp;</b>
          </div>
          <div className='max-content'>
            <div className='d-flex badge capitalize renewalDate badge-pill '>{dateFormat(item.end_date)}</div>
          </div>
        </InnerWraper>
      </PostBody>
    );
  };

  const renderArticleReadTime = (data, item, title) => {
    return (
      <PostBody title={title}>
        <InnerWraper date={`${dateFormat(item.start_time)} · ${timeFormat(item.start_time)}`}>
          <div className='cardHeading mb-2 capitalie'>
            <b>{item?.article?.title}&nbsp;</b>
          </div>
          <div className='max-content'>
            <div className='d-flex badge capitalize renewalDate badge-pill'>
              <div> {getReadTime(item.duration)}</div>
            </div>
          </div>
        </InnerWraper>
      </PostBody>
    );
  };

  const handleAddDepartmentClick = () => {
    //open Add dept modal
    setOpenDeptModal(true);
  };

  const handleEditDepartmentClick = () => {
    //open Edit dept modal
    setOpenEditDeptModal(true);
  };

  const handleDeptModalClose = () => {
    setOpenDeptModal(false);
  };

  const handleEditDeptModalClose = () => {
    setSelectedDept();
    setOpenEditDeptModal(false);
  };

  const handleEditClick = (data) => {
    setSelectedDept(data);
    handleEditDepartmentClick();
  };

  const handleDeleteDepartment = (data) => {
    let windoConfirm = window.confirm('Are you sure you want to delete this department?');
    if (windoConfirm) {
      const formData = new FormData();
      formData.append('selected_department_id', data?.id);
      ApiClient.deleteDepartment(id, formData)
        .then((res) => {
          getPatientData();
          dispatch(setAlerts({ patient: { title: 'Department deleted successfully.', type: 'alert-success' } }));
        })
        .catch((e) => {
          toast.error(e.response.data.error);
        });
    }
  };

  const clearFilters = () => {
    setFilterData(new Object());
    setClearFilterData(!clearFiltersData);
  };

  const renderFilterChips = useMemo(() => {
    const ignoreKeys = ['tag_ids', 'tags_data', 'category_ids', 'author_ids', 'active_patients'];
    if (checkObjectLength(filterData, ignoreKeys)) {
      setFilterBtnUi(true);
      return (
        <div
          // className='mt-3'
          style={{
            marginRight: '17px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <FilterChips clearData={clearFilters} setData={setFilterData} data={filterData} />
        </div>
      );
    } else {
      setFilterBtnUi(false);
      if (Object.keys(filterData).length > 0 && !filterData.today_date && !filterData.active_patients) {
        clearFilters();
      }
      return;
    }
  }, [filterData]);
  const getFilterData = (data) => {
    setFilterData(data);
    setCurrentPage(1);
  };
  const showFilters = useCallback(() => {
    return (
      <FilterModal filter={'timeline'} getData={getFilterData} data={filterData} clearFiltersData={clearFiltersData} setClearFilterData={setClearFilterData} />
    );
  }, [filterData, clearFiltersData]);
  const groupByCreatedAt = (data) => {
    return data.reduce((acc, item) => {
      console.log('items for date', item);
      const date = new Date(item.timestamp).toDateString(); // Normalize the date format
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date();
    yesterday?.setDate(today?.getDate() - 1);

    const day = date?.getDate()?.toString()?.padStart(2, '0');
    const month = date?.toLocaleString('default', { month: 'short' });
    const year = date?.getFullYear();

    const formattedDate = `${day} ${month} ${year}`;

    if (date?.toDateString() === today?.toDateString()) {
      return 'Today';
    } else if (date?.toDateString() === yesterday?.toDateString()) {
      return 'Yesterday';
    } else {
      return formattedDate;
    }
  };
  const groupedData = groupByCreatedAt(data);
  return (
    <>
      <AddTimelineTab
        setStatus={setStatus}
        newGoal={newGoal}
        uploadReport={uploadReport}
        addSservice={addSservice}
        newPrescription={newPrescription}
        newConsultation={newConsultation}
        handleAddDepartmentClick={handleAddDepartmentClick}
      />
      <AddDepartmentModal open={openDeptModal} handleClose={handleDeptModalClose} addDepartment={addDept} />
      <EditDepartmentModal open={openEditDeptModal} handleClose={handleEditDeptModalClose} addDepartment={addDept} data={selectedDept} />

      <Loader show={isLoading} />
      {isNivanCare && (
        <div className='table-responsive mt-4'>
          <table className='table table-bordered'>
            <thead>
              <tr>
                <th className='text-center'>Department</th>
                <th className='text-center'>Doctor</th>
                <th className='text-center'>Status</th>
                <th className='text-center'>{labels?.consultantMode?.label || 'Consultant Mode'}</th>
                <th className='text-center'>Action</th>
              </tr>
            </thead>
            <tbody>
              {selectedDepartments?.length > 0 ? (
                selectedDepartments.map((item, index) => {
                  return (
                    <tr>
                      <td>{item.department.name}</td>
                      <td>{item.admin.full_name}</td>
                      <td>{item.status.title}</td>
                      <td>{modes.find(({ id }) => id == item['consultation_mode'])?.title}</td>
                      <td>
                        <div className='d-flex'>
                          <CommonToolTip
                            placement={'top'}
                            item={
                              <Button className='p-0 ms-2' appearance='link' onClick={() => handleEditClick(item)}>
                                <span class='material-icons-outlined'>edit</span>
                              </Button>
                            }
                            showValue={'Edit'}
                          />
                          <CommonToolTip
                            placement={'top'}
                            item={
                              <Button color='red' className='p-0 ms-2' appearance='link' onClick={() => handleDeleteDepartment(item)}>
                                <span class='material-icons-outlined'>delete</span>
                              </Button>
                            }
                            showValue={'Delete'}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={5}>
                    <EmptyView />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
      {/* <div
        className='d-flex'
        style={{
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      > */}
      {/* <div
          style={{
            backgroundColor: '#F8FAFF',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {renderFilterChips}

          <button
            // className=''
            style={{
              marginRight: 0,
              backgroundColor: filterBtnUi ? '#D1DCFF' : 'white',
            }}
            data-bs-toggle='offcanvas'
            data-bs-target='#offcanvasRight'
            aria-controls='offcanvasRight'
            type='button'
            className={`btn ${filterBtnUi ? '' : 'btn-outline-primary'}`}
          >
            <img src={filterIcon} className='btnImg' /> Filter
          </button>
        </div> */}
      {/* <div className='me-2'>
          <BorderBtnWithBg
            // onClick={item.onClick}
            className={'themeColor boldFont'}
            // key={index}
            iconImg={'/static/media/filters.e1ddd89e2547a6d97ac8875cfa0a2a2d.svg'}
            title={'Apply Filter'}
          />
        </div> */}
      {/* </div> */}

      <div className='d-flex'>
        <div className='pt-4 w-100'>
          <div className='cardMilestone'>
            {/* {console.log('data which is shown', data)}
            {data &&
              data.map((item, index) => {
                if (index == 0) {
                  let goalDate = item?.patient_goal?.updated_at;

                  if (dateData) {
                    if (dateData < goalDate) {
                      setDateData(goalDate);
                    }
                  }
                }

                return Object.keys(item).map((keys, index) => {
                  return <div key={index}>{renderData(keys, item, item[keys])}</div>;
                });
              })} */}
            <div>
              {Object.keys(groupedData).map((date, index) => (
                <div key={index}>
                  {console.log('date, index', date, index)}
                  <h3
                    style={{
                      color: '#243B86',
                      // textAlign: 'center',
                      fontFamily: 'Inter',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      lineHeight: '15px', // 125%
                      margin: '10px 0',
                    }}
                  >
                    {formatDate(date)}
                  </h3>
                  {groupedData[date].map((item, idx) => (
                    <div
                      style={{
                        borderLeft: '1px dashed #D1DCFF',
                        marginLeft: '10px',
                      }}
                      key={idx}
                    >
                      {renderData(data, item, idx)}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {showFilters()}
      <div className='d-flex justify-content-end'>
        <div className='patientPagination mt-3'>
          <Pagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </div>
      </div>
    </>
  );
}

export default RenderTimelineTab;
