import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CommonAlert, CoreTextArea, Loader, Pagination, Select2DropDown } from 'whealth-core-web/components';
import { setReduxNotesData } from 'redux/Slices';
import { useDispatch } from 'react-redux';
import { dateFormat, timeFormat } from 'whealth-core-web/components/Helper';

function PatientNotesView(props) {
  // const [content, setContent] = useState('');
  // const [titleReal, setTitleReal] = useState('');
  const [showAll, setShowAll] = useState(false);
  const [errors, setErrors] = useState(false);
  const [alertType, setAlertType] = useState('alert-success');
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [isShowUpdateAlert, setIsShowUpdateAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [categories, setCategories] = useState([]);
  const [categoriesTemplate, setCategoriesTemplate] = useState([]);
  const [contentId, setContentId] = useState('');
  // const [noteCategory, setNoteCategory] = useState('');
  const [selectedCatObj, setSelectedCatObj] = useState({});
  const ApiClient = useApiManager();
  const { patientId, content, setContent, titleReal, setTitleReal, noteCategory, setNoteCategory } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    getNotes();
    setContent('');
    setTitleReal('');
    setNoteCategory('');
  }, [patientId, currentPage]);

  useEffect(() => {
    if (content?.length > 0) {
      setErrors(false);
    }
  }, [content]);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = () => {
    setIsLoading(true);
    ApiClient.notesCategoryGet(1)
      .then((res) => {
        setIsLoading(false);
        setCategories(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getCategoriesByTemplate = (val) => {
    if (Object.keys(val).length === 0) {
      setCategoriesTemplate([]);
      setContent('');
    } else {
      setIsLoading(true);
      ApiClient.notesCategoryGetByTemplate(val.id)
        .then((res) => {
          setIsLoading(false);
          setCategoriesTemplate(res.data);
          setTitleReal('');
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  const getNotes = () => {
    if (!patientId) return;
    setIsLoading(true);
    let params = { page: currentPage };
    ApiClient.getNotes(patientId, params)
      .then((res) => {
        setIsLoading(false);
        setTotalPages(res.headers['total-pages']);
        setData(res.data);
        dispatch(setReduxNotesData(res.data));
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const Notes = (params) => {
    setIsLoading(true);

    params = {
      content: content,
      category_id: noteCategory,
      title: titleReal,
    };
    ApiClient.notes(params, patientId)
      .then((res) => {
        setNoteCategory('');
        setTitleReal('');

        // setContent(res.data.content);
        getNotes();
        setContent('');
        setIsLoading(false);
        setAlertType('alert-success');
        setIsShowAlert('Notes created successfully. ');
        setErrors(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err.response.data.errors);
        setAlertType('alert-danger');
        setIsShowAlert(err.response.data.errors.detail);
        setErrors(err.response.data.errors);
      });
  };

  const updateData = (content, titleReal, notesUpdateId, catId, closeModal, setErrorsupdate) => {
    const data = {
      content: content,
      title: titleReal,
      category_id: catId,
    };
    ApiClient.updateNotes(data, patientId, notesUpdateId)
      .then((res) => {
        setIsLoading(false);
        getNotes();
        setIsShowAlert('Notes updated successfully');
        setAlertType('alert-success');
        closeModal({});
      })
      .catch((err) => {
        console.warn('er', err);
        setIsLoading(false);
        setAlertType('alert-danger');
        console.log(err.response.data);
        setErrorsupdate(err.response.data.errors);
        setIsShowUpdateAlert(err.response.data.detail);
      });
  };

  const deleteNotes = (notesId) => {
    setErrors('');
    setIsLoading(true);
    ApiClient.deleteNotes(patientId, notesId)
      .then((res) => {
        setIsLoading(false);
        getNotes();
        setIsShowAlert(res.data.message);
        setAlertType('alert-success');
      })
      .catch((err) => {
        if (err.response.status == 500) {
          setIsShowAlert('Internal server error');
        } else {
          if (!Array.isArray(Object.values(err.response.data)[0])) {
            setIsShowAlert(Object.values(err.response.data)[0]);
          }
        }
        setIsLoading(false);
        setAlertType('alert-danger');
      });
  };

  const handleSearchCategory = (srcVal) => {
    ApiClient.searchNotesCategory(srcVal)
      .then((res) => {
        setCategories(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const renderTitle = () => {
    return (
      <div className='position-relative'>
        <div className='mb-3'>
          <Select2DropDown
            container={document.getElementById('clinic-dropdown-container6')}
            data={categoriesTemplate}
            placeholder='Select Template'
            label='Select Template'
            // retuired={true}
            labelStyle={{ fontWeight: 'var(--lightFont)' }}
            value={titleReal}
            labelKey={'title'}
            valueKey={'title'}
            showMSG={errors?.category_id}
            getObjData
            onSelect={(val, obj) => {
              setTitleReal(val);
              setContent(obj.content ? obj.content : '');
            }}
            isValidation
            onSearch={(val) => {
              if (val) {
                setTitleReal(val);
              }
            }}
          />
          <div id='clinic-dropdown-container6' style={{ position: 'absolute', bottom: '0px' }}></div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return <CoreTextArea rows={3} label='Content' retuired showMSG={errors?.content} value={content} setValue={setContent} msgStyle={{ color: 'red' }} />;
  };
  const handleShowMore = () => {
    setShowAll((prev) => !prev);
  };

  const renderNotes = () => {
    return (
      <div className='table-responsive order-table'>
        <table
          class='table'
          style={{
            width: '100%',
            tableLayout: 'fixed',
          }}
        >
          <thead>
            <tr>
              <th className='text-center' scope='col' colSpan={4}>
                Content
              </th>
              <th className='text-center' scope='col'>
                Created By
              </th>
              <th className='text-center' scope='col'>
                Created At
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.length || isLoading ? (
              data?.slice(0, showAll ? data?.length : 3).map((item, index) => {
                return (
                  <tr key={item.id}>
                    <td
                      colSpan={4}
                      // className='text-center'
                      style={{
                        width: '60%',
                        wordWrap: 'break-word',
                        whiteSpace: 'normal',
                        textAlign: 'left !important',
                      }}
                    >
                      {item?.content}
                    </td>
                    <td className='text-center'>{item?.admin?.full_name}</td>
                    <td className='text-center'>{dateFormat(item?.created_at)}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={6} className='text-center'>
                  {' '}
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {data?.length > 3 && (
          <a style={{ cursor: 'pointer' }} className='d-flex justify-content-end align-items-center' onClick={() => setShowAll(!showAll)}>
            <span class='material-icons-outlined'>{showAll ? 'expand_less' : 'expand_more'}</span> {showAll ? 'View Less' : 'View More'}
          </a>
        )}
      </div>
    );
  };

  return (
    <>
      <div className='d-flex patientNote flex-column pb-3'>
        <div className='form-group my-4'>
          {isShowAlert && (
            <div className='mt-4'>
              <CommonAlert isShowAlert={isShowAlert} alertType={alertType} setIsShowAlert={setIsShowAlert} />
            </div>
          )}
          {renderNotes()}
          <div className='filterHeader'>
            <div>Create Note</div>
          </div>
          <div className='mb-3'>
            <Select2DropDown
              container={document.getElementById('clinic-dropdown-container4')}
              data={!categories.find((item) => selectedCatObj?.id == item.id) && selectedCatObj?.id ? [...categories, selectedCatObj] : categories}
              placeholder='Select Category'
              label='Select Category'
              // retuired={true}
              labelStyle={{ fontWeight: 'var(--lightFont)' }}
              value={noteCategory}
              labelKey={'title'}
              valueKey={'id'}
              showMSG={errors?.category_id}
              getObjData
              onSelect={(val, data) => {
                getCategoriesByTemplate(data);
                setNoteCategory(val);
                setSelectedCatObj(data);
              }}
              isValidation
              onSearch={(srcVal) => {
                handleSearchCategory(srcVal);
              }}
            />

            <div id='clinic-dropdown-container4' style={{ position: 'absolute', bottom: '0px' }}></div>
          </div>
          {renderTitle()}
          {renderContent()}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              width: 200,
            }}
            onClick={Notes}
            className='
          form-control coreBtn text-white btn btn-secondary btn-md '
            // className='ms-2 d-flex'
          >
            Submit
          </div>
        </div>

        {/* {tempId != undefined && (
          <NotesListView
            isShowAlert={isShowUpdateAlert}
            setIsShowAlert={setIsShowUpdateAlert}
            alertType={alertType}
            setIsLoading={setIsLoading}
            errors={errors}
            content={content}
            setContent={setContent}
            contentId={contentId}
            setContentId={setContentId}
            titleReal={titleReal}
            setTitleReal={setTitleReal}
            categoriesTemplate={categoriesTemplate}
            getCategoriesByTemplate={getCategoriesByTemplate}
            setEditError={setErrors}
            editError={errors}
            data={data}
            showKey='content'
            updateData={updateData}
            deleteData={deleteNotes}
            categories={categories}
            handleSearchCategory={handleSearchCategory}
          />
        )} */}
      </div>
      <Loader show={isLoading} />
      {/* <div className='d-flex justify-content-end'>
        <div className='patientPagination mt-3'>
          <Pagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </div>
      </div> */}
    </>
  );
}

export default PatientNotesView;
