import { AdminPanelHeader, DashBoardHeader } from 'components';
import useApiManager from 'networking/ApiManager';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setAllDepartments, setLocations } from 'redux/Slices';
import {
  useAllUserStatusQuery,
  useCreateUserTeamProfileMutation,
  useGetLoginUserDataQuery,
  useUpdateUserTeamProfileMutation,
  useUpdateWorkingHourMutation,
} from 'redux/apiSlice';
import { useGetCockpitDataQuery } from 'redux/cockpitSlice';
import { CommonAlert, Loader } from 'whealth-core-web/components';
import SearchBox from 'whealth-core-web/components/common/SearchBox';
import { TeamForm } from 'whealth-core-web/forms';
import { profileImage } from 'whealth-core-web/components/Helper';

function CreateTeam() {
  const { id } = useParams();
  const navigation = useNavigate();
  const ApiClient = useApiManager();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, serErrors] = useState({});
  const [extraErrors, setExtraErrors] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [teamUpdateData, setUpdateTamData] = useState('');

  const [alertType, setAlertType] = useState('alert-danger');
  const activeTabTeamForm = useSelector((state) => state?.sliceReducer?.activeTabTeamForm);
  const setSelectedRole = useSelector((state) => state?.sliceReducer?.selectedRole);

  let userData = useGetLoginUserDataQuery()?.data?.data || {};
  const [updateUserTeamProfile, result] = useUpdateUserTeamProfileMutation();
  const [createUserTeamProfile, createResult] = useCreateUserTeamProfileMutation();
  const [updateWorkingHour, { status: updateStatus }] = useUpdateWorkingHourMutation();
  const [updateExtraWorkingHour, { status: extraUpdateStatus }] = useUpdateWorkingHourMutation();
  const [isNivanCare, setIsNivanCare] = useState(false);
  const [isClinic, setIsClinic] = useState(false);

  const getCockpitData = useGetCockpitDataQuery();
  let configData = getCockpitData?.data?.data?.business_config;
  let userDetails = localStorage.getItem('userDetails');
  userDetails = JSON.parse(userDetails);

  useEffect(() => {
    if (configData) {
      configData.map((item) => {
        if (item.key == 'enable_summary' && item.isActive) {
          setIsNivanCare(item.isActive);
        }
        if (item.key == 'departments_clinics' && item.isActive) {
          setIsClinic(item.isActive);
        }
      });
    }
  }, [configData]);

  useEffect(() => {
    const getData = async () => {
      const res = await ApiClient.searchLocations();
      if (res.status == 200) {
        let locationsData = [];
        res.data.map((item) => locationsData.push({ id: item.id, title: item.name }));
        dispatch(setLocations(locationsData));
      }
    };
    try {
      getData();
    } catch (e) {
      console.log(e);
    }
  }, []);
  console.warn('teamUpdateData', teamUpdateData);
  useEffect(() => {
    const getData = async () => {
      const res = await ApiClient.searchDepartments();
      if (res.status == 200) {
        let departmentsData = [];
        res.data.map((item) => departmentsData.push({ id: item.id, title: item.name, statuses: item.department_status }));
        dispatch(setAllDepartments(departmentsData));
      }
    };
    try {
      getData();
    } catch (e) {
      console.log(e);
    }
  }, []);

  const adminStatus = useAllUserStatusQuery({ type: 'team' })?.data?.data;
  const formatDate = (dateStr) => {
    // Create a Date object from the date string
    const date = new Date(dateStr);

    // Check if the parsed date is valid
    if (isNaN(date)) {
      throw new RangeError('Invalid time value');
    }

    // Get year, month, and day from the date object
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');

    // Return the formatted date string
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    handleResult(result, 'Profile updated successfully');
    // handleResult(updateResult, 'Working Hours updated successfully');
  }, [result]);

  useEffect(() => {
    serErrors({});
    setExtraErrors({});
  }, [id]);

  useEffect(() => {
    handleResult(createResult, 'Profile created successfully');
  }, [createResult]);

  useEffect(() => {
    if (id) {
      getTeamData();
    }
  }, [id]);

  const getTeamData = () => {
    ApiClient.teamGet(id)
      .then((res) => {
        setUpdateTamData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleResult = (result, msg) => {
    if (result?.data?.status == '200' || result?.data?.statusText == 'OK') {
      navigation('/team', { state: { showAlert: msg } });
    } else if (result?.data?.statusText != 'OK') {
      let alertmsg = result?.data?.data?.detail;
      if (result?.data?.status == 413) {
        alertmsg = 'image size is too large';
      } else if (result?.data?.status == 500) {
        alertmsg = 'Internal server Error';
      } else if (result?.data?.status == 403) {
        alertmsg = 'You do not have permission to perform this action.';
      } else if (result?.data?.data?.errors?.locations_id?.length) {
        alertmsg = result?.data?.data?.errors?.locations_id[0];
      } else if (result?.data?.data?.errors?.appointment_exist?.length) {
        alertmsg = result?.data?.data?.errors?.appointment_exist[0];
      } else {
        if (result?.data?.data?.errors) {
          serErrors(result?.data?.data?.errors);
        }
        if (result?.data?.data?.extra_working_hour_errors) {
          setExtraErrors(result?.data?.data?.extra_working_hour_errors);
        }
      }
      setAlertType('alert-danger');
      if (alertmsg) {
        setShowAlert(alertmsg);
      }
    }
    setIsLoading(result.isLoading);
  };

  const createProfile = (formData) => {
    const formDataInstance = new FormData();
    formDataInstance.append('image_url', formData.imageUpload || '');
    formDataInstance.append('profile_url', formData.profile_url || '');
    const body = {};

    // Assign the properties of formData to data
    body.profile_url = formData.profile_url;
    body.image_name = formData.image_name;
    body.email = formData.email || '';
    body.full_name = formData.full_name || '';
    body.gender = formData.gender || '';
    body.intro = formData.intro || '';
    body.language = formData.language?.toString() || '';
    body.phone = formData.phone || '';
    body.whatsapp_number = formData.whatsapp_number || '';
    body.role = formData.role || '';
    body.status_uuid = formData.status_uuid || '';
    body.specialization = formData.specialization || '';
    body.admins_ids = [...formData.care_admins_ids,...formData.rev_admins_ids] || '';
    body.meeting_url = formData.meeting_url || '';
    body.degree = formData.degree || '';
    body.license_number = formData.license_number || '';
    body.appointment_fee = formData.appointment_fee || '';
    body.appointment_fee_details = formData.appointmentFeeDetails || [];
    // body.imageUpload = formData.imageUpload || '';

    if (isNivanCare) {
      body.department_id = formData.department || '';
      body.can_accept_payment = formData.can_accept_payment || false;
    }
    body.staff_id = formData.staff_id || '';
    body.service_id = formData.service_id || '';
    if (isClinic) {
      if (isNivanCare && formData.locations) {
        body.locations_id = formData.locations;
      }
    }
    if (formData.services) {
      body.service_ids = formData.services || '';
    }

    if (formData.signature && typeof formData.signature == 'object') {
      body.signature = formData.signature || '';
    }

    if (setSelectedRole == 'doctor' || setSelectedRole === 'doctor_pms') {
      body.appointment_slot_window = formData.appointment_slot_window || '';
      if (formData.timeSlots) {
        body.working_hours = Object.entries(formData.timeSlots).map(([day, slotData], index) => {
          return {
            id: slotData.id || '',
            is_available: slotData.is_available,
            week_day: slotData?.week_day,
            slots: slotData.slots.map((slot, slotIndex) => {
              return {
                id: slot.id || '',
                start_time: slot.start_time || '',
                end_time: slot.end_time || '',
                location_id: slot.location_id || '',
              };
            }),
          };
        });
      } else {
        body.working_hours = formData.working_hours;
      }
      if (formData.extraTimeSlots) {
        body.extra_working_hours = Object.entries(formData.extraTimeSlots).map(([day, slotData], index) => {
          return {
            id: slotData.id || '',
            is_available: slotData.is_available,
            week_day: slotData?.week_day,
            is_extra_slot: slotData?.is_extra_slot,
            extra_slot_date: slotData?.extra_slot_date ? slotData?.extra_slot_date : '',
            slots: slotData?.slots?.map((slot, slotIndex) => {
              return {
                id: slot.id || '',
                start_time: slot.start_time || '',
                end_time: slot.end_time || '',
                location_id: slot.location_id || '',
                extra_slot_date: slotData?.extra_slot_date ? slotData?.extra_slot_date : '',
              };
            }),
          };
        });
      } else {
        body.extra_working_hours = formData.extraTimeSlots;
      }
    }

    if (id) {
      let data = {
        data: body,
        id: id,
        self: userData.id == id,
      };
      let data1 = {
        data: body,
        id: id,
        self: userData.id == id,
      };
      if (activeTabTeamForm == 'Working Hours & Appointments') {
        let params = { data: data1, is_extra_slot: 0 };
        // updateWorkingHour(params);
        let extraWorkingData = {
          ...data1,
          data: {
            ...data1.data,
            working_hours:
              !data1?.data?.extra_working_hours ||
              (data1?.data?.extra_working_hours?.length == 1 && data1?.data?.extra_working_hours?.[0]?.extra_slot_date == '')
                ? []
                : data1.data.extra_working_hours,
          },
        };
        let params1 = { data: extraWorkingData, is_extra_slot: 1 };
        // updateWorkingHour(params1);

        Promise.all([updateWorkingHour(params), updateExtraWorkingHour(params1)])
          .then(([result1, result2]) => {
            // Check if both responses have status code 200

            if (result1?.data?.status === 200 && result2?.data?.status === 200) {
              navigation('/team', { state: { showAlert: ' Working Hours updated successfully' } });
            } else if (result1?.data?.status != 200) {
              handleResult(result1, result1?.statusText);
            } else if (result2?.data?.status != 200) {
              handleResult(result2, result2?.statusText);
            } else {
              // Log the errors if any of the calls failed
              console.error('Error in updating profile:', result1.error || result2.error);
            }
          })
          .catch((error) => {
            // Log any unexpected errors
            console.error('Unexpected error:', error);
          });
      } else {
        formDataInstance.append('data', JSON.stringify(data.data));
        updateUserTeamProfile({
          ...data,
          data: formDataInstance,
        });
      }
    } else {
      formDataInstance.append('data', JSON.stringify(body));
      createUserTeamProfile({
        data: formDataInstance,
      });
    }
  };

  const renderSearchBox = useMemo(() => {
    return (
      <SearchBox
        placeHolder='Search a team member by name or mobile number'
        searchMethod='SEARCHTEAM'
        label='full_name'
        searchId='Team'
        renderPath='/team/{source_id}/edit'
        imagePath='profile_url'
      />
    );
  }, []);
  console.warn('dd', userData);
  const showTree = [
    { iconClass: 'people_alt', title: 'Team', link: userData?.role != 'ee' ? '/team' : null },
    {
      icon: profileImage(teamUpdateData?.gender, userDetails?.image),
      title: teamUpdateData?.full_name,
      link: `/team`,
    },
    { iconClass: 'people_alt', title: id ? 'Update Team Member' : 'Add Team Member' },
  ];

  const leftSideData = [{ title: id ? 'Update Profile' : 'Create Profile', style: { color: 'var(--themeDarkColor)' } }];

  const showArticles = useMemo(() => (
    <>
      <div className='articleHeader stickyHeader'>
        <div className='headerFiles'>
          <AdminPanelHeader iconTitle='people_alt' showTree={showTree} searchBox={userData?.role != 'ee' ? renderSearchBox : null} />
          <DashBoardHeader leftData={leftSideData} />
        </div>
        <div className='hrLine' />
      </div>
      <div className='px-4 wrapperContent'>
        <div className='createTeamBody'>
          {showAlert && (
            <div className='mt-4'>
              <CommonAlert isShowAlert={showAlert} alertType={alertType} setIsShowAlert={setShowAlert} />
            </div>
          )}
          <TeamForm
            isNivanCareTeam
            status={adminStatus}
            errors={errors}
            extraErrors={extraErrors}
            onClick={createProfile}
            id={id}
            // updateResult={updateResult}
          />
        </div>
      </div>
    </>
  ));

  const articleBody = useMemo(() => {
    return showArticles;
  });

  return (
    <div className='wrapper'>
      {articleBody}
      <Loader show={isLoading} />
    </div>
  );
}

export default CreateTeam;
