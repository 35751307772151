import React, { useEffect, useState } from 'react';
import { CoreButton, CoreCheckBox, CoreInputBox, CoreTextArea, Select2DropDown, TagsShow } from 'whealth-core-web/components';

function ClinicForm(props) {
  const { valueObj, error, isUpdate, setValueObj, onClick, statuses, careManagersList, open } = props;
  const [selectedCareManager, setSelectedCareManager] = useState();

  useEffect(() => {
    if (!selectedCareManager && valueObj?.admin_profiles && !valueObj.admin_profiles_ids) {
      setSelectedCareManager(valueObj?.admin_profiles[0]?.id);
    }
    setValueObj({ ...valueObj, admin_profiles_ids: selectedCareManager });
  }, [selectedCareManager]);

  return (
    <div className='medicineForm'>
      <div className='formTitle'>{!isUpdate ? 'Add Hospital' : 'Update Hospital'} </div>

      <div style={{ margin: '20px 0', gap: '10px' }} className='d-flex w-100 justify-content-between medicineNamerow'>
        <div className='w-100'>
          <CoreInputBox
            showMSG={error?.name}
            placeholder='Hospital Name'
            value={valueObj?.name || ''}
            label='Hospital Name'
            setValue={(inputVal) => {
              setValueObj({ ...valueObj, name: inputVal });
            }}
            maxLength={255}
            msgStyle={{ color: 'red' }}
            retuired
          />
        </div>
      </div>

      <div style={{ margin: '20px 0' }}>
        <CoreInputBox
          showMSG={error?.address1}
          placeholder='Address 1'
          value={valueObj?.address1 || ''}
          label='Address 1'
          setValue={(inputVal) => {
            setValueObj({ ...valueObj, address1: inputVal });
          }}
          maxLength={255}
          msgStyle={{ color: 'red' }}
          retuired
        />
      </div>
      <div style={{ margin: '20px 0' }}>
        <CoreInputBox
          showMSG={error?.address2}
          placeholder='Address 2'
          value={valueObj?.address2 || ''}
          label='Address 2'
          setValue={(inputVal) => {
            setValueObj({ ...valueObj, address2: inputVal });
          }}
          maxLength={255}
          msgStyle={{ color: 'red' }}
        />
      </div>
      <div style={{ margin: '20px 0' }}>
        <CoreInputBox
          validateString
          showMSG={error?.city}
          placeholder='City'
          value={valueObj?.city || ''}
          label='City'
          setValue={(inputVal) => {
            setValueObj({ ...valueObj, city: inputVal });
          }}
          maxLength={255}
          msgStyle={{ color: 'red' }}
          retuired
        />
      </div>
      <div style={{ margin: '20px 0' }}>
        <CoreInputBox
          validateString
          showMSG={error?.state}
          placeholder='State'
          value={valueObj?.state || ''}
          label='State'
          setValue={(inputVal) => {
            setValueObj({ ...valueObj, state: inputVal });
          }}
          maxLength={255}
          msgStyle={{ color: 'red' }}
          retuired
        />
      </div>
      <div style={{ margin: '20px 0' }}>
        <CoreInputBox
          showMSG={error?.zip_code}
          placeholder='Zipcode'
          value={valueObj?.zip_code || ''}
          label='Zipcode'
          setValue={(inputVal) => {
            setValueObj({ ...valueObj, zip_code: inputVal });
          }}
          validateNumbers
          maxLength={6}
          msgStyle={{ color: 'red' }}
          retuired
        />
      </div>
      <div style={{ margin: '20px 0' }}>
        <CoreInputBox
          // validateString
          showMSG={error?.latitude}
          placeholder='Latitude'
          value={valueObj?.latitude || ''}
          label='Latitude'
          setValue={(inputVal) => {
            setValueObj({ ...valueObj, latitude: inputVal });
          }}
          maxLength={255}
          msgStyle={{ color: 'red' }}
          retuired
        />
      </div>
      <div style={{ margin: '20px 0' }}>
        <CoreInputBox
          // validatedecimal
          showMSG={error?.longitude}
          placeholder='Longitude'
          value={valueObj?.longitude || ''}
          label='Longitude'
          setValue={(inputVal) => {
            setValueObj({ ...valueObj, longitude: inputVal });
          }}
          maxLength={255}
          msgStyle={{ color: 'red' }}
          retuired
        />
      </div>
      <div style={{ margin: '20px 0' }}>
        <CoreInputBox
          // validatedecimal
          showMSG={error?.google_pin}
          placeholder='Google Pin'
          value={valueObj?.google_pin || ''}
          label='Google Pin'
          setValue={(inputVal) => {
            setValueObj({ ...valueObj, google_pin: inputVal });
          }}
          maxLength={255}
          msgStyle={{ color: 'red' }}
          retuired
        />
      </div>
      <div style={{ margin: '20px 0' }}>
        <CoreInputBox
          showMSG={error?.contact_number}
          placeholder='Contact Number'
          value={valueObj?.contact_number || ''}
          label='Contact Number'
          setValue={(inputVal) => {
            setValueObj({ ...valueObj, contact_number: inputVal });
          }}
          maxLength={10}
          validateNumbers
          msgStyle={{ color: 'red' }}
          retuired
        />
      </div>
      <div style={{ margin: '20px 0' }}>
        <CoreInputBox
          showMSG={error?.contact_email}
          placeholder='Contact Email'
          value={valueObj?.contact_email || ''}
          label='Contact Email'
          setValue={(inputVal) => {
            setValueObj({ ...valueObj, contact_email: inputVal });
          }}
          maxLength={255}
          msgStyle={{ color: 'red' }}
          retuired
        />
      </div>

      <div style={{ margin: '20px 0' }}>
        <Select2DropDown
          showMSG={error?.admin_profiles_ids}
          placeholder='Care Manager'
          value={valueObj.admin_profiles_ids || ''}
          label='Care Manager'
          onSelect={(inputVal) => {
            setSelectedCareManager(inputVal);
          }}
          maxLength={255}
          msgStyle={{ color: 'red' }}
          data={careManagersList}
        />
      </div>
      <div style={{ margin: '20px 0' }}>
        <CoreInputBox
          showMSG={error?.short_code}
          placeholder='Short Code'
          value={valueObj?.short_code || ''}
          label='Short Code'
          setValue={(inputVal) => {
            setValueObj({ ...valueObj, short_code: inputVal });
          }}
          maxLength={255}
          msgStyle={{ color: 'red' }}
        />
      </div>

      <CoreButton
        onClick={() => {
          onClick();
        }}
        title={isUpdate ? 'Update' : 'Save'}
      />
    </div>
  );
}

export default ClinicForm;
