import React, { useEffect, useState } from 'react';
import { ChipsTagsView } from 'whealth-core-web/components';

function LeadSourceFilter(props) {
  const { filterData, setFilterData } = props;
  const [activeData, setActiveData] = useState([]);

  const leadSource = {
    digital: 'Digital',
    dro: 'DRO',
    organic: 'Organic',
    social: 'Social',
    offline_brand_marketing: 'Offline/Brand Marketing',
  };

  useEffect(() => {
    let stringActiveData = activeData.map((item) => {
      return leadSource[item];
    });
    stringActiveData = stringActiveData?.toString();
    let obj = { source: stringActiveData };
    setFilterData(obj);
  }, [activeData]);

  useEffect(() => {
    setActiveData(new Array());
  }, []);

  useEffect(() => {
    const tempActiveData = filterData?.source?.split(',');
    setActiveData(
      tempActiveData?.map((item) => {
        return Object.keys(leadSource).find((key) => leadSource[key] === item);
      }) || []
    );
  }, [filterData]);

  const renderHeading = () => {
    return <div className='filterHeadings mt-3'>Lead Source</div>;
  };

  const renderChips = () => {
    return (
      <div>
        <div className='mt-3' />
        <ChipsTagsView
          setActiveTags={setActiveData}
          activeTags={activeData}
          data={Object.keys(leadSource).map((item) => {
            return {
              id: item,
              title: leadSource[item],
            };
          })}
        />
      </div>
    );
  };

  return (
    <div>
      {renderHeading()}
      {renderChips()}
    </div>
  );
}

export default LeadSourceFilter;
