import useApiManager from 'networking/ApiManager';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { addIconWhite, searchLight } from 'res/images';
import { CoreButton, CoreCheckBox, CoreInputBox, Select2DropDown, TagSelectPicker } from 'whealth-core-web/components';
import TimezoneSelect from 'react-timezone-select';
import CommonRenderCheckBox from 'whealth-core-web/components/CommonRenderCheckBox';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import TimeKeeper from 'react-timekeeper';
import { STRING } from 'constants/String';
import { DatePicker } from 'rsuite';
function WatiConfigForm(props) {
  const {
    error,
    valueObj,
    setValueObj,
    onClick,
    setIsLoading,
    logo,
    setConversionlogData,
    conversionLogData,
    modelOperator,
    setModelOperator,
    fieldDetails,
    setFieldDetails,
    periodDetails,
    setPeriodDetails,

    showConversionDetails,
    setShowConversionDetails,
    showPmsDetails,
    setShowPmsDetails,
    conversionLogV2,
    setConversionLogV2,
    isNotDistinct,
    setIsNotDistinct,
    CommonTemplateName,
    setCommonTemplateName,
    currentStep,
  } = props;
  const ApiClient = useApiManager();
  const [data, setData] = useState([]);
  const [showFrom, setShowFrom] = useState(false);
  const [showTo, setShowTo] = useState(false);
  const [conversionLogQuestionData, setConversionLogQuestionData] = useState([]);
  const [conversionLogAnswerData, setConversionLogAnswerData] = useState([]);

  useEffect(() => {
    let params = { page: 1, all_questions: true };
    ApiClient.getBehaviourQuestions(params).then((res) => {
      console.log('res.data', res.data);
      getCLdata(res.data);
    });
  }, []);

  const getCLdata = (data) => {
    let arr1 = data.map((item) => ({
      id: item.question.replace(/\s+/g, '_').toLowerCase(), // Assign a sequential ID starting from 1
      title: item.question, // Use the input value as the title
    }));
    let arr2 = data
      .map((item) => item.options)
      .filter((item) => item != null && item != '')
      .flatMap((item) => item.split(/[|\/]/)) // Split by '|' or '/' and flatten the array
      .filter((option) => option.trim() !== '') // Remove empty strings
      .map((option) => option.trim())
      .map((item) => ({
        id: item.replace(/\s+/g, '_').toLowerCase(), // Assign a sequential ID starting from 1
        title: item, // Use the input value as the title
      }));
    console.log('arr1', arr1, arr2);
    setConversionLogQuestionData(arr1);
    setConversionLogAnswerData(arr2);
  };

  const handleFieldDetailsChange = (key, value, index) => {
    const updatedFieldDetails = fieldDetails.map((item, idx) => (idx === index ? { ...item, [key]: value } : item));
    setFieldDetails(updatedFieldDetails);
  };

  const handlePeriodDetailsChange = (key, value, index) => {
    const updatedPeriodDetails = periodDetails.map((item, idx) => (idx === index ? { ...item, [key]: value } : item));
    setPeriodDetails(updatedPeriodDetails);
  };

  useEffect(() => {
    getGoalsData();
  }, []);

  const getGoalsData = () => {
    setIsLoading(true);
    ApiClient.getGoals()
      .then((res) => {
        setIsLoading(false);
        setData(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getValueList = (value) => {
    switch (value) {
      case 'Conversion_Log_Question':
        return conversionLogQuestionData;
      case 'Conversion_Log_Answer':
        return conversionLogAnswerData;
      case 'Next_PMS_Checkin_Date':
        return [];
      default:
        return [];
    }
  };

  const pmsTable = () => {
    return (
      <div className='d-flex w-100'>
        <div style={{ width: '40%', margin: '0 auto' }}>
          <table style={{ width: '100%' }}>
            <thead>
              <tr style={{ backgroundColor: '#E8EDFF' }}>
                <th style={{ padding: '10px', textAlign: 'left' }}>Field</th>
                <th style={{ padding: '10px', textAlign: 'left' }}>Operator</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ padding: '10px', alignItems: 'center' }} className='capitalize'>
                  Next PMS Checkin Date
                </td>
                <td style={{ padding: '10px', alignItems: 'center' }} className='capitalize'>
                  Is Assigned
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{ width: '50%', margin: 'auto' }}>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr style={{ backgroundColor: '#E8EDFF' }}>
                <th style={{ padding: '10px', textAlign: 'left' }}></th>
                <th style={{ padding: '10px', textAlign: 'left' }}>Day</th>
                <th style={{ padding: '10px', textAlign: 'left' }}>Template</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ padding: '10px', display: 'flex', alignItems: 'center' }}>
                  <div className='w-50'>
                    <CommonRenderCheckBox
                      id='flexCheckDefault5'
                      htmlFor='flexCheckDefault5'
                      onChange={(data) => {
                        setValueObj({
                          ...valueObj,
                          next_pms_checkin_date_notification: !valueObj.next_pms_checkin_date_notification,
                        });
                      }}
                      checked={valueObj.next_pms_checkin_date_notification}
                    />
                  </div>
                </td>
                <td className='w-25'>Instantly</td>
                <td>
                  <div>
                    <CoreInputBox
                      showMSG={error?.next_pms_checkin_date_notification_template_name}
                      placeholder={STRING.NEXT_PMS_CHECKIN_DATE + STRING.NOTIFICATION_TEMPLATE_NAME}
                      value={valueObj?.next_pms_checkin_date_notification_template_name || ''}
                      setValue={(data) => {
                        setValueObj({
                          ...valueObj,
                          next_pms_checkin_date_notification_template_name: data,
                        });
                      }}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const handleConversionChange = (key, value, index, row) => {
    const updatedConversionLog = [...conversionLogV2];
    updatedConversionLog[index] = {
      ...updatedConversionLog[index],
      [key]: value,
    };

    // If the key is 'value', update the value array based on selected items
    if (key === 'value') {
      const selectedValues = value.map((selected) => {
        // Find if the selected value is already present in the conversionLogV2 array
        let existingValue = null;
        for (let i = 0; i < conversionLogV2.length; i++) {
          existingValue = conversionLogV2[i]?.value.find((item) => item.id === selected);
          if (existingValue) break;
        }

        return existingValue && row.field === 'Question'
          ? existingValue
          : {
              id: selected,
              label: selected, // Assuming title is the label
              period: [
                {
                  day: '',
                  templateName: '',
                  isActive: false,
                },
              ],
            };
      });

      updatedConversionLog[index].value = selectedValues;
      // if (existingValue && row.field === 'Question') setPrevQuesVal(value);
    }

    setConversionLogV2(updatedConversionLog);
    console.log('updatedConversionLog', value);
  };

  const periodTable = useCallback(() => {
    return (
      <div className='d-flex'>
        <div style={{ width: '50%', margin: '0 auto' }}>
          <table style={{ width: '100%' }}>
            <thead>
              <tr style={{ backgroundColor: '#E8EDFF' }}>
                <th style={{ padding: '10px', textAlign: 'left' }}>Field</th>
                <th style={{ padding: '10px', textAlign: 'left' }}>Operator</th>
                <th style={{ padding: '10px', textAlign: 'left' }}>Value</th>
              </tr>
            </thead>
            <tbody>
              {fieldDetails.map((row, index) => (
                <>
                  <tr key={index}>
                    <td className='capitalize'>
                      <div className=' w-100 '>
                        <Select2DropDown
                          value={row.field}
                          disabled={index === 0}
                          data={
                            index === 0
                              ? ['Question', 'Answer']?.map((item) => ({
                                  id: item.replace(/\s+/g, '_'), // Assign a sequential ID starting from 1
                                  title: item, // Use the input value as the title
                                }))
                              : ['Answer']?.map((item) => ({
                                  id: item.replace(/\s+/g, '_'), // Assign a sequential ID starting from 1
                                  title: item, // Use the input value as the title
                                }))
                          }
                          labelKey='title'
                          // label={'Medicine Formulation'}
                          placeholder={'Field'}
                          valueKey='id'
                          retuired
                          onSelect={(inputVal) => {
                            handleFieldDetailsChange('field', inputVal, index);
                            // setMedicineFormulation(inputVal);
                          }}
                          // showMSG={medicineError?.formulation}
                        />
                      </div>
                    </td>
                    <td className='capitalize'>
                      <div className=' w-100 '>
                        <Select2DropDown
                          value={row.operator}
                          data={['is', 'is not']?.map((item) => ({
                            id: item.replace(/\s+/g, '_'), // Assign a sequential ID starting from 1
                            title: item, // Use the input value as the title
                          }))}
                          labelKey='title'
                          // label={'Medicine Formulation'}
                          placeholder={'Operator'}
                          valueKey='id'
                          retuired
                          onSelect={(inputVal) => {
                            handleFieldDetailsChange('operator', inputVal, index);

                            // setMedicineFormulation(inputVal);
                          }}
                          // showMSG={medicineError?.formulation}
                        />
                      </div>
                    </td>
                    <td className='capitalize'>
                      <div className=' w-100 '>
                        <TagSelectPicker
                          // isValidation
                          data={row.field === 'Question' ? conversionLogQuestionData : conversionLogAnswerData}
                          onSelect={(inputVal, _obj, _objData) => {
                            handleFieldDetailsChange('value', inputVal, index);
                          }}
                          onSearch={(searchStr) => {
                            // handleClinicSearch(searchStr);
                          }}
                          onClean={() => {
                            handleFieldDetailsChange('value', [], index);
                          }}
                          value={row.value || []}
                          labelKey={'title'}
                          searchable={true}
                          style={{ width: '100%' }}
                          // placeholder='Select Clinic'
                        />
                      </div>
                    </td>
                  </tr>
                  {index !== fieldDetails.length - 1 && (
                    <button
                      onClick={() => {
                        const updatedFieldDetails = fieldDetails.map((item, idx) => (idx === index ? { ...item, rowOperator: !item.rowOperator } : item));
                        setFieldDetails(updatedFieldDetails); // Update state with new array
                        console.log('temp', updatedFieldDetails);
                      }}
                    >
                      {row?.rowOperator ? 'AND' : 'OR'}
                    </button>
                  )}
                </>
              ))}
              {fieldDetails.length < 2 && (
                <tfoot>
                  <span
                    className='material-icons-outlined breadcrumbpic pointer'
                    onClick={() => {
                      const newFieldDetails = {
                        field: '',
                        operator: '',
                        value: '',
                        rowOperator: false,
                      };
                      // Update state with a new array including the new period detail
                      setFieldDetails([...fieldDetails, newFieldDetails]);
                    }}
                    style={{ color: 'blue' }}
                  >
                    add
                  </span>
                </tfoot>
              )}
            </tbody>
          </table>
        </div>
        <div style={{ width: '40%', margin: 'auto' }}>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr style={{ backgroundColor: '#E8EDFF' }}>
                <th style={{ padding: '10px', textAlign: 'left' }}></th>
                <th style={{ padding: '10px', textAlign: 'left' }}>Day</th>
                <th style={{ padding: '10px', textAlign: 'left' }}>Template</th>
              </tr>
            </thead>
            <tbody>
              {periodDetails.map((row, index) => (
                <tr key={index} style={{ borderBottom: '1px solid #ddd' }}>
                  <td style={{ padding: '10px', display: 'flex', alignItems: 'center' }}>
                    <CoreCheckBox
                      checked={row.isActive}
                      onChange={(val, checked) => {
                        // handleCheckboxChange(index, e.target.checked)
                        handlePeriodDetailsChange('isActive', checked, index);
                      }}
                      style={{ marginRight: '10px' }}
                    />
                  </td>
                  <td className='capitalize'>
                    <div className=' w-100 '>
                      <Select2DropDown
                        value={row.day}
                        data={[
                          { id: 0, label: 'day 1' },
                          { id: 1, label: 'day 2' },
                          { id: 2, label: 'day 3' },
                          { id: 6, label: 'day 7' },
                          { id: 14, label: 'day 15 to 30' },
                        ].filter(
                          (option) => !periodDetails.some((detail) => detail.day === option.id) || option.id === row.day // Keep the currently selected option
                        )}
                        labelKey='label'
                        // label={'Medicine Formulation'}
                        placeholder={'Day'}
                        valueKey='id'
                        retuired
                        onSelect={(inputVal) => {
                          // setMedicineFormulation(inputVal);
                          handlePeriodDetailsChange('day', inputVal, index);
                        }}
                        // showMSG={medicineError?.formulation}
                      />
                    </div>
                  </td>
                  <td style={{ padding: '10px' }}>
                    <CoreInputBox
                      placeholder='Template Name'
                      value={row.templateName}
                      setValue={(e) => {
                        // handleTemplateChange(index, e.target.value)
                        handlePeriodDetailsChange('templateName', e, index);
                        console.log(e);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <span
            className='material-icons-outlined breadcrumbpic pointer'
            onClick={() => {
              const newPeriodDetail = {
                isActive: false,
                day: '',
                templateName: '',
              };
              // Update state with a new array including the new period detail
              setPeriodDetails([...periodDetails, newPeriodDetail]);
            }}
            style={{ color: 'blue' }}
          >
            add
          </span>
        </div>
      </div>
    );
  }, [fieldDetails, periodDetails, conversionLogQuestionData, conversionLogAnswerData, valueObj]);

  const handlePeriodArrChange = (field, value, index, question) => {
    setConversionLogV2((prev) => {
      return prev.map((item) => {
        if (item.value.some((q) => q.id === question.id)) {
          return {
            ...item,
            value: item.value.map((q) => {
              if (q.id === question.id) {
                return {
                  ...q,
                  period: q.period.map((row, i) => (i === index ? { ...row, [field]: value } : row)),
                };
              }
              return q;
            }),
          };
        }
        return item;
      });
    });
    console.log('changecvalue', conversionLogV2);
  };

  const handleAddNewPeriod = (question) => {
    const newPeriodDetail = {
      isActive: false,
      day: '',
      templateName: '',
    };

    setConversionLogV2((prev) =>
      prev.map((item) => {
        if (item.value.some((q) => q.id === question.id)) {
          return {
            ...item,
            value: item.value.map((q) => {
              if (q.id === question.id) {
                return {
                  ...q,
                  period: [...q.period, newPeriodDetail],
                };
              }
              return q;
            }),
          };
        }
        return item;
      })
    );
  };

  const conversionValueTable = useCallback(() => {
    return conversionLogV2[0]?.value.map((question) => (
      <>
        <h6 key={question.id}>
          {question.id
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ')}
        </h6>
        <div style={{ width: '100%', margin: 'auto' }}>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr style={{ backgroundColor: '#E8EDFF' }}>
                <th style={{ padding: '10px', textAlign: 'left' }}></th>
                <th style={{ padding: '10px', textAlign: 'left' }}>Day</th>
                <th style={{ padding: '10px', textAlign: 'left' }}>Template</th>
              </tr>
            </thead>
            <tbody>
              {question.period.map((row, index) => (
                <tr key={index} style={{ borderBottom: '1px solid #ddd' }}>
                  <td style={{ padding: '10px', display: 'flex', alignItems: 'center' }}>
                    <CoreCheckBox
                      checked={row.isActive}
                      onChange={(val, checked) => {
                        // handleCheckboxChange(index, e.target.checked)
                        // handlePeriodDetailsChange('isActive', checked, index);
                        handlePeriodArrChange('isActive', checked, index, question);
                      }}
                      style={{ marginRight: '10px' }}
                    />
                  </td>
                  <td className='capitalize'>
                    <div className=' w-100 '>
                      <Select2DropDown
                        value={row.day}
                        data={[
                          { id: 0, label: 'day 1' },
                          { id: 1, label: 'day 2' },
                          { id: 2, label: 'day 3' },
                          { id: 4, label: 'day 5' },
                          { id: 6, label: 'day 7' },
                          { id: 14, label: 'day 15' },
                          { id: 29, label: 'day 30' },
                        ].filter(
                          (option) => !question.period.some((detail) => detail.day === option.id) || option.id === row.day // Keep the currently selected option
                        )}
                        labelKey='label'
                        // label={'Medicine Formulation'}
                        placeholder={'Day'}
                        valueKey='id'
                        retuired
                        onSelect={(inputVal) => {
                          // setMedicineFormulation(inputVal);
                          // handlePeriodDetailsChange('day', inputVal, index);
                          handlePeriodArrChange('day', inputVal, index, question);
                        }}
                        // showMSG={medicineError?.formulation}
                      />
                    </div>
                  </td>
                  <td style={{ padding: '10px' }}>
                    <CoreInputBox
                      placeholder='Template Name'
                      value={row.templateName}
                      setValue={(e) => {
                        // handleTemplateChange(index, e.target.value)
                        // handlePeriodDetailsChange('templateName', e, index);
                        handlePeriodArrChange('templateName', e, index, question);

                        console.log(e);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <span className='material-icons-outlined breadcrumbpic pointer' onClick={() => handleAddNewPeriod(question)} style={{ color: 'blue' }}>
            add
          </span>
        </div>
      </>
    ));
  }, [conversionLogV2]);

  const conversionLogTable = useCallback(() => {
    return (
      <div className='d-flex'>
        <div style={{ width: '100%', margin: '0 auto' }}>
          <table style={{ width: '100%' }}>
            <thead>
              <tr style={{ backgroundColor: '#E8EDFF' }}>
                <th style={{ padding: '10px', textAlign: 'left' }}>Conversion Log</th>
                <th style={{ padding: '10px', textAlign: 'left' }}>Operator</th>
                <th style={{ padding: '10px', textAlign: 'left' }}>Value</th>
              </tr>
            </thead>
            <tbody>
              {conversionLogV2.map((conversion, index) => (
                <>
                  <tr>
                    <td style={{ padding: '15px' }}>
                      <div className=' w-100 '>
                        <Select2DropDown
                          value={conversion.field}
                          disabled={index === 0}
                          data={
                            index === 0
                              ? ['Question', 'Answer']?.map((item) => ({
                                  id: item.replace(/\s+/g, '_'), // Assign a sequential ID starting from 1
                                  title: item, // Use the input value as the title
                                }))
                              : ['Answer']?.map((item) => ({
                                  id: item.replace(/\s+/g, '_'), // Assign a sequential ID starting from 1
                                  title: item, // Use the input value as the title
                                }))
                          }
                          labelKey='title'
                          // label={'Medicine Formulation'}
                          placeholder={'Conversion Log'}
                          valueKey='id'
                          retuired
                          onSelect={(inputVal) => {
                            handleConversionChange('field', inputVal, index, conversion);
                            // setMedicineFormulation(inputVal);
                          }}
                          // showMSG={medicineError?.formulation}
                        />
                      </div>
                    </td>
                    <td style={{ padding: '15px' }} className='capitalize'>
                      <div className=' w-100 '>
                        <Select2DropDown
                          value={conversion.operator}
                          data={['is']?.map((item) => ({
                            id: item.replace(/\s+/g, '_'), // Assign a sequential ID starting from 1
                            title: item, // Use the input value as the title
                          }))}
                          labelKey='title'
                          // label={'Medicine Formulation'}
                          placeholder={'Operator'}
                          valueKey='id'
                          retuired
                          onSelect={(inputVal) => {
                            handleConversionChange('operator', inputVal, index, conversion);

                            // setMedicineFormulation(inputVal);
                          }}
                          // showMSG={medicineError?.formulation}
                        />
                      </div>
                    </td>
                    <td style={{ padding: '15px' }} className='capitalize'>
                      <div className=' w-100 '>
                        <TagSelectPicker
                          // isValidation
                          data={[
                            ...new Map(
                              (conversion.field === 'Question' ? conversionLogQuestionData : conversionLogAnswerData).map((item) => [item.id, item])
                            ).values(),
                          ]}
                          onSelect={(inputVal, _obj, _objData, prev) => {
                            console.log('inputVal, _obj, _objData', inputVal, _obj, _objData, prev);
                            handleConversionChange('value', inputVal, index, conversion);
                          }}
                          onSearch={(searchStr) => {
                            // handleClinicSearch(searchStr);
                          }}
                          onClean={() => {
                            handleConversionChange('value', [], index, conversion);
                          }}
                          value={conversion.value.map((item) => item.id) || []}
                          labelKey={'title'}
                          searchable={true}
                          style={{ width: '100%' }}
                          // placeholder='Select Clinic'
                        />
                      </div>
                    </td>
                  </tr>
                  {index !== conversionLogV2.length - 1 && (
                    <button
                      onClick={() => {
                        if (!conversion?.rowOperator) {
                          const updatedFieldDetails = conversionLogV2.map((item, idx) => (idx === index ? { ...item, rowOperator: !item.rowOperator } : item));
                          setConversionLogV2(updatedFieldDetails); // Update state with new array
                          console.log('temp', updatedFieldDetails);
                        }
                      }}
                    >
                      {conversion?.rowOperator ? 'AND' : 'OR'}
                    </button>
                  )}
                </>
              ))}
              {conversionLogV2.length < 2 && (
                <tfoot>
                  <span
                    className='material-icons-outlined breadcrumbpic pointer'
                    onClick={() => {
                      const newFieldDetails = {
                        field: '',
                        operator: '',
                        value: [],
                        rowOperator: false,
                      };
                      // Update state with a new array including the new period detail
                      setConversionLogV2([...conversionLogV2, newFieldDetails]);
                    }}
                    style={{ color: 'blue' }}
                  >
                    add
                  </span>
                </tfoot>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }, [conversionLogV2, setConversionLogV2, conversionLogAnswerData, conversionLogQuestionData]);

  const renderBox = useCallback(() => {
    return (
      <>
        <div className='commonBorderBox settingFormOutline mb-4 pb-4'>
          {currentStep === 1 ? (
            <>
              <div className='formTitle'>WATI Configuration</div>

              <div className='commonBorderBox settingFormOutline mb-4 pb-4'>
                <div className='formTitle'>Account Configuration</div>
                <div className='mt-4'>
                  <CoreInputBox
                    showMSG={error?.wati_access_token}
                    placeholder='Access Token'
                    value={valueObj?.wati_access_token || ''}
                    label='Access Token'
                    setValue={(data) => {
                      setValueObj({ ...valueObj, wati_access_token: data });
                    }}
                  />
                </div>
                <div className='mt-4'>
                  <CoreInputBox
                    showMSG={error?.wati_api_endpoint}
                    placeholder='Wati Base URL'
                    value={valueObj?.wati_api_endpoint || ''}
                    label='WATI Base URL'
                    setValue={(data) => {
                      setValueObj({ ...valueObj, wati_api_endpoint: data });
                    }}
                  />
                </div>
                <div className='mt-4'>
                  <CoreInputBox
                    showMSG={error?.wati_broadcast_name_prefix}
                    placeholder='WATI Broadcast Name'
                    value={valueObj?.wati_broadcast_name_prefix || ''}
                    label='WATI Broadcast Name'
                    setValue={(data) => {
                      setValueObj({
                        ...valueObj,
                        wati_broadcast_name_prefix: data,
                      });
                    }}
                  />
                </div>
                <div className='mt-4'>
                  <CoreInputBox
                    showMSG={error?.wati_api_key}
                    placeholder='WATI API Key'
                    value={valueObj?.wati_api_key || ''}
                    label='WATI API Key'
                    setValue={(data) => {
                      setValueObj({ ...valueObj, wati_api_key: data });
                    }}
                  />
                </div>
                <div className='mt-4'>
                  <CoreInputBox
                    showMSG={error?.wati_token_secret}
                    placeholder='WATI Token Secret'
                    value={valueObj?.wati_token_secret || ''}
                    label='WATI Token Secret'
                    setValue={(data) => {
                      setValueObj({ ...valueObj, wati_token_secret: data });
                    }}
                  />
                </div>
              </div>

              <div className='commonBorderBox settingFormOutline mb-4 pb-4'>
                <div className='formTitle'>Goal Notification Configuration</div>
                <div className='d-flex justify-content-left'>
                  <div className='w-50'>
                    <CommonRenderCheckBox
                      label='Goal Assignment Notification'
                      id='flexCheckDefault3'
                      htmlFor='flexCheckDefault3'
                      onChange={(data) => {
                        setValueObj({
                          ...valueObj,
                          wati_goal_assignment_notification: !valueObj.wati_goal_assignment_notification,
                        });
                      }}
                      checked={valueObj.wati_goal_assignment_notification}
                    />
                  </div>
                  <div className='w-50'>
                    <CoreInputBox
                      showMSG={error?.wati_goal_assignment_notification_template_name}
                      placeholder='Goal Assignment Notification Template Name'
                      value={valueObj?.wati_goal_assignment_notification_template_name || ''}
                      label='Goal Assignment Notification Template Name'
                      setValue={(data) => {
                        setValueObj({
                          ...valueObj,
                          wati_goal_assignment_notification_template_name: data,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-4'>
                  <div className='w-50'>
                    <CommonRenderCheckBox
                      label='Goal Reminder Notification'
                      id='flexCheckDefault4'
                      htmlFor='flexCheckDefault4'
                      onChange={(data) => {
                        setValueObj({
                          ...valueObj,
                          wati_goal_reminder_notification: !valueObj.wati_goal_reminder_notification,
                        });
                      }}
                      checked={valueObj.wati_goal_reminder_notification}
                    />
                  </div>

                  <div className='w-50'>
                    <CoreInputBox
                      showMSG={error?.wati_goal_reminder_notification_template_name}
                      placeholder='Goal Reminder Notification Template Name'
                      value={valueObj?.wati_goal_reminder_notification_template_name || ''}
                      label='Goal Reminder Notification Template Name'
                      setValue={(data) => {
                        setValueObj({
                          ...valueObj,
                          wati_goal_reminder_notification_template_name: data,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className='commonBorderBox settingFormOutline mb-4 pb-4'>
                <div className='d-flex  formTitle'>
                  <CommonRenderCheckBox
                    id='flexCheckDefault6'
                    htmlFor='flexCheckDefault6'
                    onChange={(data) => {
                      setShowPmsDetails(!showPmsDetails);
                    }}
                    checked={showPmsDetails}
                  />
                  {STRING.NEXT_PMS_CHECKIN_DATE} Configuration
                </div>
                {showPmsDetails && <div className='d-flex justify-content-left w-100'>{pmsTable()}</div>}
              </div>
              {showPmsDetails && showConversionDetails && (
                <div className='commonBorderBox settingFormOutline mb-4 pb-4 d-flex'>
                  <div className=' w-50 '>
                    <Select2DropDown
                      disabled={true}
                      value={modelOperator}
                      data={['AND', 'OR', 'DISTINCT']?.map((item) => ({
                        id: item, // Assign a sequential ID starting from 1
                        title: item, // Use the input value as the title
                      }))}
                      labelKey='title'
                      placeholder={'Model Operator'}
                      label={'Model Operator'}
                      valueKey='id'
                      onSelect={(inputVal) => {
                        setModelOperator(inputVal);
                      }}
                    />
                  </div>
                  {(modelOperator === 'AND' || modelOperator === 'OR') && (
                    <div className='w-50'>
                      <p>Common Template Name</p>
                      <CoreInputBox
                        placeholder='Common Template Name'
                        value={CommonTemplateName}
                        setValue={(e) => {
                          // handleTemplateChange(index, e.target.value)
                          setCommonTemplateName(e);
                          console.log(e);
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
              <div className='commonBorderBox settingFormOutline mb-4 pb-4'>
                <div className='d-flex formTitle'>
                  <CommonRenderCheckBox
                    id='flexCheckDefault7'
                    htmlFor='flexCheckDefault7'
                    onChange={(data) => {
                      setShowConversionDetails(!showConversionDetails);
                    }}
                    checked={showConversionDetails}
                  />
                  Conversion Log Notification Configuration
                </div>

                {console.log('conversionLogData', conversionLogData)}

                {/* {showConversionDetails && periodTable()} */}
                {showConversionDetails && conversionLogTable()}
                {showConversionDetails && conversionValueTable()}
              </div>
            </>
          )}
        </div>
      </>
    );
  }, [error, valueObj, modelOperator, conversionLogData, pmsTable, periodTable]); // Added dependencies

  return (
    <>
      <div style={{ gap: '10px' }} className='d-flex w-100 justify-content-between '>
        <div className='w-100 '>{renderBox()}</div>
      </div>
      <CoreButton onClick={onClick} title={'Save'} />
    </>
  );
}

export default WatiConfigForm;
