import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { aiChat, aiChatBot, aiChatUser, editTheme } from 'res/images';
import { Button, Modal, Radio, RadioGroup } from 'rsuite';
import { CoreCheckBox, CoreInputBox } from '../index';
import { dateFormat, iconImages, timeFormat } from '../Helper';
import { useDispatch } from 'react-redux';
import { setAlerts } from 'redux/Slices';
import CommonToolTip from '../CommonToolTip';

function PostBody(props) {
  const { dateVal, title, children, isEdit, handleChange, getGraphData, getPatientData, AiChat, handleExportChat } = props;
  const ApiClient = useApiManager();
  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState('');
  const [formVal, setFormVal] = useState({});
  const [arrvalues, setArrvalues] = useState([0, 0, 0]);
  const [openChatModal, setOpenChatModal] = useState(false);

  let isMultiValueSupport = isOpen?.patient_goal?.goal?.multi_value_support;

  const dayConst = ['Morning', 'Afternoon', 'Night'];
  const dispatch = useDispatch();
  let targetValueArr = [];
  let arrData = [];
  let isMedicineGoal = isOpen?.patient_goal?.goal?.medicinal_goal;
  let newValue = formVal?.value || isEdit?.value;

  if (isOpen) {
    targetValueArr = isOpen?.value?.split('').map((item) => parseInt(item)) || [];
    arrData = newValue?.split('').map((item) => parseInt(item)) || [];
  }

  useEffect(() => {
    // if (isOpen?.type == "PatientGoal") {
    let valueObj = { patient_goal_id: isOpen?.patient_goal?.id };
    setFormVal({ ...isOpen, ...valueObj });
    // }
  }, [isOpen]);

  const handleCheckBoxClick = (val, isChecked, index, arrvalues) => {
    let values = arrvalues;
    if (val && isChecked) {
      values[val] = 1;
    } else if (val && !isChecked) {
      values[val] = 0;
    }
    setArrvalues(values);

    formVal.value = values.join('');
    setFormVal({ ...formVal });
  };

  const handleChecked = (index) => {
    if (arrData.length && arrData[index]) {
      arrvalues[index] = 1;
      return true;
    } else if (arrvalues[index]) {
      return true;
    }
    return false;
  };

  const handleFormSubmit = () => {
    ApiClient.updateActivityLogs(id, isEdit.id, formVal)
      .then((res) => {
        handleModalClose();
        dispatch(setAlerts({ patient: { title: 'Activity updated successfully', type: 'alert-success' } }));
        getGraphData();
        getPatientData();
      })
      .catch((err) => {
        console.log(err);
        // dispatch(setAlerts({ patient: { title: "Failed to update activity", type: "alert-danger" } }));
        setError(err.response.data.errors);
      });
  };

  // goal start

  const renderImageValues = (imageObj) => {
    return Object.keys(imageObj).map((item, index) => {
      let isactive = '';
      if (formVal.value == item) {
        isactive = 'active-sign';
      }
      return (
        <div
          key={index}
          onClick={() => {
            formVal.value = item;
            setFormVal({ ...formVal });
          }}
        >
          <img src={imageObj[item]} className={isactive} />
        </div>
      );
    });
  };

  const moodtrackerValue = () => {
    return <div className='d-flex goal-sign-images justify-content-center mt-4'>{renderImageValues(iconImages().mood)}</div>;
  };

  const energyTrackerValue = () => {
    return <div className='d-flex goal-sign-images justify-content-center mt-4'>{renderImageValues(iconImages().energy)}</div>;
  };

  let renderValue2 = () => {
    if (isMultiValueSupport) {
      return (
        <div className='w-100 d-flex' style={{ margin: '0px 0 0px 6px' }}>
          <div className='w-100 mt-4'>
            <CoreInputBox
              validateZeroToTen={isEdit?.patient_goal?.goal?.code == 'pain'}
              validateZero={isEdit?.patient_goal?.goal?.code !== 'pain' && true}
              showMSG={error?.value2}
              label=''
              validatedecimal
              placeholder={isEdit?.extra_details?.value2}
              inputStyle={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              value={formVal.value2 || ''}
              setValue={(text) => {
                formVal.value2 = text;
                setFormVal({ ...formVal });
              }}
              msgStyle={{ color: 'red' }}
            />
          </div>
          <div>
            <div className='unitBox mt-4 capitalize'>{isOpen?.patient_goal?.goal?.measuring_event?.measuring_unit ?? 'unit'}</div>
          </div>
        </div>
      );
    } else {
      return;
    }
  };

  const renderRadiobox = () => {
    return (
      <div className='d-flex'>
        <RadioGroup
          inline
          name='radio-name'
          value={formVal.value}
          onChange={(e) => {
            setFormVal({ ...formVal, value: e });
          }}
        >
          <Radio value={'1'}>Yes</Radio>
          <Radio value={'0'}>no</Radio>
        </RadioGroup>
      </div>
    );
  };

  const renderGoalValueField = () => {
    let dataObj = {
      mood: moodtrackerValue,
      energy: energyTrackerValue,
    };

    if (dataObj[isEdit?.patient_goal?.goal?.measuring_event?.measuring_unit]) {
      return (
        <div>
          {dataObj[isEdit?.patient_goal?.goal?.measuring_event?.measuring_unit]()}
          <div style={{ color: 'red', textAlign: 'center' }}>{error?.value}</div>
          <div>
            <button type='submit' value={formVal.value} onClick={handleFormSubmit} className='form-control coreBtn text-white'>
              Save
            </button>
          </div>
        </div>
      );
    } else {
      return renderInputField();
    }
  };

  const renderInputField = () => {
    let inputStyle = !isMultiValueSupport && { borderTopRightRadius: 0, borderBottomRightRadius: 0 };
    return (
      <div className='w-100 mt-4' style={{ margin: '20px 0 0px 6px' }}>
        <div className='d-flex '>
          <div className='w-100'>
            {isEdit?.patient_goal?.goal?.input_type !== 'radio' ? (
              <CoreInputBox
                validateZeroToTen={isEdit?.patient_goal?.goal?.code == 'pain'}
                validateZero={isEdit?.patient_goal?.goal?.code !== 'pain' && true}
                showMSG={error?.value}
                validatedecimal
                label='Value'
                inputStyle={{ ...inputStyle }}
                placeholder={isEdit?.extra_details?.value}
                value={formVal.value || ''}
                setValue={(text) => {
                  formVal.value = text;
                  setFormVal({ ...formVal });
                }}
                msgStyle={{ color: 'red' }}
              />
            ) : (
              renderRadiobox()
            )}
          </div>
          {renderValue2()}
          {!isMultiValueSupport && isEdit?.patient_goal?.goal?.input_type !== 'radio' && (
            <div>
              <div className='unitBox mt-4 capitalize'>{isOpen?.patient_goal?.goal?.measuring_event?.measuring_unit ?? 'unit'}</div>
            </div>
          )}
        </div>
        <div>
          <button type='submit' value={formVal.value} onClick={handleFormSubmit} className='form-control coreBtn text-white'>
            Save
          </button>
        </div>
      </div>
    );
  };

  // goal end

  const prescriptionEditForm = () => {
    return (
      <div>
        <div className='mb-4'>
          <div className='my-4'>
            <div className='mb-1' style={{ color: 'var(--lightBlack)' }}>
              Time
            </div>
            <div className='mt-1 d-flex flex-wrap'>
              {arrData.map((checkBox, index) => {
                if (!targetValueArr[index]) {
                  return;
                }

                return (
                  <CoreCheckBox
                    onChange={(val, checked) => handleCheckBoxClick(val, checked, index, arrvalues)}
                    value={index}
                    className='me-4'
                    title={dayConst[index]}
                    id={`patient-activity${index + 1}`}
                    htmlFor={`patient-activity${index + 1}`}
                    checked={handleChecked(index)}
                  />
                );
              })}
            </div>
            {error?.value && (
              <div className='inputMsg' style={{ color: 'red' }}>
                {error?.value}
              </div>
            )}
          </div>
        </div>

        <div>
          <button type='submit' onClick={handleFormSubmit} className='form-control coreBtn text-white'>
            Save
          </button>
        </div>
      </div>
    );
  };

  const renderGaolEdit = () => {
    if (isEdit && !isMedicineGoal) {
      return <div>{renderGoalValueField()}</div>;
    } else {
      return <div>{prescriptionEditForm()}</div>;
    }
  };

  const handleModalClose = (isClose) => {
    setIsOpen(false);
    setError({});
    !isClose && handleChange && handleChange();
  };

  const renderEdit = () => {
    return (
      <Modal backdrop='static' size='md' open={isOpen} onClose={() => handleModalClose(true)}>
        <Modal.Header />
        {renderGaolEdit()}
      </Modal>
    );
  };

  const renderEditIcon = () => {
    if (isEdit) {
      return (
        <div
          className='pointer'
          onClick={() => {
            setIsOpen(isEdit);
          }}
        >
          <img src={editTheme} />
        </div>
      );
    }
    return;
  };

  return (
    <div>
      <div
        className=''
        style={{
          margin: '5px',
          border: '1px solid #d1dcff',
          borderRadius: '10px',
        }}
      >
        {/* <div className='newMilestone success'> */}
        <div className='d-flex justify-content-between'>
          <div className='d-flex  w-100 align-items-start'>
            {dateVal && (
              <div className='w-20 capitalize' style={{ backgroundColor: '#d1dcff', padding: '5px 8px', fontSize: '14px', fontWeight: '500' }}>
                {dateVal}
              </div>
            )}{' '}
            <div
              className='w-20 capitalize'
              style={{
                backgroundColor: '#f1f5ff',
                padding: '5px 8px',
                color: '#656364',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: 'normal',
              }}
            >
              {title}
            </div>
            {/* <div className='badge badge-light me-1'>{title}</div> */}
            {AiChat?.chat_duration ? (
              <div className='d-flex align-items-center'>
                <div className=''>Duration {AiChat?.chat_duration} min</div>
              </div>
            ) : null}
            {AiChat?.messages?.length > 0 ? (
              <div className='d-flex align-items-start'>
                <div className='viewchhat-btn me-2 d-flex align-items-center' onClick={() => setOpenChatModal(true)}>
                  <img src={aiChat} className='me-1' /> View all Chat
                </div>
                <div className='viewchhat-btn d-flex align-items-center' onClick={() => handleExportChat(AiChat?.id)}>
                  <span className='material-icons-outlined me-1' style={{ fontSize: 12 }}>
                    send_time_extension
                  </span>
                  Export
                </div>
              </div>
            ) : null}
          </div>
          {/* <div>
              <CommonToolTip placement={'top'} item={renderEditIcon()} showValue={'Edit'} />
            </div> */}
          {/* <div
            className='w-25'
            style={{
              display: 'flex',
              fontSize: 13,
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
              // backgroundColor: 'red',
            }}
          >
            View Event
            <div
              style={{
                backgroundColor: '#d1dcff',
                marginLeft: '8px',
              }}
              className='badge badge-light me-1 pointer'
            >
              <span
                className='material-icons-outlined me-1'
                color='#3b4687'
                style={{
                  fontSize: 12,
                  color: '#3b4687',
                }}
              >
                chevron_right
              </span>
            </div>
          </div> */}
        </div>
        <div className=''>{children}</div>
        {/* </div> */}
      </div>
      <Modal backdrop='static' open={openChatModal} onClose={() => setOpenChatModal(false)} size='md'>
        <Modal.Header>
          <Modal.Title>
            <strong>Chat Summary</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {AiChat?.messages?.map((chatItem, index) => {
            return chatItem.sender == 'user' ? (
              <div class='msger-chat' key={index}>
                <div class='d-flex justify-content-start align-items-start'>
                  <div class='msg-img msg-img-user'>
                    <img src={aiChatUser} />
                  </div>
                  <div class='msg-text'>
                    <div>{chatItem.message}</div>
                    <p>
                      {dateFormat(chatItem.created_at)} {timeFormat(chatItem.created_at)}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div class='msger-chat msger-chat-bot' key={index}>
                <div class='d-flex justify-content-start align-items-start'>
                  <div class='msg-img'>
                    <img src={aiChatBot} />
                  </div>
                  <div class='msg-text'>
                    <div>{chatItem.message}</div>
                    <p>
                      {dateFormat(chatItem.created_at)} {timeFormat(chatItem.created_at)}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </Modal.Body>
      </Modal>
      {renderEdit()}
    </div>
  );
}

export default PostBody;
