import React, { useEffect, useMemo } from 'react';
import { AdminPanelHeader, DashBoardHeader } from 'components';
import { useNavigate } from 'react-router-dom';
import { addIconWhite, filters } from 'res/images';
import { SegmentList } from 'whealth-core-web/components';
import SearchBox from 'whealth-core-web/components/common/SearchBox';

function Segment() {
  const navigate = useNavigate();

  const leftSideData = [
    {
      title: 'Apply Filter',
      className: 'themeColor boldFont',
      showBtn: true,
      icon: filters,
    },
  ];

  const headerData = [
    {
      title: 'Add Segment',
      icon: addIconWhite,
      status: true,
      onClick: () => {
        navigate('/segments/new');
      },
    },
  ];

  const renderSearchBox = () => {
    return (
      <SearchBox
        placeHolder='Search segments by title'
        searchMethod='SEGMENTDEFINITIONSEARCH'
        label='title'
        searchId='segment'
        renderPath='/segments/{source_id}/edit' //ShowPatientProfile
      />
    );
  };

  const renderHeader = useMemo(() => {
    return <AdminPanelHeader iconTitle='segment' title='Segments' searchBox={renderSearchBox()} />;
  }, []);

  const renderDashBoardHeader = useMemo(() => {
    return <DashBoardHeader leftData={[]} data={headerData} />;
  }, []);

  return (
    <div className='articleHeader wrapperContent'>
      <div className='headerFiles'>
        {renderHeader}
        {renderDashBoardHeader}
      </div>
      <div className='hrLine' />

      {/* <CommonAlert setIsShowAlert={setAlert} isShowAlert={alert.message} alertType={alert.type} /> */}
      <div className='d-flex postHeader mt-4'>
        <div className='active capitalize'> Segment List</div>
      </div>
      <div className='teamBody'>
        <SegmentList />
      </div>
    </div>
  );
}

export default Segment;
