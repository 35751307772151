import React, { useEffect, useState } from 'react';
import { Modal } from 'rsuite';
import Pagination from '../Pagination';
import CommonBorderBox from '../CommonBorderBox';
import RegimeTemplateForm from './RegimeTemplateForm';
import RegimeTemplateList from './RegimeTemplateList';
import useApiManager from 'networking/ApiManager';
import CommonAlert from '../CommonAlert';

function RegimeTemplate(props) {
  const ApiClient = useApiManager();
  const { setIsLoading, setIsShowAlert, setAlertType, isNivaan } = props;
  const [isEditMode, setEditMode] = useState(false);
  const [questionsList, setQuestionsList] = useState([]);
  const [regimeTemplate, setRegimeTemplate] = useState({
    title: '',
    template_behaviour_questions: [{}],
    default: false,
    editable: true,
  });
  const [editRegimeTemplate, setEditRegimeTemplate] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [regimeTemplateList, setRegimeTemplateList] = useState([]);
  const [searchRegime, setSearchRegime] = useState('');
  const [isUpdateAlert, setIsUpdateAlert] = useState(false);
  const [updateAlertType, setIsUpdateAlertType] = useState('alert-success');

  useEffect(() => {
    let params = { page: 1, all_templates: true };
    ApiClient.getBehaviourQuestions(params).then((res) => {
      setQuestionsList(res.data);
    });
  }, []);

  useEffect(() => {
    if (searchRegime?.length > 2) {
      searchRegimeTemplate(searchRegime, currentPage);
    } else {
      getTemplatesList();
    }
  }, [currentPage]);

  const searchRegimeTemplate = (searchData, pageNo) => {
    setIsLoading(true);
    setSearchRegime(searchData);
    if (searchData?.length > 2) {
      ApiClient.searchTemplateList({ search_str: searchData, page: pageNo || 1 })
        .then((res) => {
          setTotalPages(res.headers['total-pages']);
          setRegimeTemplateList(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      getTemplatesList();
    }
  };

  const getTemplatesList = () => {
    setIsLoading(true);
    let params = { page: currentPage, all_templates: true };
    ApiClient.getTemplateList(params)
      .then((res) => {
        setTotalPages(res.headers['total-pages']);
        setRegimeTemplateList(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const deleteRegimeTemplate = (id) => {
    setIsLoading(true);
    ApiClient.deleteRegimeTemplate(id).then((res) => {
      setIsLoading(false);
      getTemplatesList();
      setAlertType('alert-success');
      setIsShowAlert(`${isNivaan ? 'Conversion log Template Deleted Successfully ' : 'Regime Template Deleted Successfully'}`);
    });
  };

  const getRegimeTemplate = (item) => {
    setIsLoading(true);
    ApiClient.getRegimeTemplate(item.id).then((res) => {
      setEditMode(true);
      let updateRegimeData = res.data;
      setEditRegimeTemplate({
        is_disabled: updateRegimeData?.is_disabled,
        id: updateRegimeData.id,
        title: updateRegimeData.title,
        default: updateRegimeData.default,
        editable: updateRegimeData.editable,
        template_behaviour_questions: updateRegimeData.behaviour_questions.map((item) => {
          if (questionsList.findIndex((question) => question.id == item.id) == -1) {
            questionsList.push(item);
            setQuestionsList([...questionsList]);
          }
          return { behaviour_question_id: item.id, is_default: item.is_default || false };
        }),
      });
      setIsLoading(false);
    });
  };

  return (
    <div className='mb-3'>
      <CommonBorderBox className='settingFormOutline'>
        <div className='formTitle'>{`${isNivaan ? 'Add Conversion Log Templates' : 'Regime Template'}`}</div>
        <RegimeTemplateForm
          isNivaan={isNivaan}
          questionsList={questionsList}
          regimeTemplate={regimeTemplate}
          setRegimeTemplate={setRegimeTemplate}
          getTemplatesList={getTemplatesList}
          setIsShowAlert={setIsShowAlert}
          setAlertType={setAlertType}
          setQuestionsList={setQuestionsList}
        />
      </CommonBorderBox>
      <div className='table-responsive'>
        <RegimeTemplateList
          regimeTemplate={regimeTemplate}
          regimeTemplateList={regimeTemplateList}
          editRegimeTemplate={getRegimeTemplate}
          deleteRegimeTemplate={deleteRegimeTemplate}
          searchRegimeTemplate={searchRegimeTemplate}
          setIsUpdateAlert={setIsUpdateAlert}
          setIsUpdateAlertType={setIsUpdateAlertType}
          getTemplatesList={getTemplatesList}
          setIsShowAlert={setIsShowAlert}
          setAlertType={setAlertType}
        />
      </div>
      <Pagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
      <Modal
        backdrop='static'
        size='md'
        open={isEditMode}
        onClose={() => {
          setEditMode(false);
        }}
      >
        <Modal.Header>
          <div className='settingFormOutline p-0'>
            <div className='formTitle mb-0'>{`${isNivaan ? 'Update Conversion Log Templates ' : 'Update Regime Template'}`}</div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <CommonAlert isShowAlert={isUpdateAlert} alertType={updateAlertType} setIsShowAlert={setIsUpdateAlert} className='mt-0 mb-3' />

          <RegimeTemplateForm
            isNivaan={isNivaan}
            questionsList={questionsList}
            regimeTemplate={editRegimeTemplate}
            setRegimeTemplate={setEditRegimeTemplate}
            getTemplatesList={getTemplatesList}
            setIsShowAlert={setIsShowAlert}
            setAlertType={setAlertType}
            isEditMode
            setEditMode={setEditMode}
            setQuestionsList={setQuestionsList}
            setIsUpdateAlert={setIsUpdateAlert}
            setIsUpdateAlertType={setIsUpdateAlertType}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default RegimeTemplate;
