import React, { useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useGetLoginUserDataQuery } from 'redux/apiSlice';
import { useGetCockpitDataQuery } from 'redux/cockpitSlice';
import { plainLogo, loginpageLogo } from 'res/images';
import { Dropdown } from 'rsuite';
import { s3Url } from 'whealth-core-web/components/Helper';

function SideBar(props) {
  const [activeLink, setActiveLink] = useState('');
  const [sideBarOpen, serSideBarOpen] = useState(true);
  // const sidebarImage = sideBarOpen ? sideMenuSwitcher : sideMenuSwitcherExp;
  const sideBarClass = sideBarOpen ? 'sidebar-active' : 'sidebar-close';

  const getCockpitData = useGetCockpitDataQuery();
  let userData = useGetLoginUserDataQuery()?.data?.data || {};

  let configData = getCockpitData?.data?.data?.business_config;
  const linksData = [
    {
      icon: 'home',
      title: 'Home',
      link: '/dashboard',
      key: 'home',
      hide: userData.role == 'ee' || userData.role == 'ee_manager',
    },
    {
      showDropdown: true,
      dropDown: {
        title: 'Patient',
        icon: 'person',
        data: [
          {
            icon: 'person',
            title: 'Patient',
            link: '/patient',
            key: 'patient',
          },
          {
            icon: 'segment',
            title: 'Segments',
            link: '/segments',
            key: 'segment',
            condition: 'enable_segment',
          },
        ],
      },
      hide: userData.role == 'ee' || userData.role == 'ee_manager',
    },
    {
      icon: 'calendar_month',
      title: 'Appointment',
      link: '/appointment',
      key: 'appointment',
      condition: 'enable_appointment',
      // hide: userData.role == 'ee' || userData.role == 'ee_manager',
    },
    {
      icon: 'description',
      title: 'Article',
      link: '/articles',
      key: 'article',
      condition: 'enable_article',
      hide: userData.role == 'ee' || userData.role == 'ee_manager',
    },
    {
      icon: 'people_alt',
      title: 'Team',
      link: '/team',
      key: 'team',
      hide: userData.role == 'health_coach' || userData.role == 'ee' || userData.role == 'ee_manager',
    },
    {
      icon: 'settings',
      title: 'Settings',
      link: '/settings',
      key: 'settings',
      condition: 'enable_settings',
      hide:
        userData.role == 'diet_health_coach' ||
        userData.role == 'fitness_health_coach' ||
        userData.role == 'health_coach' ||
        userData.role == 'ee' || userData.role == 'ee_manager',
    },
    {
      icon: 'notifications',
      title: 'Notifications',
      link: '/notifications',
      key: 'notifications',
      condition: 'side_bar_notification',
      hide: userData.role == 'ee' || userData.role == 'ee_manager',
    },
    {
      icon: 'analytics',
      title: 'Reports',
      link: '/reports',
      key: 'side_bar_report',
      condition: 'side_bar_report',
      // hide: userData.role == 'ee' || userData.role == 'ee_manager',
    },
    {
      icon: 'shopping_cart',
      title: 'orders',
      link: '/orders',
      key: 'orders',
      hide: userData.role == 'ee' || userData.role == 'ee_manager' || configData?.findIndex((item) => item.key == 'enable_order' && item.isActive) == -1,
    },
    {
      hide: true,
      icon: 'manage_accounts',
      title: 'Cockpit',
      link: '/cockpit',
      key: 'cockpit',
    },
  ];

  let renderLink = (item, index) => {
    return (
      <NavLink
        key={index}
        to={item.link}
        className={({ isActive }) => {
          if (isActive) {
            setActiveLink(item.link);
            setBodyClass();
            return 'sideBarText activeLink';
          } else {
            return 'sideBarText';
          }
        }}
      >
        <div className='d-flex sideLinks'>
          {/* <img src={icon} className="sidebarIcon" /> */}
          <span class='material-icons'>{item.icon}</span>
          <div className='sidebarNavText'>{item.title} </div>
        </div>
      </NavLink>
    );
  };

  const renderMenuItem = (data) => {
    let filterData = [];
    data.data.map((item, index) => {
      let isShow = false;
      if (configData && Array.isArray(configData)) {
        if (item.condition) {
          configData.map((confItem) => {
            if (confItem.key == item.condition && confItem.isActive) {
              isShow = true;
            }
          });
        } else {
          isShow = true;
        }
      }
      isShow && filterData.push(item);
    });

    if (filterData.length == 1) {
      return renderLink(filterData[0], 1);
    } else if (!filterData.length) {
      return renderLink(data.data[0], 1);
    } else {
      return (
        <div className='d-flex sideLinks sideLinks-submenu'>
          <span class='material-icons'>{data.icon}</span>
          <Dropdown title={data.title}>
            {filterData.map((item, index) => {
              return renderLink(item, index);
            })}
          </Dropdown>
        </div>
      );
    }
  };

  const setBodyClass = () => {
    if (sideBarOpen) {
      document.body.classList.remove('bodyActive');
    } else {
      document.body.classList.add('bodyActive');
    }
  };

  const renderLinks = useMemo(() => {
    return linksData.map((item, index) => {
      if (item.hide) {
        return;
      }

      let returnEmpty = false;
      if (configData && Array.isArray(configData)) {
        configData.map((confItem) => {
          if (confItem.key == item.condition && !confItem.isActive) {
            returnEmpty = true;
          }
        });
      }
      if (returnEmpty) {
        return;
      }
      if (item.showDropdown && item.dropDown.data) {
        return renderMenuItem(item.dropDown);
      }
      return renderLink(item, index);
    });
  }, [activeLink, sideBarOpen, configData, userData]);

  const navBarLogo = useMemo(() => {
    return (
      <div className='d-flex flex-column sidebarLogo'>
        <img src={s3Url('logo.svg', loginpageLogo)} className='logo' />
        <img src={s3Url('dash-short-logo.svg', plainLogo)} className='sm-logo' />
      </div>
    );
  });

  return (
    <div className={`articleListSideBar stickyPosition ${sideBarClass}`}>
      <div className='sidebarScreen'>
        {navBarLogo}
        {userData?.role && renderLinks}
      </div>
      <div
        onClick={() => {
          serSideBarOpen(!sideBarOpen);
          setBodyClass();
        }}
        className='side_switcher_bar'
      >
        <span class='material-icons'></span>
      </div>
    </div>
  );
}

export default SideBar;
