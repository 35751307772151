import React from 'react';
import { CoreButton, CoreInputBox, CoreDropDown, CoreTextArea } from 'whealth-core-web/components';

function SettingsProfileForm(props) {
  const { valueObj, error, isUpdate, setValueObj, onClick } = props;

  const typeArr = [
    { title: 'String', id: 'string' },
    { title: 'Text', id: 'text' },
    { title: 'Integer', id: 'int' },
    { title: 'Date', id: 'date' },
    { title: 'Date Time', id: 'date_time' },
    { title: 'Float', id: 'float' },
    { title: 'Multi Select', id: 'multi_select' },
    { title: 'Single Select', id: 'single_select' },
  ];

  return (
    <div>
      <CoreInputBox
        retuired
        showMSG={error?.question}
        placeholder='Question'
        value={valueObj.question || ''}
        label='Question'
        setValue={(data) => {
          setValueObj({ ...valueObj, question: data });
        }}
        msgStyle={{ color: 'red' }}
        maxLength={255}
      />

      <div style={{ margin: '20px 0' }}>
        <CoreTextArea
          showMSG={error?.description}
          placeholder='Description'
          value={valueObj.description || ''}
          label='Description'
          setValue={(data) => {
            setValueObj({ ...valueObj, description: data });
          }}
          rows={3}
        />
      </div>

      <div style={{ margin: '20px 0' }}>
        <CoreInputBox
          retuired
          showHint={'Hint: Use small letters only. No special characters except underscore is allowed. Eg- height, target_weight, average_step_count.'}
          showMSG={error?.key}
          placeholder='Key'
          value={valueObj.key || ''}
          label='Key'
          setValue={(data) => {
            setValueObj({ ...valueObj, key: data });
          }}
          maxLength={255}
        />
      </div>

      <div style={{ margin: '20px 0' }}>
        <CoreInputBox
          placeholder='Unit'
          value={valueObj.unit || ''}
          label='Unit'
          setValue={(data) => {
            setValueObj({ ...valueObj, unit: data });
          }}
          maxLength={255}
          msgStyle={{ color: 'red' }}
        />
      </div>

      <CoreDropDown
        retuired
        data={typeArr}
        showMSG={error?.type}
        placeholder='Type'
        value={valueObj.type || ''}
        label='Type'
        setValue={(data) => {
          setValueObj({ ...valueObj, type: data });
        }}
        msgStyle={{ color: 'red' }}
      />

      {(valueObj.type == 'multi_select' || valueObj.type == 'single_select') && (
        <div style={{ margin: '20px 0' }}>
          <CoreInputBox
            retuired={valueObj?.key != 'department_subsource'}
            placeholder="Please use 'I' to seperate different options. Ex. Option1|Option2|Option3"
            value={valueObj.options || ''}
            showMSG={error?.options}
            label='Options'
            setValue={(data) => {
              setValueObj({ ...valueObj, options: data });
            }}
            msgStyle={{ color: 'red' }}
          />
        </div>
      )}

      <div className='d-flex'>
        <div className='form-check mt-3'>
          <input
            className='form-check-input'
            style={{ cursor: 'pointer' }}
            checked={valueObj.enable_mobile}
            onChange={(data) => {
              setValueObj({ ...valueObj, enable_mobile: !valueObj.enable_mobile });
            }}
            type='checkbox'
            id={isUpdate ? 'flexCheckDefault1' : 'update'}
          />
        </div>
        <label className='pointer mt-3' htmlFor={isUpdate ? 'flexCheckDefault1' : 'update'}>
          Display On Mobile
        </label>
        <div className='form-check ms-3 mt-3'>
          <input
            className='form-check-input'
            style={{ cursor: 'pointer' }}
            checked={valueObj.is_mandatory}
            onChange={(data) => {
              setValueObj({ ...valueObj, is_mandatory: !valueObj.is_mandatory });
            }}
            type='checkbox'
            id={isUpdate ? 'mandatory' : 'mandatoryUpdate'}
          />
        </div>
        <label className='pointer mt-3' htmlFor={isUpdate ? 'mandatory' : 'mandatoryUpdate'}>
          Mandatory
        </label>
      </div>

      <CoreButton
        onClick={() => {
          onClick();
        }}
        title={isUpdate ? 'Update' : 'Save'}
      />
    </div>
  );
}

export default SettingsProfileForm;
